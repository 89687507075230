import React from 'react';
import Navbar from "../navbar";
import Disclaimer from "../Disclaimer";
import Footer from "../Footer";


const Disclaimerpage = () => {
    return (
        <>
            <Navbar />
            <Disclaimer />   
            <Footer />         
        </>
    );
};

export default Disclaimerpage;