import React from 'react';
import Navbarmodule from "../navbarmodule";
import ContactUs from "../ContactUs";
import Footerlogin from "../Footerlogin";


const Contactuslogin = () => {
    return (
        <>
            <Navbarmodule />
            <ContactUs />   
            <Footerlogin />         
        </>
    );
};

export default Contactuslogin;