import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
//import Axios from 'axios';



function HydrogenOutout(outputData) {
    // const url ="/finModel"
    const currDate = new Date().toLocaleDateString()
    const currTime = new Date().toLocaleTimeString()

    //const [outputData, setoutputData] = useState(null)    

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content:() =>componentRef.current,
    });

    const history = useHistory()

   
        console.log(outputData.Output[0])
        
        return ( 
                <>
                <div className="print__section">
                <div ref={ componentRef } className="card">

                <section className="container main-hero-container">
                    <div className="row">
                        <div classname="col-12 col-lg-6 header-left-side d-flex justify-content 
                            align-items-start">
                           
                            <p className="main-hero-para"> 
                                <span style={{color: "blueviolet"}} className="text-center span-tag-big"> Welcome to Sueng-Tech (www.sueng-tech.com)</span> <br/> 
                                <span style={{color: "black"}} > Model running Date : { currDate } & Time : { currTime } </span> <br/><br/>
                                
                                <div className="text-center span-tag-big"><u>Technical Input data entered by customer</u></div>
                                
                                <table width = "100%">
                                    
                                        <th style={{color: "blueviolet"}}>General Plant Information</th>     
                                    
                                    <tr>
                                        <td style={{color: "black"}}>Name of the Plant or Project Name : { outputData.Output[0] } </td>
                                    </tr>
                                    <tr>
                                        <td style={{color: "black"}}>Rating of the proposed Green Hydrogen Plant in Nm3/h : { outputData.Output[1] } </td>
                                    </tr> 
                                    <tr>
                                        <td style={{color: "black"}}>The Useful Life of the Plant in years (max. 20 years) : { outputData.Output[2] } </td>
                                    </tr>                                 
                                    <tr>
                                        <td style={{color: "black"}}>The Type of Electrolyzer to be used in the Plant will be : { outputData.Output[3] } </td>
                                    </tr>
                                    <tr>
                                        <td style={{color: "black"}}>The annual degradation rate of the chosen Electrolyzer in % as per the manufacturer's data sheet will be : { outputData.Output[4] } </td>
                                    </tr>
                               
                                </table> <br/>                            
                               
                            <div>                                    
                                                                 
                                                                                                                        
                                <table width="100%">
                                     <tr>
                                        <th style={{color: "blueviolet"}} > Financial Input Data entered by the customer </th>
                                    </tr>
                                    <tr>
                                        <td style={{color: "black"}} width = "100%">Capex of the project in {outputData.Output[5]} : { outputData.Output[6] } </td>                                        
                                    </tr>                                    
                                    <tr>
                                        <td style={{color: "black"}} width = "100%">Salvage value of the plant (in %) : { outputData.Output[7] } </td>
                                        
                                    </tr>
                                    <tr>
                                        <td style={{color: "black"}} width = "100%">Selling price of Green Hydrogen in { outputData.Output[5]}/kg : { outputData.Output[8] } </td>
                                        
                                    </tr>                                                   
                                    
                                    <tr>
                                        <td style={{color: "black"}} width = "100%">Rate of Depreciation (in %) : { outputData.Output[9] } </td>
                                        
                                        
                                    </tr>
                                    <tr>
                                        <td style={{color: "black"}} width = "100%">No. of years for application of WDV method of depreciation : { outputData.Output[10] }</td>
                                        
                                        
                                    </tr>
                                    <tr>
                                        <td style={{color: "black"}} width = "100%">AC power consumption of the Plant in kWh / Nm3 : { outputData.Output[11] } </td>
                                       
                                    </tr>
                                    <tr>
                                        
                                        <td style={{color: "black"}} width = "100%">The cost of Green Power in {outputData.Output[5]}/kWhr : { outputData.Output[12] }</td>
                                    </tr>
                                    <tr>
                                        <td style={{color: "black"}} width = "100%">The ratio of Green Power Cost to the overall Annual Operation & Maintenance (O&M) of the Plant will be : { outputData.Output[13] } </td>
                                        
                                    </tr>
                                    <tr>
                                        
                                        <td style={{color: "black"}} width = "100%">Annual running hours of the Plant is (in no. of hours) : { outputData.Output[14] }</td>
                                    </tr>
                                    <tr>
                                        <td style={{color: "black"}} width = "100%">Escalation in O&M cost of per year in % of overall O&M cost : { outputData.Output[15] }</td>
                                        
                                    </tr>
                                    <tr>
                                        
                                        <td style={{color: "black"}} width = "100%">Debt to Equity ratio (in decimal) : { outputData.Output[16] } </td>
                                    </tr>
                                    <tr>
                                        <td style={{color: "black"}} width = "100%">Rate of interest for Debt (in %) : { outputData.Output[17] } </td>
                                        
                                        
                                    </tr>
                                     <tr>
                                        
                                        <td style={{color: "black"}} width = "100%">No. of years for repayment of Debt (in years) : { outputData.Output[18] } </td>
                                        
                                    </tr>
                                    <tr>
                                        <td style={{color: "black"}} width = "100%">Insurance cost considered in % of depreciated project cost (in %) : { outputData.Output[19] } </td>
                                        
                                        
                                    </tr>
                                    <tr>
                                        
                                        <td style={{color: "black"}} width = "100%">Tax Rate (in %) : { outputData.Output[20] } </td>
                                        
                                    </tr>
                                    <tr>
                                        <td style={{color: "black"}} width = "100%">The value of capital expenditure considered in the modelling as % of Revenue  : { outputData.Output[21] } </td>
                                       
                                        
                                    </tr>
                                    <tr>
                                        
                                        <td style={{color: "black"}} width = "100%">The year in which the electrolyzer membranes or electrolyzer needs to be replaced : { outputData.Output[22] } </td>
                                        
                                    </tr>
                                    <tr>
                                        <td style={{color: "black"}} width = "100%">The value of capital expenditure to be made towards replacement of electrolyzer membranes or electrolyzers in {outputData.Output[5]}  : { outputData.Output[23] } </td>
                                        
                                        
                                    </tr>
                                    <tr>
                                        
                                        <td style={{color: "black"}} width = "100%">The value of subsidy / rebate / benifits to be received from Government in terms of monetary value in {outputData.Output[5]}  : { outputData.Output[24] } </td>
                                        
                                    </tr>
                                    <tr>
                                        <td style={{color: "black"}} width = "100%">The cost of Equity or the rate of return for equity investors (in %) : { outputData.Output[25] } </td>
                                        
                                        
                                    </tr>
                                    <tr>
                                        
                                        <td style={{color: "black"}} width = "100%">Risk free rate of interest (in %) : { outputData.Output[26] } </td>
                                        
                                    </tr>
                                    
                                   <tr>
                                        <td style={{color: "black"}} width = "100%">The company sensitivity value, Beta (default is 1) : { outputData.Output[27] } </td>
                                        
                                    </tr>
                                    <tr>
                                        
                                        <td style={{color: "black"}} width = "100%">Equity risk premium (in %) : { outputData.Output[28] } </td>
                                    </tr>
                                </table><br/>

                                <section className="span-tag-big text-center" style={{color: "blueviolet"}}><u>Financial Modelling of the Project</u></section>
                                
                                <table width="100%">
                                    <tr>
                                        <th className="span-tag-big text-left" > 1.0  Yearwise Hydrogen Production along with O&M Cost, Depreciation, Insurance, Interest, Revenue, PAT & Free Cash flow </th>
                                    </tr>
                                </table>
                                <table width="100%">                      
                                    <tr > 
                                        <th style={{color: "blue"}} className="span-tag text-center"><u>Year</u></th>
                                        <th style={{color: "blue"}} className="span-tag text-center"><u>H2 Production</u></th>
                                        <th style={{color: "blue"}} className="span-tag text-center"><u>O&M Cost</u></th>
                                        <th style={{color: "blue"}} className="span-tag text-center"><u>Depreciation</u></th>
                                        <th style={{color: "blue"}} className="span-tag text-center"><u>Insurance</u></th>
                                        <th style={{color: "blue"}} className="span-tag text-center"><u>Interest</u> </th>
                                        <th style={{color: "blue"}} className="span-tag text-center"><u>Revenue</u></th>
                                        <th style={{color: "blue"}} className="span-tag text-center"><u>Capital Invested</u></th>
                                        <th style={{color: "blue"}} className="span-tag text-center"><u>PAT</u></th>
                                        <th style={{color: "blue"}} className="span-tag text-center"><u>FCFF</u></th>

                                    </tr>                                             
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 1 </td>
                                        <td className="span-tag text-center"> { outputData.Output[29].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[49].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[69].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[89].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[109].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[129].toFixed(1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[149].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[169].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[189].toFixed( 1) } </td>
                                        

                                    </tr>
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 2 </td>
                                        <td className="span-tag text-center"> { outputData.Output[30].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[50].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[70].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[90].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[110].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[130].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[150].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[170].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[190].toFixed( 1) } </td>
                                    </tr>
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 3 </td>
                                        <td className="span-tag text-center"> { outputData.Output[31].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[51].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[71].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[91].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[111].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[131].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[151].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[171].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[191].toFixed( 1) } </td>
                                    </tr>
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 4 </td>
                                        <td className="span-tag text-center"> { outputData.Output[32].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[52].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[72].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[92].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[112].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[132].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[152].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[172].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[192].toFixed( 1) } </td>
                                    </tr>
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 5 </td>
                                        <td className="span-tag text-center"> { outputData.Output[33].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[53].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[73].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[93].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[113].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[133].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[153].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[173].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[193].toFixed( 1) } </td>
                                    </tr>
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 6 </td>
                                        <td className="span-tag text-center"> { outputData.Output[34].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[54].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[74].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[94].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[114].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[134].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[154].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[174].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[194].toFixed( 1) } </td>
                                    </tr>
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 7 </td>
                                        <td className="span-tag text-center"> { outputData.Output[35].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[55].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[75].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[95].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[115].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[135].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[155].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[175].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[195].toFixed( 1) } </td>
                                    </tr>
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 8 </td>
                                        <td className="span-tag text-center"> { outputData.Output[36].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[56].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[76].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[96].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[116].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[136].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[156].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[176].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[196].toFixed( 1) } </td>
                                    </tr>
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 9 </td>
                                        <td className="span-tag text-center"> { outputData.Output[37].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[57].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[77].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[97].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[117].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[137].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[157].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[177].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[197].toFixed( 1) } </td>
                                    </tr>
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 10 </td>
                                        <td className="span-tag text-center"> { outputData.Output[38].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[58].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[78].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[98].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[118].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[138].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[158].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[178].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[198].toFixed( 1) } </td>
                                    </tr>
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 11 </td>
                                        <td className="span-tag text-center"> { outputData.Output[39].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[59].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[79].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[99].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[119].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[139].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[159].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[179].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[199].toFixed( 1) } </td>
                                    </tr>
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 12 </td>
                                        <td className="span-tag text-center"> { outputData.Output[40].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[60].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[80].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[100].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[120].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[140].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[160].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[180].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[200].toFixed( 1) } </td>
                                    </tr>
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 13 </td>
                                        <td className="span-tag text-center"> { outputData.Output[41].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[61].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[81].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[101].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[121].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[141].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[161].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[181].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[201].toFixed( 1) } </td>
                                    </tr> 
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 14 </td>
                                        <td className="span-tag text-center"> { outputData.Output[42].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[62].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[82].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[102].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[122].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[142].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[162].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[182].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[202].toFixed( 1) } </td>
                                    </tr>
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 15 </td>
                                        <td className="span-tag text-center"> { outputData.Output[43].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[63].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[83].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[103].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[123].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[143].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[163].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[183].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[203].toFixed( 1) } </td>
                                    </tr>
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 16 </td>
                                        <td className="span-tag text-center"> { outputData.Output[44].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[64].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[84].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[104].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[124].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[144].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[164].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[184].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[204].toFixed( 1) } </td>
                                    </tr>
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 17 </td>
                                        <td className="span-tag text-center"> { outputData.Output[45].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[65].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[85].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[105].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[125].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[145].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[165].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[185].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[205].toFixed( 1) } </td>
                                    </tr>
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 18 </td>
                                        <td className="span-tag text-center"> { outputData.Output[46].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[66].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[86].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[106].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[126].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[146].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[166].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[186].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[206].toFixed( 1) } </td>
                                    </tr>
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 19 </td>
                                        <td className="span-tag text-center"> { outputData.Output[47].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[67].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[87].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[107].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[127].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[147].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[167].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[187].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[207].toFixed( 1) } </td>
                                    </tr>
                                    <tr > 
                                        <td className="span-tag text-center"> Yr 20 </td>
                                        <td className="span-tag text-center"> { outputData.Output[48].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[68].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[88].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[108].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[128].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[148].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[168].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[188].toFixed( 1) } </td>
                                        <td className="span-tag text-center"> { outputData.Output[208].toFixed( 1) } </td>
                                    </tr>
                                                                
                                                                  
                                </table> <br/>    

                            
                                <table width="100%"> 
                                     <tr style={{color: "blueviolet"}} className="span-tag-big"> Abbreviations / Legends </tr>                                             
                                    <tr style={{color: "black"}}> H2 Production : Annual Green Hydrogen Production from the plant in kg (Kilograms) </tr>
                                    <tr style={{color: "black"}}> O&M Cost : Annual Operation & Maintenance cost of the plant in {outputData.Output[5]} </tr>
                                    <tr style={{color: "black"}}> Depreciation : Annual depreciation value of the plant in {outputData.Output[5]}</tr>
                                    <tr style={{color: "black"}}> Insurance Cost : Annual insurance cost for the plant in {outputData.Output[5]} </tr>
                                    <tr style={{color: "black"}}> Interest Cost : Annual interest cost for the plant in {outputData.Output[5]} </tr>
                                    <tr style={{color: "black"}}> Revenue : Annual Revenue generation for the plant in {outputData.Output[5]} </tr>
                                    <tr style={{color: "black"}}> Capital Invested : Annual Capital invested in the plant including replacement of membranes & electrolyzers in {outputData.Output[5]} </tr>
                                    <tr style={{color: "black"}}> PAT : Profit after tax for the plant in {outputData.Output[5]}</tr>
                                    <tr style={{color: "black"}}> FCFF : Free Cash Flow to the Firm / Company for the plant in {outputData.Output[5]}</tr>
                                    
                                </table> <br/>    
                                <table width="100%">
                                    <tr className="span-tag-big">
                                        <th> 2.0  Predicted Financial Parameters of the Project </th>
                                    </tr>
                                </table>
                                <table width="100%"> 
                                    <tr className="span-tag" style={{color: "blue"}}> Cost of Equity in % : { outputData.Output[209].toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> Cost of Capital in % : { outputData.Output[210].toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> Levelized Cost of Hydrogen Generation, LCoH in {outputData.Output[5]}/ kg of Hydrogen produced : { outputData.Output[211].toFixed( 2) }</tr>
                                   
                                    <tr className="span-tag" style={{color: "blue"}}> Net Present Value of the Project, NPV in {outputData.Output[5]} : { outputData.Output[212].NPV.toFixed( 2) } </tr>
                                    <tr className="span-tag" style={{color: "blue"}}> Payback Period for the Project in years : { outputData.Output[212].Payback.toFixed( 2) }</tr>   
                                     
                                    <tr className="span-tag" style={{color: "blue"}}> Project IRR (Internal Rate of Return) in % : { outputData.Output[213].toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> Equity IRR (Internal Rate of Return) in %  : { outputData.Output[214].toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> PLCR(Project Life Coverage Ratio) : { outputData.Output[215].PLCR.toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> LLCR(Loan Life Coverage Ratio) : { outputData.Output[215].LLCR.toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> Periodic Coverage Ratios - DSCR(Debt Service Coverage Ratio) : { outputData.Output[216].DSCR[0].toFixed( 2) }, { outputData.Output[216].DSCR[1].toFixed( 2) },
                                    { outputData.Output[216].DSCR[2].toFixed( 2) }, { outputData.Output[216].DSCR[3].toFixed( 2) }, { outputData.Output[216].DSCR[4].toFixed( 2) }, { outputData.Output[216].DSCR[5].toFixed( 2) }, 
                                     { outputData.Output[216].DSCR[6].toFixed( 2) }, { outputData.Output[216].DSCR[7].toFixed( 2) }, { outputData.Output[216].DSCR[8].toFixed( 2) }, { outputData.Output[216].DSCR[9].toFixed( 2) },
                                     { outputData.Output[216].DSCR[10].toFixed( 2) }, { outputData.Output[216].DSCR[11].toFixed( 2) }, { outputData.Output[216].DSCR[12].toFixed( 2) }, { outputData.Output[216].DSCR[13].toFixed( 2) },
                                     { outputData.Output[216].DSCR[14].toFixed( 2) }, { outputData.Output[216].DSCR[15].toFixed( 2) }, { outputData.Output[216].DSCR[16].toFixed( 2) }, { outputData.Output[216].DSCR[17].toFixed( 2) },
                                     { outputData.Output[216].DSCR[18].toFixed( 2) }, { outputData.Output[216].DSCR[19].toFixed( 2) }, 
                                                                          
                                     & ICR(Interest Coverage Ratio) are : { outputData.Output[216].ICR[0].toFixed( 2) }, { outputData.Output[216].ICR[1].toFixed( 2) }, 
                                     { outputData.Output[216].ICR[2].toFixed( 2) }, { outputData.Output[216].ICR[3].toFixed( 2) }, { outputData.Output[216].ICR[4].toFixed( 2) },
                                     { outputData.Output[216].ICR[5].toFixed( 2) }, { outputData.Output[216].ICR[6].toFixed( 2) }, { outputData.Output[216].ICR[7].toFixed( 2) }, { outputData.Output[216].ICR[8].toFixed( 2) },
                                     { outputData.Output[216].ICR[9].toFixed( 2) }, { outputData.Output[216].ICR[10].toFixed( 2) }, { outputData.Output[216].ICR[11].toFixed( 2) }, { outputData.Output[216].ICR[12].toFixed( 2) }, 
                                     { outputData.Output[216].ICR[13].toFixed( 2) }, { outputData.Output[216].ICR[14].toFixed( 2) }, { outputData.Output[216].ICR[15].toFixed( 2) }, { outputData.Output[216].ICR[16].toFixed( 2) }, 
                                     { outputData.Output[216].ICR[17].toFixed( 2) }, { outputData.Output[216].ICR[18].toFixed( 2) }, { outputData.Output[216].ICR[19].toFixed( 2) }, 
                                    </tr>                                
                                </table>   
                                <br></br>

                                <table width="100%">
                                    <tr className="span-tag-big">
                                        <th> 3.0  GENERAL DESIGN GUIDELINES, PROCESS FLOW DIAGRAM (PFD) AND PIPING & INSTRUMENTATION DIAGRAM (PID) OF A GREEN HYDROGEN GENERATION PLANT </th>
                                    </tr>
                                </table>
                                
                                <table width="100%">
                                    <tr className="span-tag-big">
                                        <th> 3.1  General Design Guidelines of a Green Hydrogen Generation Plant </th>
                                    </tr>
                                </table><br></br>


                                <div>
                                    <iframe src="./dg.pdf" width="100%" height="500px" alt="Design Guidelines of Hydrogen System" />
                                </div> 

                                <br></br>
                                <br></br>

                                <table width="100%">
                                    <tr className="span-tag-big">
                                        <th> 3.2  Suggestive Process Flow Diagram (PFD) of a Green Hydrogen Generation Plant </th>
                                    </tr>
                                </table><br></br><br></br>

                                <div>
                                    <iframe src="./pfd.pdf" width="100%" height="500px" alt="PFD of Hydrogen System" />
                                </div>

                                <br></br>
                                <br></br>
                                <table width="100%">
                                    <tr className="span-tag-big">
                                        <th> 3.3  Suggestive Piping & Instrumentation Diagram (PID) of a Green Hydrogen Generation Plant </th>
                                    </tr>
                                </table><br></br><br></br>

                                <div>
                                    <iframe src="./pid.pdf" width="100%" height="500px" alt="PID of Hydrogen System" />
                                </div>


                                <br></br>
                                                  
                                <div>
                                  <br/>                      
                                    <div className="fw-bold text-black">
                                        Thank you very much for using our Green Hydrogen SaaS program. Hope it helped you. Please provide your feedback in the comment section.
                                    </div>
                                  <br/>  
                                    <div className="row">
                                    <div class="text-center">
                                        <button className="btn btn-style location-middle w-40 fw-bold" onClick={()=> history.push("/homelogin")}>Home</button>
                                    {/*    <button className="btn btn-style location-middle w-40 fw-bold" onClick={()=> history.push("/energymodel")}>Fin Model</button> */}
                                        <button onClick={ handlePrint } className="print__button btn btn-style location-middle fw-bold w-40"> Print</button>
                                    </div>
                                    </div>
                                </div> 
                                </div> 
                                </p>
                          
                        </div>
                    </div>   
                          
                </section>   
                </div>
                </div>

                </>     
            )
    }

                                
export default HydrogenOutout;


