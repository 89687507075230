import React from 'react';
import Navbarhydrogen from "../navbarhydrogen";
import HydrogenModel from "../HydrogenModel";
import Footer from "../Footer";

const HydrogenModelpage = (setLoginUser) => {
    return (
        <>
            <Navbarhydrogen />      
            <HydrogenModel />
            <Footer />
        </>
    );
};

export default HydrogenModelpage;

