import React, { useState } from "react";
import Home from "./pages/Home";
import Homelogin from "./pages/Homelogin";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Modules from "./pages/Modules";
import Technologieshome from "./pages/Technologieshome";
import Technologieslogin from "./pages/Technologieslogin";
import Founders from "./pages/Founders";
import Experts from "./pages/Experts";
import Founderslogin from "./pages/Founderslogin";
import Contact from "./pages/Contact";
import Contactuslogin from "./pages/Contactuslogin";
import Error from "./pages/Error";
import Login from "./pages/Loginpage";
import Register from "./pages/Registerpage";
import Modulesmainpage from "./pages/Modulesmainpage";
import Modulesmainpagehydrogen from "./pages/Modulemainpagehydrogen";
import HydrogenModelpage from "./pages/HydrogenModelpage";

import EnergyModelpage from "./pages/EnergyModelpage";
import FinModelpage from "./pages/FinModelpage";
import ScheduleADemoPage from "./pages/ScheduleADemoPage";
import ScheduleADemologin from "./pages/ScheduleADemologin";
import FAQlogin from "./pages/FAQlogin";
import FAQpage from "./pages/FAQpage";
import Disclaimerlogin from "./pages/Disclaimerlogin";
import Disclaimerpage from "./pages/Disclaimerpage";
import Privacypolicylogin from "./pages/Privacypolicylogin";
import Privacypolicypage from "./pages/Privacypolicypage";
import TandClogin from "./pages/TandClogin";
import TandCpage from "./pages/TandCpage";
import Careerslogin from "./pages/Careerslogin";
import Careerspage from "./pages/Careerspage";
import Traininglogin from "./pages/Traininglogin";
import Trainingpage from "./pages/Trainingpage";
import Investorlogin from "./pages/Investorlogin";
import Investorpage from "./pages/Investorpage";


function App () {

  const [ setLoginUser] = useState('')
  return (
    <div className="App">  
    <Router>  
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/homelogin" component={Homelogin}></Route>
        <Route path="/modules" component={Modules}></Route> 
        <Route path="/modulemain" component={Modulesmainpage}></Route>
        <Route path="/modulemainhydrogen" component={Modulesmainpagehydrogen}></Route>
        <Route path="/founders" component={Founders}></Route>    
        <Route path="/experts" component={Experts}></Route>    
        <Route path="/founderslogin" component={Founderslogin}></Route>
        <Route path="/energymodel" component={EnergyModelpage}></Route>
        <Route path="/finmodel" component={FinModelpage}></Route>
        <Route path="/hydrogenmodel" component={HydrogenModelpage}></Route>
        
        <Route path="/technologieshome" component={Technologieshome}></Route>
        <Route path="/technologieslogin" component={Technologieslogin}></Route>
        <Route path="/contact" component={Contact}></Route>
        <Route path="/contactuslogin" component={Contactuslogin}></Route>
        <Route path="/login"><Login setLoginUser={setLoginUser} /></Route>
        <Route path="/register" component={Register}></Route>
        <Route path="/scheduleademopage" component={ScheduleADemoPage}></Route>
        <Route path="/scheduleademologin" component={ScheduleADemologin}></Route>
        <Route path="/faqlogin" component={FAQlogin}></Route>
        <Route path="/faqpage" component={FAQpage}></Route>
        <Route path="/disclaimerlogin" component={Disclaimerlogin}></Route>
        <Route path="/disclaimerpage" component={Disclaimerpage}></Route>
        <Route path="/privacypolicylogin" component={Privacypolicylogin}></Route>
        <Route path="/privacypolicypage" component={Privacypolicypage}></Route>
        <Route path="/tandclogin" component={TandClogin}></Route>
        <Route path="/tandcpage" component={TandCpage}></Route>
        <Route path="/careerslogin" component={Careerslogin}></Route>
        <Route path="/careerspage" component={Careerspage}></Route>
        <Route path="/traininglogin" component={Traininglogin}></Route>
        <Route path="/trainingpage" component={Trainingpage}></Route>
        <Route path="/investorlogin" component={Investorlogin}></Route>
        <Route path="/investorpage" component={Investorpage}></Route>
        <Route component={Error}></Route>
        
      </Switch>
    </Router>
    </div>
  );
};

export default App;