import React from 'react';
import Navbar from "../navbar";
import Footer from "../Footer";
import ScheduleADemo from "../ScheduleADemo";

const ScheduleADemoPage = () => {
    return (
        <>
        <Navbar />      
        <ScheduleADemo />
        <Footer /> 
        </>
    );
};

export default ScheduleADemoPage;
