import React, { useState } from 'react';
import serviceapi from "./API/serviceApi.js";
import { Link } from 'react-router-dom'

const Technologies = () => {
    const [ serviceData ] = useState(serviceapi);
    return (
        <>
            <section className="service-main-container">
                <div className="container service-container">
                    <h1 className="main-heading text-center fw-bold">
                        Technologies of Interest
                    </h1>
                    <div className="row">
                        {
                            serviceData.map((curElem) => {
                                const { id, logo, title, info } = curElem;
                            return(
                            <> 
                                <div className="col-11 col-lg-4 col-xxl-4 work-container-subdiv" key={id}>
                                    <i className={`fontawesome-style ${logo}`}></i>
                                        <h4 className="sub-heading">{title}</h4> 
                                        <p className="main-hero-para">{info}</p>
                                    <>{curElem.id===1 &&
                                            <Link to="/modulemain">
                                                <div class="text-center">
                                                    <button className="btn btn-style location-middle fw-bold w-50">Visit</button>
                                                </div>
                                            </Link> 
                                            
                                             }
                                        </> 

                                        <>{curElem.id===2 &&
                                            <Link to="/modulemainhydrogen">
                                                <div class="text-center">
                                                    <button className="btn btn-style location-middle fw-bold w-50">Visit</button>
                                                </div>
                                            </Link> 
                                            
                                             }
                                        </>
                                        <br/>

                                        
                                </div>
                            </>
                            );
                            })}                        
                    </div>
                </div>    
            </section>
            
        </>
    );
};

export default Technologies;
