import React from 'react';

const FAQ = () => {
    return (
        <>
            <header> 
                <section className="container main-hero-container">
                    <div className="row">
                        <div classname="col-12 col-lg-6 header-left-side d-flex justify-content 
                            align-items-start">
                           
                            <p className="main-hero-para"> 
                                <span className="span-tag text-blue">FAQ</span>  <br/>
<span className="fw-bold text-black">What is SUENGTECH?</span><br/>
SUENGTECH is a technology-based platform to estimate the Energy Yield of the project along with the estimated bill 
of quantities. Presently, this platform can be used for small grid connected rooftop or small ground mounted 
residential project. <br/>
<span className="fw-bold text-black">Who can use our SUENGTECH?</span><br/>
The platform is useful to the freelancers, independent consultants, project managers, building owners, contractors 
and installers to estimate the Energy Yield from the installation during the pre-project stage for viability as 
well as during the execution of the project.<br/>
<span className="fw-bold text-black">What is the License cost?</span><br/>
Presently, the platform is absolutely free for use by anybody having sufficient knowledge about Solar PV plant. <br/>
<span className="fw-bold text-black">Do I need any minimum System requirement? </span><br/>
There is as such no requirement. User can fill up the necessary input data as requested by the platform and 
estimated Energy Yield output file along with estimated bill of quantities file will be generated which can be 
downloaded from the platform in excel or pdf format. <br/>
<span className="fw-bold text-black">In which languages, the output file will be available? </span><br/>
Presently, the output file is available in ENGLISH language only.<br/>
<span className="fw-bold text-black">People from which countries are permitted to use this platform?</span><br/>
Any person from any part of the world can access and use this platform for their use and generate the output sheets. <br/>
<span className="fw-bold text-black">How can I ask for technical support in case of difficulty or suggestion for improvement? </span><br/>
User can use the feedback form for submitting their experience related to our platform and difficulties, if any to our 
team to look in to the issues and their resolution. <br/> 
<span className="fw-bold text-black">Where I can get the information about the new features of the upgraded platform and product? </span><br/>
User can visit our website from time to time to see the new features added in the platform and system. You can also 
subscribe to our newsletter for keeping yourself updated about our platform. 
 <br/><br/>
                            </p>
                          
                        </div>
                    </div>   
                </section>             
            </header>   
        </>
    );
};

export default FAQ;
