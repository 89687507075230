import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

import {Bar} from "react-chartjs-2";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function Energyoutput(outputData) {
    
    const currDate = new Date().toLocaleDateString()
    const currTime = new Date().toLocaleTimeString()

    
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () =>componentRef.current,
    });
    
    const history = useHistory()

    const chartData = {
            labels: ['Jan', 'Feb','Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [
                {
                    label: "Monthwise Power Generation Data in MWh",
                    data: [outputData.Output[133].toFixed( 2), outputData.Output[134].toFixed( 2), 
                    outputData.Output[135].toFixed( 2),outputData.Output[136].toFixed( 2),
                    outputData.Output[137].toFixed( 2),outputData.Output[138].toFixed( 2),
                    outputData.Output[139].toFixed( 2),outputData.Output[140].toFixed( 2),
                    outputData.Output[141].toFixed( 2),outputData.Output[142].toFixed( 2),
                    outputData.Output[143].toFixed( 2), outputData.Output[144].toFixed( 2)],
                    borderColor: "rgb(53, 162, 235)",
                    backgroundColor: "rgba(53, 162, 235, 0.4)",
                },
            ],
        };
        
        const chartOptions = {
            responsive: true,
            plugins: {
                legend: {
                    position: "top"
                },
                title: {
                    display: true,
                    text: "Monthwise Power Generation Data",
                },
            },
        };
    
        const prData = {
            labels: ['Jan', 'Feb','Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [
                {
                    label: "Monthwise Performance Ratio of the PV Plant in %",
                    data: [outputData.Output[146].toFixed( 2), outputData.Output[147].toFixed( 2), 
                    outputData.Output[148].toFixed( 2),outputData.Output[149].toFixed( 2),
                    outputData.Output[150].toFixed( 2),outputData.Output[151].toFixed( 2),
                    outputData.Output[152].toFixed( 2),outputData.Output[153].toFixed( 2),
                    outputData.Output[154].toFixed( 2),outputData.Output[155].toFixed( 2),
                    outputData.Output[156].toFixed( 2), outputData.Output[157].toFixed( 2)],
                    borderColor: "rgb(53, 162, 235)",
                    backgroundColor: "rgba(53, 162, 235, 0.4)",
                },
            ],
        };
        
        const prOptions = {
            responsive: true,
            plugins: {
                legend: {
                    position: "top"
                },
                title: {
                    display: true,
                    text: "Monthwise PR data in %",
                },
            },
        };
            
        console.log(outputData.Output[0])
        return ( <>
                <div className="print__section">
                <div ref={ componentRef } className="card">
                
                <section className="container main-hero-container">
                    <div className="row">
                        <div classname="col-12 col-lg-6 header-left-side d-flex justify-content 
                            align-items-start">
                           
                            <p className="main-hero-para"> 
                                <span className="span-tag"> Welcome to Sueng-Tech for modelling of a Solar PV based power project</span> <br/> 
                                Model running Date : { currDate } & Time : { currTime } <br/> <br/>

                                <div className="text-center span-tag"><u>Technical Input data entered by customer</u></div>
                                
                                <table width ="100%">
                                    <tr>
                                        <th style={{color: "blueviolet"}}> General Plant Information </th>
                                    </tr>
                                    <tr>
                                        <td>Name of the Plant or Project Name : { outputData.Output[0] } </td>
                                    </tr>
                                    <tr>
                                        <td>Desired DC rating of the Plant in kW: { outputData.Output[1] } </td>
                                    </tr>
                                    <tr>
                                        <td width = "50%">Latitude in degrees N or S, negative value (-) is for S : { outputData.Output[2].toFixed( 2) }</td>
                                        <td width = "50%">Longitude in degrees E or W, negative value (-) is for W : { outputData.Output[3].toFixed( 2) } </td>
                                    </tr>
                                                                            
                                    <tr>
                                        <td width = "50%">Angle of Tilt in degrees : { outputData.Output[4] } </td>
                                        <td width = "50%">Useful life of the plant in years: { outputData.Output[37] } </td>
                                    </tr>
                                    <tr>
                                        <td width = "50%">Minimum DC:AC Ratio selected : { outputData.Output[5] } </td>
                                        <td width = "50%">Maximum DC:AC Ratio selected : { outputData.Output[6] } </td>
                                    </tr>
                                </table> <br/>
                                <table width="100%">
                                    <tr>
                                        <th style={{color: "blueviolet"}}> Solar PV Module Input Information </th>
                                    </tr>
                                </table>
                                <table width="100%">                                
                                    <tr>
                                        <td width = "50%">Model name of the module : { outputData.Output[7] } </td>
                                        <td width = "50%">Maximum power rating at STC in watts : { outputData.Output[8] }</td>
                                        
                                    </tr>
                                    <tr>
                                        <td width = "50%">Open circuit voltage value in volts : { outputData.Output[9] } </td>
                                        <td width = "50%">Short circuit current in amps : { outputData.Output[10] }</td>
                                    </tr>
                                    <tr>
                                        <td width = "50%">MPP voltage value in volts : { outputData.Output[11] }</td>
                                        <td width = "50%">MPP current value in amps : { outputData.Output[12] } </td>
                                    </tr>
                                    <tr>
                                        <td width = "50%">Temperature coefficient for power output in % per degree centigrade : { outputData.Output[13] } </td>
                                        
                                    </tr>
                                    <tr>
                                        <td width = "50%">Module length in mm : { outputData.Output[14] } </td>
                                        <td width = "50%">Module width in mm : { outputData.Output[15] } </td>
                                        
                                    </tr>
                                    <tr>
                                        <td width = "50%">1st year degradation of power output of module in % : { outputData.Output[38] }</td>
                                        <td width = "50%">Yearly degradation of power output of module, 2nd year onwards in % : { outputData.Output[39] }</td>
                                    </tr>
                                    <tr>
                                        <td width = "50%">Module efficiency : { outputData.Output[16] } </td>
                                        <td width = "50%">NOCT temperature of the PV module in degree centigrade : { outputData.Output[17] } </td>
                                    </tr>
                                </table><br/>
                                
                                <table>
                                    <tr>
                                        <th style={{color: "blueviolet"}}> Solar Inverter Input Information </th>
                                    </tr>
                                </table>
                                <table width="100%">                                
                                     
                                    <tr>
                                        <td width = "50%">Inverter model name : { outputData.Output[18] } </td>
                                        <td width = "50%">AC rating in kVA : { outputData.Output[19] }</td>
                                        
                                    </tr>
                                    <tr>
                                        <td width = "50%">Inverter efficiency in % : { outputData.Output[20] } </td>
                                        <td width = "50%">Maximum dc input voltage value of the inverter in volts : { outputData.Output[21] }</td>
                                    </tr>
                                    <tr>
                                        <td width = "50%">Maximum MPP voltage in volts : { outputData.Output[22] }</td>
                                        <td width = "50%">Minimum MPP voltage in volts : { outputData.Output[23] } </td>
                                    </tr>
                                    <tr>
                                        <td width = "50%">Maximum DC input current in amps : { outputData.Output[24] } </td>
                                      {/*  <td width = "50%">Maximum DC short circuit current in Amps : { outputData.Output[25] } </td> */}
                                    </tr><br/>
                                    
                                </table>
                                <table width="100%">
                                    <tr>
                                        <th style={{color: "blueviolet"}}> Solar PV Plant Loss Input Information </th>
                                    </tr>
                                </table>
                                <table width = "100%">                            
                                     
                                    <tr>
                                        <td width = "50%">Shading loss in % : { outputData.Output[26] } </td>
                                        <td width = "50%">Soiling loss in % : { outputData.Output[27] }</td>
                                        
                                    </tr>
                                    <tr>
                                        <td width = "50%">Module quality loss in % : { outputData.Output[28] } </td>
                                        <td width = "50%">LID (Light induced degradation) loss in % : { outputData.Output[29] }</td>
                                    </tr>
                                    <tr>
                                        <td width = "50%">Module array mismatch loss in % : { outputData.Output[30] }</td>
                                        <td width = "50%">DC cabling loss in % : { outputData.Output[31] } </td>
                                    </tr>
                                                            
                                </table><br/>
                                <div className="span-tag text-center"><u>Technical System design output data calculated & predicted by the Modelling Platform</u></div>
                                
                                <table width="100%">
                                    <tr>
                                        <th style={{color: "blueviolet"}}> System Design Results </th>
                                    </tr>
                                </table>
                                <table width="100%">                                                 
                                    <tr>
                                        <td width = "100%">Total no. of modules in the PV plant are : { outputData.Output[32] } </td>
                                    </tr>
                                    <tr>
                                        <td width = "100%">Total no. of inverters in the PV plant are : { outputData.Output[33] } </td>
                                    </tr>
                                    <tr>                                        
                                        <td width = "100%">{ outputData.Output[40] } </td>
                                    </tr>
                                    <tr>                                        
                                        <td width = "100%">{ outputData.Output[41] } </td>
                                    </tr>
                                    <tr>                                        
                                        <td width = "100%">{ outputData.Output[42] } </td>
                                    </tr>                       
                                
                                    <tr>
                                        <td width = "100%">Optimized DC : AC ratio will be : { outputData.Output[43].toFixed( 2) } </td>
                                    </tr>                                 
                                </table> 
                                <table width="100%">
                                    <tr>                                        
                                        <td width = "" style={{color: "red"}}>{ outputData.Output[25] } </td> 
                                    </tr><br/>

                                </table>
                                <br/>
                                <table width="100%">
                                    <tr>
                                        <th className="span-tag text-center"> Yield Calculation Results & Performance Ratio Calculation </th>
                                    
                                    </tr>
                                </table>
                                <table width="100%"> 
                                    <tr style={{color: "navy"}}> 
                                        <th>Month</th>                                    
                                        <th>GHI</th>
                                        <th>GInc</th>
                                        <th>DNI</th>
                                        <th>DHI</th>
                                        <th>G</th>
                                        <th>T Amb</th>
                                    {/* <th>AOI</th> */}
                                        <th>E_arr_dc</th>
                                        <th>PR</th>

                                    </tr>                                             
                                    <tr> 
                                        <td> Jan </td>
                                        <td> { outputData.Output[44].toFixed( 2) } </td>
                                        <td> { outputData.Output[57].toFixed( 2) } </td>
                                        <td> { outputData.Output[70].toFixed( 2) } </td>
                                        <td> { outputData.Output[83].toFixed( 2) } </td>
                                        <td> { outputData.Output[96].toFixed( 2) } </td>
                                        <td> { outputData.Output[108].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[120].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[133].toFixed( 2) } </td>
                                        <td> { outputData.Output[146].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> Feb </td>
                                        <td> { outputData.Output[45].toFixed( 2) } </td>
                                        <td> { outputData.Output[58].toFixed( 2) } </td>
                                        <td> { outputData.Output[71].toFixed( 2) } </td>
                                        <td> { outputData.Output[84].toFixed( 2) } </td>
                                        <td> { outputData.Output[97].toFixed( 2) } </td>
                                        <td> { outputData.Output[109].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[121].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[134].toFixed( 2) } </td>
                                        <td> { outputData.Output[147].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> Mar </td>
                                        <td> { outputData.Output[46].toFixed( 2) } </td>
                                        <td> { outputData.Output[59].toFixed( 2) } </td>
                                        <td> { outputData.Output[72].toFixed( 2) } </td>
                                        <td> { outputData.Output[85].toFixed( 2) } </td>
                                        <td> { outputData.Output[98].toFixed( 2) } </td>
                                        <td> { outputData.Output[110].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[122].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[135].toFixed( 2) } </td>
                                        <td> { outputData.Output[148].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> Apr </td>
                                        <td> { outputData.Output[47].toFixed( 2) } </td>
                                        <td> { outputData.Output[60].toFixed( 2) } </td>
                                        <td> { outputData.Output[73].toFixed( 2) } </td>
                                        <td> { outputData.Output[86].toFixed( 2) } </td>
                                        <td> { outputData.Output[99].toFixed( 2) } </td>
                                        <td> { outputData.Output[111].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[123].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[136].toFixed( 2) } </td>
                                        <td> { outputData.Output[149].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> May </td>
                                        <td> { outputData.Output[48].toFixed( 2) } </td>
                                        <td> { outputData.Output[61].toFixed( 2) } </td>
                                        <td> { outputData.Output[74].toFixed( 2) } </td>
                                        <td> { outputData.Output[87].toFixed( 2) } </td>
                                        <td> { outputData.Output[100].toFixed( 2) } </td>
                                        <td> { outputData.Output[112].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[124].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[137].toFixed( 2) } </td>
                                        <td> { outputData.Output[150].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> June </td>
                                        <td> { outputData.Output[49].toFixed( 2) } </td>
                                        <td> { outputData.Output[62].toFixed( 2) } </td>
                                        <td> { outputData.Output[75].toFixed( 2) } </td>
                                        <td> { outputData.Output[88].toFixed( 2) } </td>
                                        <td> { outputData.Output[101].toFixed( 2) } </td>
                                        <td> { outputData.Output[113].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[125].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[138].toFixed( 2) } </td>
                                        <td> { outputData.Output[151].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> July </td>
                                        <td> { outputData.Output[50].toFixed( 2) } </td>
                                        <td> { outputData.Output[63].toFixed( 2) } </td>
                                        <td> { outputData.Output[76].toFixed( 2) } </td>
                                        <td> { outputData.Output[89].toFixed( 2) } </td>
                                        <td> { outputData.Output[102].toFixed( 2) } </td>
                                        <td> { outputData.Output[114].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[126].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[139].toFixed( 2) } </td>
                                        <td> { outputData.Output[152].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> Aug </td>
                                        <td> { outputData.Output[51].toFixed( 2) } </td>
                                        <td> { outputData.Output[64].toFixed( 2) } </td>
                                        <td> { outputData.Output[77].toFixed( 2) } </td>
                                        <td> { outputData.Output[90].toFixed( 2) } </td>
                                        <td> { outputData.Output[103].toFixed( 2) } </td>
                                        <td> { outputData.Output[115].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[127].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[140].toFixed( 2) } </td>
                                        <td> { outputData.Output[153].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> Sep </td>
                                        <td> { outputData.Output[52].toFixed( 2) } </td>
                                        <td> { outputData.Output[65].toFixed( 2) } </td>
                                        <td> { outputData.Output[78].toFixed( 2) } </td>
                                        <td> { outputData.Output[91].toFixed( 2) } </td>
                                        <td> { outputData.Output[104].toFixed( 2) } </td>
                                        <td> { outputData.Output[116].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[128].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[141].toFixed( 2) } </td>
                                        <td> { outputData.Output[154].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> Oct </td>
                                        <td> { outputData.Output[53].toFixed( 2) } </td>
                                        <td> { outputData.Output[66].toFixed( 2) } </td>
                                        <td> { outputData.Output[79].toFixed( 2) } </td>
                                        <td> { outputData.Output[92].toFixed( 2) } </td>
                                        <td> { outputData.Output[105].toFixed( 2) } </td>
                                        <td> { outputData.Output[117].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[129].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[142].toFixed( 2) } </td>
                                        <td> { outputData.Output[155].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> Nov </td>
                                        <td> { outputData.Output[54].toFixed( 2) } </td>
                                        <td> { outputData.Output[67].toFixed( 2) } </td>
                                        <td> { outputData.Output[80].toFixed( 2) } </td>
                                        <td> { outputData.Output[93].toFixed( 2) } </td>
                                        <td> { outputData.Output[106].toFixed( 2) } </td>
                                        <td> { outputData.Output[118].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[130].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[143].toFixed( 2) } </td>
                                        <td> { outputData.Output[156].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> Dec </td>
                                        <td> { outputData.Output[55].toFixed( 2) } </td>
                                        <td> { outputData.Output[68].toFixed( 2) } </td>
                                        <td> { outputData.Output[81].toFixed( 2) } </td>
                                        <td> { outputData.Output[94].toFixed( 2) } </td>
                                        <td> { outputData.Output[107].toFixed( 2) } </td>
                                        <td> { outputData.Output[119].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[131].toFixed( 2) } </td>  */}
                                        <td> { outputData.Output[144].toFixed( 2) } </td>
                                        <td> { outputData.Output[157].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td className="span-tag"> Annual </td>
                                        <td className="span-tag"> { outputData.Output[56].toFixed( 2) } </td>
                                        <td className="span-tag"> { outputData.Output[69].toFixed( 2) } </td>
                                        <td className="span-tag"> { outputData.Output[82].toFixed( 2) } </td>
                                        <td className="span-tag"> { outputData.Output[95].toFixed( 2) } </td>
                                        <td className="span-tag">  ------ </td>
                                        <td className="span-tag">  ------ </td>
                                    {/* <td> { outputData.Output[132].toFixed( 2) } </td> */}
                                        <td className="span-tag"> { outputData.Output[145].toFixed( 2) } </td>
                                        <td className="span-tag"> { outputData.Output[159].toFixed( 2) } </td>
                                    </tr> 
                                                                  
                                </table> <br/>
                                <table width="100%"> 
                                    <tr style={{color: "maroon"}}>
                                        <th> Abbreviations / Legends </th> 
                                    </tr>                                             
                                    <tr>GHI : Horizontal Global Irradiation in kWh / Sq.m </tr>
                                    <tr> GNI : Global Normal Irradiation in kWh / Sq.m </tr>
                                    <tr> DNI : Direct Normal Irradiation in kWh / Sq.m </tr>
                                    <tr> DHI : Diffuse Horizontal Irradiation in kWh / Sq.m </tr>
                                    <tr> T Amb : Ambient Temperature Monthwise in degree centigrade  </tr>
                                {/* <tr> AOI : Monthly Angle of Incidence of Sun rays in Degrees </tr> */}
                                    <tr> G : Insolation or Irradiation in W / Sq.m </tr>
                                    <tr> E_Arr_dc : Monthly DC Energy Produced by the Solar Module Arrays in MWh </tr>
                                    <tr> PR : Monthly Performance Ratio in % </tr>
                                    
                                </table> <br/>    

                                <section className="span-tag"> <u>Predicted Energy Output & Performance Ratio </u>
                                </section>

                                <table> 
                                    <tr className="span-tag" style={{color: "blue"}}> Yearly energy produced (DC energy) by Array of PV modules in MWh : { outputData.Output[145].toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> Yearly energy produced by the Inverters (AC energy) in MWh : { outputData.Output[158].toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> Performance Ratio of the PV Plant in % : { outputData.Output[159].toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> The minimum distance between two (2) consecutive rows of fixed tilt modules to avoid shadow overlapping or Pitch in mm : { outputData.Output[160].toFixed( 2) }</tr>
                                </table>   
                                <div>
                                    <span>
                                        <Bar options= {chartOptions}  data= { chartData} />
                                        <Bar options= {prOptions}  data= { prData} /> 
                                    </span>
                                </div> 
                                <br/>
                                    <div className="fw-bold text-black">
                                        Thank you very much for using our Solar PV SaaS program. Hope it helped you. Please provide your feedback in the comment section.
                                    </div>
                                <br/>  
                                <div className="row">
                                <div class="text-center">
                                    <button className="btn btn-style location-middle w-40 fw-bold" onClick={()=> history.push("/homelogin")}>Home</button>
                                 {/*   <button className="btn btn-style location-middle w-40 fw-bold" onClick={()=> history.push("/finmodel")}>Fin Model</button> */}
                                    <button onClick={ handlePrint } className="print__button btn btn-style location-middle fw-bold w-40"> Print</button>
                                </div>
                                <div class="text-center">
                                    <br />
                                </div>
                                </div>  
                            </p>
                          
                        </div>
                    </div>   
                          
                </section> 
                </div>    
                </div> 
                </>  
            )
    }

                                
export default Energyoutput;






