import React from 'react';

const Leadership = () => {
    return (
        <>
           <section className="common-section our-services">
                <div className="container mb-3">
                    <div className="row">
                        <div className="col-12 col-lg-4 our-service-leftside-img">
                            <img src="./Photos/Founder-1.jpg" alt="Founder-1" />
                        </div>
                            <div className="col-12 col-lg-8 our-services-list">
                               <h1 className="main-heading">Chaitanya Jee Srivastava</h1>   
                                    <div className="row our-services-info">
                                    </div>   
                                        <div className="col-12 our-services-data">
                                            <p className="main-hero-para">
                                            Chaitanya is B.Tech (IIT-Kanpur), MBA (IIM-Calcutta) and a PMP (PMI, USA). 
                                            He has more than 23 yrs. of experience in power and renewable energy sectors 
                                            across engineering, site execution, project management and strategy consulting 
                                            domains. He has helped execute large thermal, hydro and Solar PV projects as 
                                            design engineer, project manager and strategic advisor. 
                                            He is an avid learner of new tech and enthusiastic about using technology 
                                            to solve problems of clean energy sector.
                                            </p>   
                                        </div>           
                                </div>

                    </div>
                </div>

                <div className="container mb-3">
                    <div className="row">
                        <div className="col-12 col-lg-8 our-services-list">
                            <h1 className="main-heading">Parikshit Mukherjee</h1>   
                                <div className="row our-services-info">
                                </div>   
                                    <div className="col-12 our-services-data">
                                        <p className="main-hero-para">
                                            Parikshit, graduated from IIT-Kharagpur (B.Tech - Mechanical) and has more than 25 yrs. of 
                                            extensive experience and expertise in strategic planning, project engineering, project management and  
                                            site execution in the 
                                            field of power generation sector (Thermal, Solar PV, Green Hydrogen, Compressed Bio Gas (CBG), 
                                            Concentrated Solar Plant, Wind energy etc.). He has end to end execution experience starting from 
                                            project feasibility stage to project commissioning and handing over of the plant. 
                                            He has keen interest in various clean energy technologies and is passionate about developing tech-based solutions  
                                            to make a carbon free society.
                                        </p>   
                                    </div>           
                        </div>
                    
                        <div className="col-12 col-lg-4 our-service-rightside-img">
                            <img src="./Photos/Founder-2.jpeg" alt="Founder-2" />
                    </div>
                </div>
                </div>
            </section>
        </>
    )
}

export default Leadership;
