import React from 'react';
import Navbarmodule from "../navbarmodule";
import Footerlogin from "../Footerlogin";
import Header from "../Header";

const Homelogin = () => {
    return (
        <>
        <Navbarmodule />     
        <Header />
        <Footerlogin /> 
        </>
    );
};

export default Homelogin;
