import React from 'react';
import { Link } from 'react-router-dom'
import pdfEngineering from "./Output/Engineering.pdf";
import pdfFinancial from "./Output/Financial.pdf";


const Header = () => {
    return (
        <>
            <header> 
                <section className="container main-hero-container">
                    <div className="row">
                        <div classname="col-12 col-lg-6 header-left-side d-flex justify-content 
                            align-items-start">
                           
                            <p className="main-hero-para"> 
                                <span className="span-tag">SuEng-Tech</span> (Sustainable Engineering through web based Technology) is a technology-based platform 
                                to carry out the System Engineering, Financial Modelling and Project Management of the renewable & sustainable projects. 
                                We are working on technological solutions for <span className="fw-bold text-black"> Solar PV, Wind, Waste to Energy, 
                                Rainwater Harvesting & Hydrogen </span> projects.<br/>
                                <br/>This platform can be used by the Developers, EPC Contractors, Independent Consultants, Freelancers and Building Owners 
                                for the feasibility analysis, system engineering and project management of green energy projects. The solutions of this platform 
                                have been developed by the industry experts.<br/><br/> 
                                The <span className="fw-bold text-black"> System Engineering and Financial Feasibility </span> modules are <span className="fw-bold text-black">
                                active now for Solar PV Project.</span> Please click the links <a href = {pdfEngineering} target = "_blank" rel="noreferrer"><span className="fw-bold text-black"><u>
                                System Engineering</u></span></a> and <a href = {pdfFinancial} target = "_blank" rel="noopener noreferrer"><span className="fw-bold text-black"><u>
                                Financial Feasibility</u></span></a> for a sample output generated through our SuEng-Tech platform for reference.                                 
                                To use our platform and these modules, please <Link to="/login"><span className="fw-bold text-black"><u>click here.</u></span></Link><br/><br/>
                                This platform is absolutely free and can be used by any user across the globe. We encourage users to 
                                provide their comments and requirements in the feedback section for further developments. We are coming with more features, 
                                calculations and user-friendly interactive tools to make your projects value engineered and efficiently executed. Please 
                                visit regularly to check the updates from time to time. <br/><br/>
                                Welcome to SuEng-Tech. 
                                <br/><br/>Please <Link to="/register"><span className="fw-bold text-black"><u>register</u></span></Link> or insert your <Link to="/login"><span className="fw-bold text-black"><u> login</u></span></Link> details to see the available solutions. 
                                <br/><br/>
                                Please <a href="https://www.youtube.com/watch?v=7aGETxirOwo" target="_blank" rel="noreferrer"><span className="fw-bold text-black"><u>click here</u></span></a> for <span className="fw-bold text-black">user manual</span> video.
                            </p>
                          
                        </div>
                    </div>   
                </section>             
            </header>   
        </>
    );
};

export default Header;
