import React from 'react';
import Navbar from "../navbar";
import Careers from "../Careers";
import Footer from "../Footer";


const Careerspage = () => {
    return (
        <>
            <Navbar />
            <Careers />   
            <Footer />         
        </>
    );
};

export default Careerspage;