import React from 'react';
import Navbarmodule from "../navbarmodule";
import Footerlogin from "../Footerlogin";
import ScheduleADemo from "../ScheduleADemo";

const ScheduleADemologin = () => {
    return (
        <>
        <Navbarmodule />      
        <ScheduleADemo />
        <Footerlogin /> 
        </>
    );
};

export default ScheduleADemologin;
