import React from 'react';
import Navbarmodule from "../navbarmodule";
import Footerlogin from "../Footerlogin";
import TandC from "../TandC";

const TandClogin = () => {
    return (
        <>
        <Navbarmodule />      
        <TandC />
        <Footerlogin /> 
        </>
    );
};

export default TandClogin;
