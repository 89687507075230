import React from 'react';
import Navbar from "../navbar";
import Login from "../login";
import Footer from "../Footer";

const Modules = () => {
    return (
        <>
            <Navbar />      
            <Login />      
            <Footer />
        </>
    );
};

export default Modules;
