import React from 'react';
import Navbar from "../navbar";
import Errorpage from "../Errorpage";
import Footer from "../Footer";

const Error = () => {
    return (
        <>
            <Navbar />      
            <Errorpage />      
            <Footer />
        </>
    );
};

export default Error;
