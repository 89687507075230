import React from 'react';

const Disclaimer = () => {
    return (
        <>
            <header> 
                <section className="container main-hero-container">
                    <div className="row">
                        <div classname="col-12 col-lg-6 header-left-side d-flex justify-content 
                            align-items-start">
                           
                            <p className="main-hero-para"> 
                                <span className="span-tag">DISCLAIMER</span> <br/> <br/>
                                The SUENGTECH (platform) has been developed based on the available authentic 
                                literatures and prevailing practices in the solar PV industry. SUENGTECH shall not 
                                be used in any representation, advertising and or any other manner to promote any 
                                entity that adopts or uses our SUENGTECH model. SUENGTECH shall not provide any 
                                consulting or assistance of any kind with regard to the use of the platform. <br/><br/>
                                The output values of this platform are estimated values only. They are based on 
                                assumptions using prevailing industry norms & irradiation, etc. Hence, the actual 
                                results may vary from the estimated output values for which there is no commitment 
                                whatsoever. The purpose of this tool is to provide a guide for the users interested 
                                in investing in a small rooftop or ground mounted solar PV system, to assess its 
                                viability.<br/><br/>
                                You agree to indemnify SUENGTECH, and its developers, affiliates, officers, agents, 
                                and employees against any claim or demand, including reasonable lawyer' fees, 
                                related to your use, reliance, or adoption of the platform for any purpose 
                                whatsoever. In no case, SUENGTECH shall be held liable for any special, indirect 
                                or consequential damages including but not limited to claims associated with the 
                                loss of data or profits, which may result from any action in contract, negligence 
                                or other claim that arises out of with the use or performance of the platform.<br/>
 <br/><br/>
                                 
                            </p>
                          
                        </div>
                    </div>   
                </section>             
            </header>   
        </>
    );
};

export default Disclaimer;
