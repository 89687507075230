import React from 'react';

const Privacypolicy = () => {
    return (
        <>
            <header> 
                <section className="container main-hero-container">
                    <div className="row">
                        <div classname="col-12 col-lg-6 header-left-side d-flex justify-content 
                            align-items-start">
                           
                            <p className="main-hero-para"> 
                                <span className="span-tag">PRIVACY POLICY</span> <br/> 
                                <span className="fw-bold text-black">1. Policy Purpose </span><br/>
1.1 Policy: This policy applies to all users of the SUENGTECH platform.<br/>
1.2 Purpose: The purpose of this Policy is to let users of the platform to know what we do with personal 
information of the users. We do not use or share personal information except as set out in this Privacy Policy. 
Personal information means that identifies you as an individual. <br/>
<span className="fw-bold text-black">2. Information related to Data Collection </span><br/>
2.1 non-disclosure requirement: The SUENGTECH platform can be viewed without the need for you to disclose any 
personal information to us except the details as requested in the LOGIN creation page.<br/>
2.2 Fees: Presently, the SUENGTECH platform is free to use and no subscription or license fee is associated with it. <br/> 
2.3 Data types: We may collect and use the following data about you from the platform: <br/>
(a) personal information provided by you at the time of LOGIN creation including your name, address, email address,
 occupation, and any other personal information that you provide to us.<br/>
(b) technical information which includes your device identifier, server address, IP address, domain, browsers used 
when accessing SUENGTECH platform (including version), your search terms, links that are clicked on, date and time 
of visits, your operating systems, and any other such technical information relating to your use of the SUENGTECH 
platform.<br/>
2.4 Data Sources: We may collect and hold personal information about you from the following sources:<br/>
(a) you, by filling in the LOGIN creation form when you use our platform;<br/>
(b) indirectly, through your use of the SUENGTECH platform, including by automated technologies and interactions;<br/>
(c) through your interaction with our administrators, such as by telephone or email;<br/>
(d) through cookies and Google analytics; and<br/>
(e) provided by third parties authorized by you.<br/>
2.5 Unavailability of SUENGTECH to user: Failure to provide necessary personal information when requested may result 
in SUENGTECH not being available to you.<br/>
2.6 Changes: Users are able to access, change and update their details (name, email address, password, and credit 
card information) on their account details page.<br/>
<span className="fw-bold text-black">3. Data Disclosure policy</span><br/>
3.1 Disclosure: We will not disclose personal information we hold about you to any third party except as set out 
in this Privacy policy document.<br/>
3.2 Service providers associated with SUENGTECH: SUENGTECH platform work with third (3rd) party company or 
individual service providers who provide help, hosting, maintenance, backup, storage, infrastructure, payment 
processing, analysis, marketing and other services for us, which may require access of your information provided 
in our SUENGTECH platform. If the service provider needs to access your information to perform services on our 
behalf, they do so under instruction from us, including abiding by policies and procedures designed to protect 
your information. These service providers may be located in countries across globe. <br/>
3.3 Data Sharing for Legal obligations: We may share your information with a third party if that sharing is 
reasonably necessary to:<br/>
(a) comply with national security requirements, governmental request, applicable laws, regulation or legal 
process,<br/>
(b) ensure compliance to all our agreements, policies, terms, security or integrity of our SUENGTECH platform and 
related services<br/>
(c) protect the Intellectual Property Rights of any person or company and protect us, you or the public from harm 
or illegal activities,<br/>
3.4 Future Sale of our business: If we sell the business or any of our assets in near future to a prospective 
buyer, your personal information as available in our SUENGTECH platform may have to be provided as part of a 
database so that they can consider the purchase of the business, can continue to operate the business and 
contact you about their plans for the business.<br/>
<span className="fw-bold text-black">4. Data Use policy</span><br/>
4.1 We use the data we collect from our platform for the following purposes:<br/>
(a) to hold and to contact you as necessary including:<br/>
•	to enable you to access and use our platform;<br/>
•	to notify you about product, policy and any future requirement of use by sending emails to the registered 
email address;<br/>
•	to communicate with you at any time;<br/>
•	to check misuse of the platform and or its functionality;<br/>
(b) to administer and manage your access to our platform, verifying your identity and assisting you if you forget 
your user ID and/or password;<br/>
(c) to further develop and improve the platform and ensure that Content on the platform is relevant, of interest 
to you and presented in the most effective manner for you;<br/>
(d) to monitor who is accessing the platform or using the services offered including for marketing or internet 
security purposes – this information may be shared with trusted third-party service providers;<br/>
(e) to maintain and update records and details of people who accessed our platform;<br/>
(f) to check for any promotions, surveys and other services;<br/>
(g) we may anonymize data such that you cannot be identified so we can understand how our platform is used; <br/>
(h) for any other purpose that you agree and authorize us<br/>
4.2 If you request us not to use personal information in a particular manner, please send us your concern in 
feedback section to understand more by our team. We will adopt all reasonable measures to observe your request, 
but we may still need to disclose it.<br/>
<span className="fw-bold text-black">5. Policy on Cookies</span><br/>
5.1 Use of Cookies: We may use a "cookie" file, tracking pixels and other related technologies containing 
information that can identify the computer, smartphone or other web–enabled device that you are working from.<br/>
5.2 Usage of Cookies: We may use the information generated by cookies to:<br/>
(a) understand traffic patterns to and from the platform including information like the pages you visit, the 
time you spend on the platform, the date and time of your visit, and pages you came from or go to;<br/>
(b) ensure proper place of advertising for viewing and limit the frequency of display;<br/>
(c) enable you to enter the platform and use services as a registered User without having to log on each time;<br/>
(d) enable us and our associates to serve targeted advertising to you.<br/>
5.3 Turning-off option of cookies: The browsers of most computers, smartphones and other web–enabled devices are 
usually set up to accept cookies. If your browser preferences allow it, you can configure your browser to accept 
all cookies, reject all cookies, or notify you when cookies are set. Each browser is different, so check the “Help” 
menu of your browser to learn about how to change your cookie preferences. You do not need to have cookies turned 
on to use the platform, but you may need them for viewing the various features that we have now or in the future. 
Please remember that cookies are often used to enable and improve certain functions on our platform. If you choose 
to switch certain cookies off, it may affect how the platform works and you may not be able to access all or parts 
of the platform. <br/>
5.4 Use of Google Analytics: Google Analytics may be used in our platform to understand traffic patterns to and 
from our platform, aggregating anonymous information about visits, and randomly and anonymously surveying users 
during their visits. The system is used to collect information from the visit of the user about the number of 
unique visitors, how long these visitors spend time in our platform, use of common entry and exit points into 
and from the platform, no of times they visit in a day or weeks or months. Random anonymous surveys are also 
used to collect further information about you. This non-personal information is aggregated using Google Analytics 
and then used by us in our analysis of the platform. The Google Analytics data is also accessible by other 
organizations including media and research companies for the purpose of conducting industry comparisons with 
other similar platforms or websites. All our pages are coded with a small piece of Google Analytics code that is 
transparent to you when you read that page. The platform stores a cookie in your browser (if you have enabled 
cookies) which contains a unique identifier to allow us to track the number of unique visitors to the platform.
 This information is collected and aggregated by Google Analytics and in no way can you be identified personally 
 and no personal information is stored about you.<br/>
<span className="fw-bold text-black">6. Links</span> <br/>
6.1 The platform may include links to third-party websites. These sites are provided as a convenience and does 
not imply our endorsement of the sites or any association by us with their business or owners. We are not 
responsible for the data protection / privacy / cookie usage policies of any sites linked in our platform and 
these sites may not follow the same privacy policies as us. We recommend that you check any relevant privacy 
policies of such Linked Sites before providing your personal information to any third party.<br/>
<span className="fw-bold text-black">7. Data Security</span><br/>
7.1 We use reasonable safeguards to protect personal information of our Users against theft, loss or unauthorized 
access, use, modification, disclosure or disposal. It is to be noted that no data transmission over the Internet 
can be guaranteed to be completely secure. We cannot ensure or warrant the security of any information you transmit 
or receive through the platform. These activities are conducted at your own risk.<br/>
<span className="fw-bold text-black">8. Options on receiving Communication</span><br/>
8.1 You may opt in to agree to be contacted by us in relation to notification of new features to the platform and 
promotional activities.<br/>
8.2 You may opt out of receiving communications from us by editing your email setting or be removed from our 
general mailing list. If you choose not to receive emails or ask to be removed from a mailing list, we may 
still send you emails where it is necessary to communicate with you related to our platform.<br/>
<span className="fw-bold text-black">9. Corrections of your Personal Data </span><br/>
9.1 You have the right to request access to your personal information. <br/>
9.2 You have the right to request correction of any of your personal information provided to us, at any time and 
as often as necessary.<br/>
9.3 You can contact us at any time by any of the means set out below.<br/>
9.4 You will not usually have to pay a fee to access your personal information. We may charge a reasonable fee 
permitted by law if your request is clearly unfounded, repetitive or excessive in nature.<br/>
9.5 We may need to request specific information from you to help us confirm your identity for security reason 
and ensure your right to access your personal information. <br/>
9.6 If you are not satisfied with our response to any privacy-related concern you may have, you can escalate 
the issue to our management to look in to it.<br/>
<span className="fw-bold text-black">10. Privacy Policy Change</span><br/>
10.1 We reserve right to change this Privacy Policy at any time. The change may be in the form of Amendments 
or complete replacement of the policy. By continuing to access the platform, you agree to be bound by the policy 
change from time to time. We recommend you to regularly review the Privacy Policy to see any updates or changes 
from our existing Privacy Policy.<br/>
 
                            </p>
                          
                        </div>
                    </div>   
                </section>             
            </header>   
        </>
    );
};

export default Privacypolicy;
