import React, { useState } from 'react';
import { NavLink } from "react-router-dom";

const Navbar = () => {
    const [show, setShow] = useState(false);
    return (
        <>
<section className="navbar-bg">
           <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
  <div className="container">
    <a className="navbar-brand" href="/#"><i className = "fas fa-sun"></i><strong>SuEng Tech </strong> <h6> A Technology Company for Hydro Carbon Free Society </h6></a>
    
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
      onClick={() =>setShow(!show)}>  
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className={`collapse navbar-collapse ${show ? "show" : ""}`} >
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
        <li className="nav-item">
         <NavLink className="nav-link" aria-current="page" to="/">Home</NavLink>
          
        </li>
        
        <li className="nav-item">
          <NavLink className="nav-link" to="/technologieshome">Technologies</NavLink>
    </li> 
        <li className="nav-item">
          <NavLink className="nav-link" to="/founders">Founders</NavLink>
        </li>

      {/*  
        <li className="nav-item">
          <NavLink className="nav-link" to="/experts">Our Experts</NavLink>
        </li>

        */}
        <li className="nav-item">
          <NavLink className="nav-link" to="/contact">Contact us</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="/register">Register</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="/login">Login</NavLink>
        </li>
        
      </ul>
      
    </div>
  </div>
</nav> 
</section>
        </>
    )
}

export default Navbar;
