import React from 'react';

const ScheduleADemo = () => {
    return (
        <>
            <header> 
                <section className="container main-hero-container">
                    <div className="row">
                        <div classname="col-12 col-lg-6 header-left-side d-flex justify-content 
                            align-items-start">
                           
                            <p className="main-hero-para"> 
                                <span className="span-tag">Please <a href="https://www.youtube.com/watch?v=7aGETxirOwo" target="_blank" rel="noreferrer"><span className="fw-bold text-black"><u>click here</u></span></a> for USER MANUAL video. </span><br/><br/>
                            
                                <span className="span-tag">Please <a href="https://youtu.be/V2RQiF89Wr4" target="_blank" rel="noreferrer"><span className="fw-bold text-black"><u>click here</u></span></a> for detail understanding on how to use our platform and fill the input data in the FORM. <br/> This is in Hindi language. </span> 
                                                               
                            </p>
                          
                        </div>
                    </div>   
                </section>             
            </header>   
        </>
    );
};

export default ScheduleADemo;
