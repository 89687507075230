import React, { useState } from 'react'; 

const ContactUs = () => { 
    const [userData, setUserData] = useState({
        firstName:"",
        lastName: "",
        phone: "",
        email: "",
        country:"",
        address: "",
        message: "",
    });
    
    let name, value;
    const postUserData = (event) => {
        name = event.target.name;
        value= event.target.value;
        setUserData({ ...userData, [name]: value });
    }; 

    const submitData = async (event) => {        
        event.preventDefault();
        const { firstName, lastName, phone, email, country, address, message } = userData;
        
        if(firstName && lastName && phone && email && country && address && message )
        {
        const res = fetch('https://suengtech-b07cc-default-rtdb.firebaseio.com/userDataRecords.json', 
        {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            }, 
            body: JSON.stringify({
                firstName, 
                lastName, 
                phone, 
                email, 
                country, 
                address, 
                message,
            }),
        }
      );

      if (res) {
          setUserData({
            firstName:"",
            lastName: "",
            phone: "",
            email: "",
            country:"",
            address: "",
            message: "",              
          })

          alert("Data Stored");
      } else {
          alert ("Please Fill The Data");
        }
      } else {
          alert ("Please Fill The Data");
        }
    };

    return (
        <>
          <section className="contactus-section">
              <div className="loginregister container">
                  <div className="row">
                      <div className="col-12 col-lg-10 mx-auto">
                        <div className="row">
                            <div className="contact-leftside col-12 col-lg-5">
                                <h2 className="main-heading fw-bold">
                                    Contact Our Team
                                </h2>
                                <p className="main-hero-para"> Please fill the 
                                form and send us your message</p>
                                <figure>
                                    <img src="./Photos/1-Images.jpg" alt="Form Fill" 
                                    className="img-fluid"/>
                                </figure>
                            </div>
                            <div className="contact-rightside col-12 col-lg-7">
                                <form method="post">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 contact-input-field">
                                            <input type="text" name="firstName" id="" className="form-control" 
                                            placeholder="First Name"
                                            value = {userData.firstName}
                                            onChange = {postUserData}
                                        />
                                        </div>
                                        <div className="col-12 col-lg-6 contact-input-field">
                                            <input type="text" name="lastName" id="" className="form-control" 
                                            placeholder="Last Name"
                                            value = {userData.lastName}
                                            onChange = {postUserData}
                                            />

                                        
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6 contact-input-field">
                                            <input type="text" name="phone" id="" className="form-control" 
                                            placeholder="Mobile No"
                                            value = {userData.phone}
                                            onChange = {postUserData}
                                            />

                                        
                                        </div>
                                        <div className="col-12 col-lg-6 contact-input-field">
                                            <input type="text" name="email" id="" className="form-control" 
                                            placeholder="E-mail ID"
                                            value = {userData.email}
                                            onChange = {postUserData}
                                            />

                                        
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6 contact-input-field">
                                            <input type="text" name="country" id="" className="form-control" 
                                            placeholder="Country"
                                            value = {userData.country}
                                            onChange = {postUserData}
                                            />

                                        
                                        </div>
                                        <div className="col-12 col-lg-6 contact-input-field">
                                            <input type="text" name="address" id="" className="form-control" 
                                            placeholder="Address"
                                            value = {userData.address}
                                            onChange = {postUserData}
                                            />

                                        
                                        </div>
                                        
                                    </div>
                                    <div className="row">
                                        <div className="col-12 contact-input-field">
                                            <input type="text" name="message" id="" className="form-control" 
                                            placeholder="Message Here"
                                            value = {userData.message}
                                            onChange = {postUserData}
                                            />

                                        
                                        </div>
                                    </div>
                                    
                                {/*
                                    <div class="form-check form-checkbox-style">
                                        <input class="form-check-input" type="checkbox" value="" 
                                        id="flexCheckChecked" />
                                        <label class="form-check-label" className="main-hero-para" checked>
                                            I agree to the terms and conditions of this website and 
                                            application. 
                                        </label>
                                    </div>
                                */}
                                <br/>
                                    <button type="Submit" className="btn btn-style w-100" 
                                    onClick={submitData}>
                                        Submit
                                    </button>
                                
                                </form>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactUs;
