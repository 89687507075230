import React, { useState } from 'react';
import hydrogenapi from "./API/hydrogenAPI.js";
import { Link, NavLink } from 'react-router-dom'
import xlsxCost from "./cost/cost.xlsx";

const Modulemainhydrogen = () => {
    const [ serviceData ] = useState(hydrogenapi);
    return (
        <>
            
            <section className="service-main-container">
                <div className="container service-container">
                    <h1 className="main-heading text-center fw-bold">
                        MODULES FOR GREEN HYDROGEN GENERATION PLANT
                    </h1>
                    <div className="row">
                        {
                            serviceData.map((curElem) => {
                                const { id, logo, title, info } = curElem;
                            return(
                            <> 
                                
                                <div className="col-11 col-lg-4 col-xxl-4 work-container-subdiv" key={id}>
                                   
                                    <i className={`fontawesome-style ${logo}`}></i>
                                        <h4 className="sub-heading">{title}</h4> 
                                        <p className="main-hero-para">{info}</p>
                                    {/*    <>{curElem.id===1 &&
                                            <Link to="/energymodel"><span className="fw-bold text-black"><u>Click Here for Engineering Module.</u></span></Link> 
                                             }
                                            </>    */}


                                        <>{curElem.id===1 &&
                                            <Link to="/hydrogenmodel">
                                                <div class="text-center">
                                                    <button className="btn btn-style location-middle fw-bold w-50">Enter</button>
                                                </div>
                                                
                                            </Link> 
                                             }
                                        </> 
                                       {/*
                                        <>{curElem.id===2 &&
                                            <Link to="/finmodel"><span className="fw-bold text-black"><u>Click Here for Financial Module.</u></span></Link> 
                                            
                                           <Link to="/finmodel">
                                                <div class="text-center">
                                                    <button className="btn btn-style location-middle fw-bold w-50">Visit</button>
                                                </div>
                                                
                                            </Link> 
                                            }
                                        </>  

                                        <>{curElem.id===2 &&
                                            <Link to="/finmodel">
                                                <div class="text-center">
                                                    <button className="btn btn-style location-middle fw-bold w-50">Enter</button>
                                                </div>
                                                
                                            </Link> 
                                            }
                                        </> 

                                        <>{curElem.id===3 &&
                                            <a href = {xlsxCost} target = "_blank" rel="noopener noreferrer">
                                                <div class="text-center">
                                                    <button className="btn btn-style location-middle fw-bold w-50">Download</button>
                                                </div>
                                                
                                            </a> 
                                             }
                                        </> */}
                                        <br/>
                                        
                                </div>
                               
                            </>
                            );
                            })}                        
                    </div>
                </div>    
            </section>
            
        </>
    );
};

export default Modulemainhydrogen;
