import React from 'react';

const ScheduleADemo = () => {
    return (
        <>
            <header> 
                <section className="container main-hero-container">
                    <div className="row">
                        <div classname="col-12 col-lg-6 header-left-side d-flex justify-content 
                            align-items-start">
                           
                            <p className="main-hero-para"> 
                                <span className="span-tag">We are looking for investors who are interested to invest in 
                                clean energy solution through technology. Interested investors may contact us either by filing the 
                                CONTACT US form or send direct e-mail at suengtechco@gmail.com. </span> 
                            </p>
                          
                        </div>
                    </div>   
                </section>             
            </header>   
        </>
    );
};

export default ScheduleADemo;
