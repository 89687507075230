import React, { useState } from 'react';
import axios from "axios";
import { useHistory } from "react-router-dom";

const Register = () => {
    const history = useHistory()
    const [ user, setUser ] = useState({
        name: "",
        email: "",
        password:"",
        reEnterPassword: ""
    })

    const handleChange = e => {
        const { name, value } = e.target 
        
        setUser({
            ...user, [name]: value
            
        })
    }

const register = () => {
    const { name, email, password, reEnterPassword } = user
    if(name && email && password && (password === reEnterPassword)) {
        alert("Submit data?")
        axios.post("https://suengtech-loginbackend-suengtech.vercel.app/register", user)
        .then(res => { 
            console.log(res.data.message)
            history.push("/login")
    }) 
    } else {
        alert("Insert Valid Values")
    }    
}


return (
        
    <section className="loginregister container main-hero-container">
            <div className="row">
                <div classname="col-12 col-lg-6 header-left-side d-flex justify-content align-items-center ">
                    <div className="register">
                        {console.log("User", user)}
                        <h1>Register</h1>
                        <input type="text" name="name" value={ user.name } placeholder="Enter your Name & Last Name" onChange={ handleChange }></input>
                        <input type="text" name="email" value={ user.email } placeholder="Enter Email" onChange={ handleChange }></input>
                        <input type="password" name="password" value={ user.password } placeholder="Enter Password" onChange={ handleChange }></input>
                        <input type="password" name="reEnterPassword" value={ user.reEnterPassword } placeholder="Re-enter Password" onChange={ handleChange }></input>
                        <div className="button" onClick = {register}>Register</div>
                        <div>or</div>
                        <div className="button" onClick={() => history.push("/login")}>Login</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Register;
