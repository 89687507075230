import React from 'react';
import Navbarmodule from "../navbarmodule";
import Footerlogin from "../Footerlogin";
import Modulemain from "../Modulemain";

const Modulesmainpage = (setLoginUser) => {
    return (
        <>
        <Navbarmodule />      
        <Modulemain />
        <Footerlogin /> 
        </>
    );
};

export default Modulesmainpage;
