import React from 'react';
import Navbarmodule from "../navbarmodule";
import FAQ from "../FAQ";
import Footerlogin from "../Footerlogin";


const FAQlogin = () => {
    return (
        <>
            <Navbarmodule />
            <FAQ />   
            <Footerlogin />         
        </>
    );
};

export default FAQlogin;