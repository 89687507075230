

const modulesapi = [
    {
        id: 1,
        logo: "fas fa-solar-panel",
        title: "System Engineering Module",
        info: "Ask users for various inputs and then Calculates the optimum Module nos. for Series & Parallel, Energy Losses and Predicted Annual Energy output of the plant.",
        
    },
    {
        id: 2,
        logo: "fas fa-dumpster-fire",
        title: "Project Financial Module",
        info: "This Module provides User the Financial modelling of the Project with respect to the Capital cost, annual O&M cost, Depreciation, Interest, Return on Investment (ROI), Internal rate of return (IRR) and LCOE over the PPA / Contracted period.",
    },

    {
        id: 3,
        logo: "fas fa-database",
        title: "Bill of Quantity (BOQ) & Cost Module",
        info: "Provides users a suggestive BOQ for the main items of the Solar PV Plant. The Module will also be provided with a cost estimation sheet which can be used by the proposal team & management team and decides the final cost of the project",
    },

    {
        id: 4,
        logo: "fas fa-hand-holding-water",
        title: "Schedule Management",
        info: "Schedule Management Module provides User a handy tool to monitor day to day planning & scheduling in the project w.r.t Engineering, Procurement, Construction, Commissioning, Operation & Maintenance activities before handover etc. with an alarm to the management of any potential delays.",
    },

    {
        id: 5,
        logo: "fas fa-gas-pump",
        title: "Quality Management",
        info: "Quality Management Module provides options to the User for various quality checks required during the procurement, construction & commissioning phases of the project.",
  },

    {
        id: 6,
        logo: "fas fa-industry",
        title: "Dash Board",
        info: "This Module provides the management team to Monitor and Track the project progress with respect to Project Management, Cost Management, Estimated Project Completion based on progress achieved, Alarms and Flags and Contractual Issues ",
   },
];

export default modulesapi;