import React from 'react';
import Navbarmodule from "../navbarmodule";
import Footerlogin from "../Footerlogin";
import Training from "../Training";

const Technologieslogin = () => {
    return (
        <>
        <Navbarmodule />     
        <Training />
        <Footerlogin /> 
        </>
    );
};

export default Technologieslogin;
