const serviceapi = [
    {
        id: 1,
        logo: "fas fa-solar-panel",
        title: "Solar PV",
        info: "PV modules convert sunlight into electrical energy. PV cells are connected together in chains to form larger units known as modules or panels. Visit solar PV section for more details.", 
        
    },
    {
        id: 2,
        logo: "fas fa-gas-pump",
        title: "Green Hydrogen",
        info: "Green hydrogen is produced by electrolysis of water into Hydrogen and Oxygen. No greenhouse gases are produced during this process and electricity used is generated from renewable energy technologies.",
    },
    {
        id: 3,
        logo: "fas fa-poop",
        title: "Compressed Bio-Gas (CBG)",
        info: "Bio-gas is generated using animal manure, crop residues, vegetable residues, poultry wastes etc. through Anaerobic digestion process. The generated gas is then cleaned and compressed to the requirement of local Gas Station to feed as fuel to vehicles or household application.",
    },
    

    {
        id: 4,
        logo: "fas fa-database",
        title: "Wind Energy",
        info: "Wind turbines generate power due to rotation of turbine blades by the local wind (in areas having adequate wind velocity).",
    },

    {
        id: 5,
        logo: "fas fa-hand-holding-water",
        title: "Rain Water Harvesting",
        info: "Rainwater harvesting consists of collection, storing, purification, pumping of rainwater for human use. Rainwater can be used as washing, gardening etc.",
    },
    {
        id: 6,
        logo: "fas fa-dumpster-fire",
        title: "Waste to Energy",
        info: "Waste-to-energy(WtE) is the process of generating energy in the form of electricity or bio-gas and byproduct such as compost for industrial and domestic application from the processing of municipal solid waste.",
    },

    
    
];

export default serviceapi;