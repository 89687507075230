import React from 'react';
import Navbar from "../navbar";
import Register from "../register";
import Footer from "../Footer";


const Registerpage = () => {
    return (
        <>
            <Navbar />
            <Register />   
            <Footer />         
        </>
    );
};

export default Registerpage;