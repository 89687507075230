import React from 'react';

const TandC = () => {
    return (
        <>
            <header> 
                <section className="container main-hero-container">
                    <div className="row">
                        <div classname="col-12 col-lg-6 header-left-side d-flex justify-content 
                            align-items-start">
                           
                            <p className="main-hero-para"> 
                                <span className="span-tag">TERMS AND CONDITIONS OF USE OF THIS PLATFORM</span><br/><br/> 
                                <span className="fw-bold text-black">1. SUENGTECH Platform </span><br/>
1.1. The “Terms and Conditions” and our “Privacy Policy” apply to all users of our SUENGTECH platform, whether as 
a User or as a visitor, and whether just browsing or purchasing Products from the Website.<br/>
1.2. If you do not agree to these “Terms and Conditions” and the “Privacy Policy”, then you must stop browsing, 
accessing or using our SUENGTECH platform. <br/>
<span className="fw-bold text-black">2. Words and their Meaning in the context of SUENGTECH </span><br/>
2.1. In these Terms and Conditions, the following words have been used and their meanings are elaborated below:<br/>
“Content” means any platform, software, data, information, picture, graphics and other materials published or 
made available on the platform, including all third party content.<br/>
“Intellectual Property Rights” means any patent, trade mark, service mark, logo, trade name and business name 
(including rights in goodwill), copyright, moral right, right in a design, drawings, graphics, documents, data, 
ideas, procedures, calculations, right in or to internet domain names, know-how, right in or to confidential 
information (including trade secrets) and any other intellectual property rights, whether or not registered and 
any similar rights worldwide.<br/>
"User" means a user that has completed our LOGIN or User registration process and created an account to use the 
services offered on this platform.<br/>
“Products” means goods and services relating to building and construction being offered through this platform, 
electronic and/or hardcopy information resources ranging from books, research reports, technical papers, bulletins,
 webinars and other information.<br/>
“Unacceptable” means anything that is defamatory, harmful, offensive, upsetting, obscene, inappropriate, false, 
misleading, unsuitable for persons under the age of 18 years, in violation of any law or regulation, or otherwise 
considered by us as being unacceptable.<br/>
“We", "us" and "our" refers to the Developers of the SUENGTECH platform.<br/>
“You" and "your" refers to a SUENGTECH User.<br/>
<span className="fw-bold text-black">3. Requirement to be considered as SUENGTECH User</span><br/>
3.1. You acknowledge and agree that you will not do any of the following while being a SUENGTECH user:<br/>
(a) access or use any part of the platform or its Content for the purposes of competing with us;<br/>
(b) access or print material from the platform for any commercial purpose without prior written consent from us;<br/>
(c) on-sell any information or Content obtained from the platform without our prior consent;<br/>
(d) provide us with inaccurate, misleading or false information;<br/>
(e) post or transmit any Content that is Unacceptable in nature;<br/>
(f) abuse, harass, breach the confidence of or otherwise violate the rights (such as rights of privacy and 
publicity) of others;<br/>
(g) post or transmit Content that infringes the Intellectual Property Rights of any person;<br/>
(h) accessing others account ;<br/>
(i) manipulate or interfere with the platform, or post or transmit any Content that contains any virus, malware, 
spyware or other destructive component;<br/>
(j) harvest or collect any Content either manually or via an automated software tool;<br/>
(k) commit or encourage a criminal offence, or post or transmit any Content that is prohibited by or violates 
any applicable law or regulation;<br/>
(l) transmit any unsolicited advertising or promotional materials;<br/>
(m) any other conduct breaches the Terms of Conditions<br/>
<span className="fw-bold text-black">4. Specific Terms of use of SUENGTECH </span><br/>
4.1. This part is valid to the registered Users of SUENGTECH.<br/>
4.2. To become a SUENGTECH User of the platform, you must complete the online LOGIN registration form, which will 
include creating a user login and password. By completing the registration process, you are stating that you are 
eligible to be a SUENGTECH User and that you agree to be bound by the SUENGTECH User specific terms, in addition 
to the other terms specified in Terms and Conditions & Privacy Policy. We reserve the right to reject any 
application or suspend access of any user at any time.<br/>
4.3. It is deemed that any information you provide to us relating to you through the User registration process, 
or subsequent to registration, is current, complete and accurate at the time you provide it, is not misleading or 
deceptive, or likely to mislead or deceive. If your details change whilst you are an User, you agree to update such
 information in a timely manner (which can be done by logging onto the platform).<br/>
4.4. You must ensure that your user ID and password is kept secure and confidential and not revealed to any other 
person. You are entirely responsible for all activities that occur through the use of your user ID and password. 
You must notify us immediately of any unauthorized use of your user ID and password or any other breach of 
security. Following any unauthorized use, you will cease using that password and create a new unrelated password. 
You indemnify us, our developers, our directors, officers and employees against all costs, expenses and damages 
incurred in connection with any claim arising from any reasonable reliance by us on any use of your password, 
including us disclosing information relating to your being an User of our platform.<br/>
4.5 The normal mode of communication will be email until you indicate otherwise. These communications may include 
information about our Products, features of the platform, notices about applicable fees and charges, transactional
 information and other information concerning or related to the platform. <br/>
4.6. You may terminate your membership at any time by giving us [30] days' notice. We reserve the right in our 
sole discretion to terminate your membership at any time if you violate the Specific Terms of Use and Other Terms 
& Conditions applicable for the SUENGTECH platform.<br/>
<span className="fw-bold text-black">5. Recommendations and Advices</span><br/>
5.1.  The platform provides impartial evidence-based advices and recommendations on critical issues as 
applicable.<br/>
5.2. When a User purchases a Product, a contract will be formed between that User and us, and our Terms of Sale 
will apply. Terms of Sale shall be provided at time of sale and purchase of the product.<br/>
5.3. All information and Products provided on or through the platform are intended to be general in nature, and 
is provided to you on an “as is” basis. We do not:<br/>
(a) give any assurances that any information contained on the platform or in any Products will be suitable for 
your purposes; or<br/>
(b) warrant the accuracy, correctness, reliability and completeness of any information provided in, or obtained 
through the use of the SUENGTECH platform.<br/>
5.4. If you register yourself as SUENGTECH user, you must complete the transaction in the manner specified.<br/>
<span className="fw-bold text-black">6. Fees </span><br/>
6.1. There is no fee for registering as a SUENGTECH User or being a User of the SUENGTECH platform.<br/>
<span className="fw-bold text-black">7. Other Important Requirement</span><br/>
7.1. We reserve the right to update and amend any information on the platform at any time. We do not warrant that 
you will have continued access to the Website.<br/>
7.2. We are not liable to you for any form of loss or damage however caused (including direct, indirect, 
incidental, special or consequential loss or damage), including:<br/>
(a) in relation to your access and use of the platform, including if the platform is unavailable (in whole or part) 
or performing slowly; or<br/>
(b) in connection with any errors, omissions or misstatements in any information or material made available on or 
through the platform;<br/>
(c) in relation to SUENGTECH platform;<br/>
(d) resulting from any breach of these Terms and Conditions by User. <br/>
7.3. You will take all necessary action to defend and indemnify us, our developers, our directors, officers and 
employees against all loss and damages suffered or incurred in connection with any claim brought by a third party 
against us arising from a breach by you of these Terms and Conditions of Use or the Privacy Policy.<br/>
7.4 We own:<br/>
(a) all the Intellectual Property Rights in the platform and Content (excluding any third-party Content). <br/>
(b) the various trademarks that appear on the platform. You must not use any of our trademarks without our prior 
written consent.<br/>
7.5 If we notice or consider that you have breached the Terms & Conditions, we may immediately and without written 
notice to you, suspend or terminate your access to the platform. On suspension or termination, you must immediately 
cease using the platform.<br/>
7.6 We reserve the right to change, amend or complete replacement of the Terms & Conditions at any time. 
By continuing to access the platform, you agree to be bound by these changes and amendments. We recommend you 
to regularly review the Terms and Conditions to see any updates or changes from our existing Terms of Conditions.<br/>
7.7 The Terms & Conditions are governed by and will be construed in accordance with the laws of India. <br/>
<span className="fw-bold text-black">8. Other Linked Sites</span><br/>
8.1 The platform may contain links to other third-party websites and these are not operated, controlled, or 
maintained by us. The inclusion of any link does not imply our endorsement of the Sites or any association by 
us with their business or owners.<br/>
8.2 Any link to a Linked Site does not constitute sponsorship, endorsement, or approval by us of the content, 
policies, or practices of such Linked Sites.<br/>

                                 </p>
                          
                        </div>
                    </div>   
                </section>             
            </header>   
        </>
    );
};

export default TandC;
