import React from 'react';
import Navbarsolar from "../navbarsolar";
import EnergyModel from "../EnergyModel";
import Footerlogin from "../Footerlogin";

const EnergyModelpage = (setLoginUser) => {
    return (
        <>
            <Navbarsolar />      
            <EnergyModel />      
            <Footerlogin />
        </>
    );
};

export default EnergyModelpage;


