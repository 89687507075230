import React from 'react';
import Navbarsolar from "../navbarsolar";
import FinModel from "../FinModel";
import Footerlogin from "../Footerlogin";

const FinModelpage = (setLoginUser) => {
    return (
        <>
            <Navbarsolar />      
            <FinModel />
            <Footerlogin />
        </>
    );
};

export default FinModelpage;

