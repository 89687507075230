import React from 'react';

const Ourexperts = () => {
    return (
        <>
<header>
            <section className="common-section our-services">
                <div className="container mb-3">
                    <div className="row">
                        <div className="col-12 col-lg-4 our-service-leftside-img">
                            <img src="./Photos/Expert-1.jpg" alt="Expert-1" />
                        </div>
                            <div className="col-12 col-lg-8 our-services-list">
                               <h1 className="main-heading">Braj Nandan Singh</h1>   
                                    <div className="row our-services-info">
                                    </div>   
                                        <div className="col-12 our-services-data">
                                            <p className="main-hero-para">
                                            Braj Nandan Singh, is a Mechanical Engineering Graduate, 
                                            a Post Graduate in Management from Symbiosis, Pune, a Certified 
                                            Energy Auditor and an experienced professional in the field of 
                                            Engineering, Project Management  & Construction Management. 
                                            He has 25 years of professional experience in Thermal Power 
                                            Generation Plant, Solar PV Plant, Hydrogen Generation Plant, 
                                            Flue Gas Desulphurization (FGD) Systems and Equipment. He has 
                                            worked in EPC Projects as well as in Operation & Maintenance (O & M) 
                                            of Power Generation Plants. He was associated with prestigious national 
                                            & international organizations like Reliance, Adani, Bajaj, TATA, Orion Group etc. 
                                            He is currently developing sustainable techno - economic solutions in the field of 
                                            Hydrogen Gas Generation and Storage.
                                            </p>   
                                        </div>           
                                </div>

                    </div>
                </div>
            {/*
                <div className="container mb-3">
                    <div className="row">
                        <div className="col-12 col-lg-8 our-services-list">
                            <h1 className="main-heading">Parikshit Mukherjee</h1>   
                                <div className="row our-services-info">
                                </div>   
                                    <div className="col-12 our-services-data">
                                        <p className="main-hero-para">
                                            Parikshit, graduated from IIT-Kharagpur (B.Tech - Mechanical) and has more than 23 yrs. of 
                                            extensive experience and expertise in strategic planning, project engineering, project management and  
                                            site execution in the 
                                            field of power generation sector (Thermal, Solar PV, Concentrated Solar Plant,
                                            Wind energy etc.). He has end to end execution experience starting from project feasibility stage 
                                            to project commissioning and handing over of the plant. 
                                            He has keen interest in various clean energy technologies and is passionate about developing tech-based solutions  
                                            to make a carbon free society.
                                        </p>   
                                    </div>           
                                </div>
                    
                        <div className="col-12 col-lg-4 our-service-rightside-img">
                            <img src="./Photos/Founder-2.jpeg" alt="Founder-2" />
                    </div>
                </div>
                                           

                </div>
                 */}
                 
            </section>
            </header>
        </>
    )
}

export default Ourexperts;
