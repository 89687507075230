import React from 'react';
import Navbarmodule from "../navbarmodule";
import Disclaimer from "../Disclaimer";
import Footerlogin from "../Footerlogin";


const Disclaimerlogin = () => {
    return (
        <>
            <Navbarmodule />
            <Disclaimer />   
            <Footerlogin />         
        </>
    );
};

export default Disclaimerlogin;