import React from 'react';
import Navbar from "../navbar";
import Privacypolicy from "../Privacypolicy";
import Footer from "../Footer";


const Privacypolicypage = () => {
    return (
        <>
            <Navbar />
            <Privacypolicy />   
            <Footer />         
        </>
    );
};

export default Privacypolicypage;