import React from 'react';

const ScheduleADemo = () => {
    return (
        <>
            <header> 
                <section className="container main-hero-container">
                    <div className="row">
                        <div classname="col-12 col-lg-6 header-left-side d-flex justify-content 
                            align-items-start">
                           
                            <p className="main-hero-para"> 
                                <span className="span-tag">We are looking for Interns (paid), preferably engineering students (2nd year / 3rd year) 
                                having good knowledge in web development technologies specially in reactjs, python-flask, mongodb, nodejs etc. 
                                Please send your resume at suengtechco@gmail.com for our review and process.</span> 
                            </p>
                          
                        </div>
                    </div>   
                </section>             
            </header>   
        </>
    );
};

export default ScheduleADemo;
