import React, { useState } from 'react';
import axios from "axios";
import { useHistory } from 'react-router-dom';

const Login = () => {  
    const history = useHistory()
    const [ user, setUser ] = useState({        
        email:"",
        password:""        
    })

    const handleChange = e => {
        const { name, value } = e.target 
        setUser({
            ...user, 
            [name]: value
        })
    }

const login = () => {
    axios.post("https://suengtech-loginbackend-suengtech.vercel.app/login", user)
    .then(res => {
        if(res.data.user){
            console.log(res.data.message) 
            history.push("/technologieslogin")
        }
        else {
            alert("Please Register or Insert Correct Login Details")
            
        }
                    
    })      
}

return (
        <section className="loginregister container main-hero-container">
            <div className="row">
                <div classname="col-12 col-lg-6 header-left-side d-flex justify-content align-items-center ">
                    <div className="login">
                        {console.log(user)}
                        <h1>Login</h1>
                        <input type="text" name="email" value={user.email} onChange={handleChange} placeholder="Enter Email"></input>
                        <input type="password" name="password" value={user.password} onChange={handleChange} placeholder="Enter Password"></input>
                        <div className="button" onClick={login}>Login</div>
                        <div>or</div>
                        <div className="button" onClick={()=> history.push("/register")}>Register</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
