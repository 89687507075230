import React from 'react';
import Navbar from "../navbar";
import Ourexperts from "../Ourexperts";
import Footer from "../Footer";

const Experts = () => {
    return (
        <>
            <Navbar />      
            <Ourexperts />      
            <Footer />
        </>
    );
};

export default Experts;
