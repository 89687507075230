import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'

import Energyoutput from './Energyoutput.js';

import Axios from 'axios';

function PostForm() {
    const url ="/api/energyModel"
    
    const [outputData, setoutputData] = useState(null)
       
    
    const history = useHistory()

    const [data, setData] = useState({
        plantName:"",
        kWp_dc: "",
        latitude: "",
        longitude: "",
        tiltAngle:"",
        dcacRatioMin: "",
        dcacRatioMax: "",
        model_Module: "",
        Pmpp:"",
        V_oc: "",
        I_sc: "",
        V_mpp: "",
        I_mpp:"",
        tempCoefficient: "",
        dimension_Module_l: "",
        dimension_Module_w: "",
        no_row:"",
        moduleEfficiency: "",
        noct:"",
        model_Inverter: "",
        inverterRating: "",
        inverterEfficiency: "",
        V_dc_Max: "",
        V_mpp_Max: "",
        V_mpp_Min: "",
        I_Max: "",
        //I_dc_Max: "",
        //no_MPPT:"",
        
        L2_shading: "",
        //AOI: "",
        L4_soiling: "",
        L7_mq: "",
        L8_lid: "",
        L9_mam:"",
        L10_dc:"",
       

        y:"",
        derate_1:"",
        derate_2:"",
        //G:""

    })
          
    async function submit(e) {
        e.preventDefault();
        Axios.post(url, {
           
            plantName: data.plantName,
            kWp_dc: parseFloat(data.kWp_dc),
            latitude: parseFloat(data.latitude),
            longitude: parseFloat(data.longitude),
            tiltAngle:parseFloat(data.tiltAngle),
            dcacRatioMin: parseFloat(data.dcacRatioMin),
            dcacRatioMax: parseFloat(data.dcacRatioMax),
            model_Module: data.model_Module,
            Pmpp:parseFloat(data.Pmpp),
            V_oc: parseFloat(data.V_oc),
            I_sc: parseFloat(data.I_sc),
            V_mpp: parseFloat(data.V_mpp),
            I_mpp:parseFloat(data.I_mpp),
            tempCoefficient: parseFloat(data.tempCoefficient),
            dimension_Module_l: parseFloat(data.dimension_Module_l),
            dimension_Module_w: parseFloat(data.dimension_Module_w),
            no_row: parseFloat(data.no_row),
            moduleEfficiency: parseFloat(data.moduleEfficiency),
            noct:parseFloat(data.noct),
            model_Inverter: data.model_Inverter,
            inverterRating: parseFloat(data.inverterRating),
            inverterEfficiency: parseFloat(data.inverterEfficiency),
            V_dc_Max: parseFloat(data.V_dc_Max),
            V_mpp_Max: parseFloat(data.V_mpp_Max),
            V_mpp_Min: parseFloat(data.V_mpp_Min),
            I_Max: parseFloat(data.I_Max),
            
            //I_dc_Max: parseFloat(data.I_dc_Max),
            //no_MPPT:parseFloat(data.no_MPPT),

            L2_shading:parseFloat(data.L2_shading),
           // AOI:parseFloat(data.AOI),
            L4_soiling:parseFloat(data.L4_soiling),
            L7_mq: parseFloat(data.L7_mq),
            L8_lid:parseFloat(data.L8_lid),
            L9_mam:parseFloat(data.L9_mam),
            L10_dc:parseFloat(data.L10_dc),




            y:parseFloat(data.y),
            derate_1:parseFloat(data.derate_1),
            derate_2:parseFloat(data.derate_2),



            
           // G:parseFloat(data.G)
        }).then (res => {setoutputData(res.data);
            console.log(outputData)
                       
        })
      
    }

    if (outputData) { 
        console.log(outputData);
        console.log(outputData.Output[0])
        console.log(outputData.Output[1])
        console.log(outputData.Output[2])
       
    }
        

    function handle(e) {
        const newdata = {...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
        console.log(newdata)
    }
if (outputData) 
    {
        if (outputData.Output[1]) {    
        console.log(outputData.Output[0])
        return ( 
        <>
            <div>
            
            <form onSubmit={(e) =>submit(e)}>
                                 
            <section className="contactus-section">
              <div className="loginregister container">
                  <div className="row">
                      <div className="col-12 col-lg-12 ">
                        <div className="row">
                            <div>
                                <h2 className="main-heading fw-bold text-center">
                                    INPUT DATA TO BE FILLED BY THE USER FOR PREDICTIVE ENERGY MODELLING 
                                </h2>
                                <p className="main-hero-para text-dark text-center"> <h6><u><strong>Plant General Information to be filled by the User </strong></u></h6></p>
                            </div>
                            
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="plantName" value = {data.plantName} placeholder="Name of the Plant" type="text" className="form-control"></textarea>  
                                </div>                                    
                              
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="kWp_dc" value = {data.kWp_dc} placeholder="DC rating of the plant kWp (in kW dc)" type="number" className="form-control"></textarea> 
                                </div>
                            </div>        

                            <div className="row">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3722357.427729623!2d51.71102028379017!3d24.34249772838431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1666239444569!5m2!1sen!2sin" 
                                    width="100%" 
                                    height="250" 
                                    style={{border:0}} 
                                    allowFullScreen="" 
                                    loading="lazy" 
                                    referrerpolicy="no-referrer-when-downgrade">
                                </iframe>                           
                            </div>
                            <div className="row">
                                <br/>
                            </div>

                            <div className="row">
                                <p style={{color: "red"}}> <strong>Go to the location and Right Click to see the Latitudes and Longitudes of that place. Otherwise insert Latitude and Longitude of the place.</strong></p> 
                            </div>          






                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="latitude" value = {data.latitude} placeholder= "Latitude of the site in degrees N or S (+90 to -90, '+' for northern hemisphere), e.g. 30 for 30 deg. N & -30 for 30 deg. S, use maps to get the co-ordinate of the site" type="number" className="form-control"></textarea>
                                </div>
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="longitude" value = {data.longitude} placeholder="Longitude of the site in degrees E or W (+180 to -180, '+' for east of meridian), e.g. 30 for 30 deg. E & -30 for 30 deg. W, use maps to get the co-ordinate of the site" type="number" className="form-control"></textarea>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="tiltAngle" value = {data.tiltAngle} placeholder="Tilt angle of the modules in degrees (Suggestion : Use same value as that of latitude up to 30 N or S & use 30 degrees for latitude greater than 30 N or S" type="number" className="form-control"></textarea>
                                </div>        
                                <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="y" value = {data.y} placeholder="Useful life of the plant in terms of years (Recommended value is 25 years), enter 25 for 25 years life of the plant" type="number" className="form-control"></textarea>
                                </div>    
                            </div>
                                
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="dcacRatioMin" value = {data.dcacRatioMin} placeholder="Minimum DC:AC ratio of the plant (Recommended value is between 1.01 to 1.5)" type="number" className="form-control"></textarea>
                                </div>        
                            
                                <div className="col-12 col-lg-6 contact-input-field flex-nowrap">
                                    <textarea onChange={(e) =>handle(e)} id="dcacRatioMax" value = {data.dcacRatioMax} placeholder="Maximum DC:AC ratio of the plant (Recommended value is between 1.01 to 1.5)" type="number" className="form-control"></textarea>
                                </div>       
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-12 contact-input-field flex-nowrap">
                                    <p style={{color: "red"}}> Notes:(1) If DC capacity of the plant (kWp) is not known, the AC capacity in kVA based on requirement or area availability can be multiplied by 1.2 - 1.3 to get approximate DC capacity of the plant (2) Approximate area requirement will be 10 - 12 sq.m. per kWp.
                                    </p>
                                </div>
                            </div>           
                            <p className="main-hero-para fw-bold text-dark text-center"> <h6><u>Please fill the following Input Data for the <b>Modules </b>from the Module manufacturer's datasheet </u></h6></p>
                                
                            <div className="row">      
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="model_Module" value = {data.model_Module} placeholder="Model name of the module (use manufacturer's data sheet)" type="text" className="form-control"></textarea>
                                </div>     
                            
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="Pmpp" value = {data.Pmpp} placeholder="Maximum power rating of the module at STC in watts (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                </div>
                            </div> 
                                    
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="V_oc" value = {data.V_oc} placeholder="Open circuit voltage of the module value in volts (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                </div>        
                            
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="I_sc" value = {data.I_sc} placeholder="Short circuit current of the module in amps (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                </div>        
                            </div> 
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="V_mpp" value = {data.V_mpp} placeholder="MPP voltage value of the module in volts (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                </div>         
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="I_mpp" value = {data.I_mpp} placeholder="MPP current value of the module in amps (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                    </div>    
                                </div> 
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="tempCoefficient" value = {data.tempCoefficient} placeholder="Temperature coefficient of the module for power output in % per degree centigrade (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                    </div>    
                               
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="dimension_Module_l" value = {data.dimension_Module_l} placeholder="Length of the Module in mm (e.g. 1100), use manufacturer's data sheet" type="number" className="form-control"></textarea>
                                    </div>      
                                </div> 
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="dimension_Module_w" value = {data.dimension_Module_w} placeholder="Width of the Module in mm (e.g. 995), use manufacturer's data sheet" type="number" className="form-control"></textarea>
                                    </div>    
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="no_row" value = {data.no_row} placeholder="Please enter the no. of rows of modules to be stacked vertically lengthwise on one column or table of the structure (MMS)" type="number" className="form-control"></textarea>
                                    </div>                                         
                                </div> 
                                <div className="row">
                                    <p style={{color: "red"}}> <strong>Please enter larger dimension of module as length if the modules are stacked (vertically) larger dimensionwise. Otherwise, enter 
                                        smaller dimension as length if the modules are stacked with smaller dimension.</strong></p> 
                                </div> 
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="derate_1" value = {data.derate_1 } placeholder="1st year degradation of module in % (e.g. enter 3 for 3 % ), use manufacturer's data sheet" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="derate_2" value = {data.derate_2 } placeholder="Yearly degradation of module, 2nd year onwards in % (e.g. enter 0.5 for 0.5 % ), use manufacturer's data sheet" type="number" className="form-control"></textarea>
                                    </div>    
                                </div> 
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="noct" value = {data.noct} placeholder="NOCT temperature of the PV module in degree centigrade (e.g. enter 45 for 45 degree centigrade), use manufacturer's data sheet" type="number" className="form-control"></textarea>
                                    </div>  
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="moduleEfficiency" value = {data.moduleEfficiency} placeholder="Module efficiency (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                    </div>  
                                                                    
                                </div>   
                                   
                                <p className="main-hero-para text-dark text-center fw-bold"> <h6><u>Please fill the following
                                            Input Data for the <b>Inverters </b>from the Inverter manufacturer's datasheet </u></h6></p>
                                   
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="model_Inverter" value = {data.model_Inverter} placeholder="Inverter model (use manufacturer's data sheet)" type="text" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="inverterRating" value = {data.inverterRating} placeholder="AC rating of the inverter in kVA (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>    
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="inverterEfficiency" value = {data.inverterEfficiency} placeholder="Inverter efficiency in % (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                    </div>    
                                                       
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="V_dc_Max" value = {data.V_dc_Max} placeholder="Maximum dc input voltage value of the inverter in volts (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                    </div>    
                                </div> 
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="V_mpp_Max" value = {data.V_mpp_Max} placeholder="Maximum MPP voltage value of the inverter in volts (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="V_mpp_Min" value = {data.V_mpp_Min} placeholder="Minimum MPP voltage value of the inverter in volts (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>     
                                
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="I_Max" value = {data.I_Max} placeholder="Maximum dc input current of the inverter in amps (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                {/* <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="I_dc_Max" value = {data.I_dc_Max} placeholder="Maximum dc short circuit current of the inverter in Amps" type="number" className="form-control"></textarea>
                                    </div>   */}                       
                                    </div>      
                                <p className="main-hero-para text-dark text-center fw-bold"> <h6><u>Please fill the following
                                            Input Data for the <b>Losses </b>based on experience and local environmental data </u></h6></p>
                                <div className="row">
                                    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="L2_shading" value = {data.L2_shading} placeholder="Shading loss in %, typical value is 0.5% (e.g. enter 0.3 for 0.3 %)" type="number" className="form-control"></textarea>
                                    </div>    
                                     <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="L4_soiling" value = {data.L4_soiling} placeholder="Soiling loss in % (e.g. enter 1 for 1%), usually this loss is less than 4 %" type="number" className="form-control"></textarea>
                                    </div> 
                                </div>  
                                <div className="row">
                                   
                                </div>  
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="L7_mq" value = {data.L7_mq} placeholder="Module quality loss in % (e.g. enter 1 for 1 %), usually specified by manufacturer, use negative value for gain" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="L8_lid" value = {data.L8_lid} placeholder="LID (Light induced degradation) loss in % (e.g. enter 1 for 1 %), value is usually less than 1.5 %, check manufacturer's data sheet" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>  
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="L9_mam" value = {data.L9_mam} placeholder="Module array mismatch loss in % (e.g. enter 0.5 for 0.5 %), typical value is 0.5 %" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="L10_dc" value = {data.L10_dc} placeholder="DC cabling loss in % (e.g. enter 1 for 1 %), Should be less than 2 %" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>       
                                
                                <div className="row">
                                    <div class="text-center">
                                        <button className="btn btn-style location-middle fw-bold w-50">See Results below / Re-calculate</button>
                                    
                                        <button className="btn btn-style location-middle w-40 fw-bold" onClick={()=> history.push("/homelogin")}>Home</button>
                                    </div>
                                
                                    <div class="text-center">
                                        <br />
                                    </div>
                                    <div class="text-center">
                                        <h6 style={{color: "orange"}}><strong><u>RESULTS FROM SUENG-TECH MODELLING PLATFORM</u></strong></h6>
                                    </div>
                                    <div class="text-center">
                                        <br />
                                    </div>
                                </div>
                                

                                <div>
                                    <Energyoutput {...outputData} />
                                </div> 
                                
                            </div>
                        </div>
                      </div>
                    </div>
                
            </section>
              
            </form>
                     
        </div>
        </>  
        )
    }

    else if (!outputData.Output[1]) {
            alert(outputData.Output[0])
            return (
                <>

                    <div>

                        <form onSubmit={(e) => submit(e)}>

                            <section className="contactus-section">
                                <div className="loginregister container">
                                    <div className="row">
                                        <div className="col-12 col-lg-12 ">
                                            <div className="row">
                                                <div>
                                                    <h2 className="main-heading fw-bold text-center">
                                                        INPUT DATA TO BE FILLED BY THE USER FOR PREDICTIVE ENERGY MODELLING
                                                    </h2>
                                                    <p className="main-hero-para text-dark text-center"> <h6><u><strong>Plant General Information to be filled by the User</strong> </u></h6></p>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="plantName" value={data.plantName} placeholder="Name of the Plant" type="text" className="form-control"></textarea>
                                                    </div>

                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="kWp_dc" value={data.kWp_dc} placeholder="DC rating of the plant kWp (in kW dc)" type="number" className="form-control"></textarea>
                                                    </div>
                                                </div>
                                             <div className="row">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3722357.427729623!2d51.71102028379017!3d24.34249772838431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1666239444569!5m2!1sen!2sin" 
                                    width="100%" 
                                    height="250" 
                                    style={{border:0}} 
                                    allowFullScreen="" 
                                    loading="lazy" 
                                    referrerpolicy="no-referrer-when-downgrade">
                                </iframe>                           
                            </div>
                            <div className="row">
                                <br/>
                            </div>

                            <div className="row">
                                <p style={{color: "red"}}> <strong>Go to the location and Right Click to see the Latitudes and Longitudes of that place. Otherwise insert Latitude and Longitude of the place.</strong></p> 
                            </div>    

                                                <div className="row">
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="latitude" value={data.latitude} placeholder="Latitude of the site in degrees N or S (+90 to -90, '+' for northern hemisphere), e.g. 30 for 30 deg. N & -30 for 30 deg. S, use maps to get the co-ordinate of the site" type="number" className="form-control"></textarea>
                                                    </div>
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="longitude" value={data.longitude} placeholder="Longitude of the site in degrees E or W (+180 to -180, '+' for east of meridian), e.g. 30 for 30 deg. E & -30 for 30 deg. W, use maps to get the co-ordinate of the site" type="number" className="form-control"></textarea>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="tiltAngle" value={data.tiltAngle} placeholder="Tilt angle of the modules in degrees (Suggestion : Use same value as that of latitude up to 30 N or S & use 30 degrees for latitude greater than 30 N or S" type="number" className="form-control"></textarea>
                                                    </div>
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="y" value={data.y} placeholder="Useful life of the plant in terms of years (Recommended value is 25 years), enter 25 for 25 years life of the plant" type="number" className="form-control"></textarea>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="dcacRatioMin" value={data.dcacRatioMin} placeholder="Minimum DC:AC ratio of the plant (Recommended value is between 1.01 to 1.5)" type="number" className="form-control"></textarea>
                                                    </div>

                                                    <div className="col-12 col-lg-6 contact-input-field flex-nowrap">
                                                        <textarea onChange={(e) => handle(e)} id="dcacRatioMax" value={data.dcacRatioMax} placeholder="Maximum DC:AC ratio of the plant (Recommended value is between 1.01 to 1.5)" type="number" className="form-control"></textarea>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-lg-12 contact-input-field flex-nowrap">
                                                        <p style={{ color: "red" }}> Notes:(1) If DC capacity of the plant (kWp) is not known, the AC capacity in kVA based on requirement or area availability can be multiplied by 1.2 - 1.3 to get approximate DC capacity of the plant (2) Approximate area requirement will be 10 - 12 sq.m. per kWp.
                                                        </p>
                                                    </div>
                                                </div>
                                                <p className="main-hero-para fw-bold text-dark text-center"> <h6><u>Please fill the following Input Data for the <b>Modules </b>from the Module manufacturer's datasheet </u></h6></p>

                                                <div className="row">
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="model_Module" value={data.model_Module} placeholder="Model name of the module (use manufacturer's data sheet)" type="text" className="form-control"></textarea>
                                                    </div>

                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="Pmpp" value={data.Pmpp} placeholder="Maximum power rating of the module at STC in watts (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="V_oc" value={data.V_oc} placeholder="Open circuit voltage of the module value in volts (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                                    </div>

                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="I_sc" value={data.I_sc} placeholder="Short circuit current of the module in amps (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="V_mpp" value={data.V_mpp} placeholder="MPP voltage value of the module in volts (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                                    </div>

                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="I_mpp" value={data.I_mpp} placeholder="MPP current value of the module in amps (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="tempCoefficient" value={data.tempCoefficient} placeholder="Temperature coefficient of the module for power output in % per degree centigrade (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                                    </div>

                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="dimension_Module_l" value={data.dimension_Module_l} placeholder="Length of the Module in mm (e.g. 1100), use manufacturer's data sheet" type="number" className="form-control"></textarea>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="dimension_Module_w" value={data.dimension_Module_w} placeholder="Width of the Module in mm (e.g. 995), use manufacturer's data sheet" type="number" className="form-control"></textarea>
                                                    </div>
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) =>handle(e)} id="no_row" value = {data.no_row} placeholder="Please enter the no. of rows of modules to be stacked vertically lengthwise on one column or table of the structure (MMS)" type="number" className="form-control"></textarea>
                                                    </div>                                                     
                                                </div>

                                                <div className="row">
                                                    <p style={{color: "red"}}> <strong>Please enter larger dimension of module as length if the modules are stacked (vertically) larger dimensionwise. Otherwise, enter 
                                                            smaller dimension as length if the modules are stacked with smaller dimension.</strong></p> 
                                                </div> 
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="derate_1" value={data.derate_1} placeholder="1st year degradation of module in % (e.g. enter 3 for 3 % ), use manufacturer's data sheet" type="number" className="form-control"></textarea>
                                                    </div>

                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="derate_2" value={data.derate_2} placeholder="Yearly degradation of module, 2nd year onwards in % (e.g. enter 0.5 for 0.5 % ), use manufacturer's data sheet" type="number" className="form-control"></textarea>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="noct" value={data.noct} placeholder="NOCT temperature of the PV module in degree centigrade (e.g. enter 45 for 45 degree centigrade), use manufacturer's data sheet" type="number" className="form-control"></textarea>
                                                    </div>
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="moduleEfficiency" value={data.moduleEfficiency} placeholder="Module efficiency (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                                    </div>

                                                </div>

                                                <p className="main-hero-para text-dark text-center fw-bold"> <h6><u>Please fill the following
                                                    Input Data for the <b>Inverters </b>from the Inverter manufacturer's datasheet </u></h6></p>

                                                <div className="row">
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="model_Inverter" value={data.model_Inverter} placeholder="Inverter model (use manufacturer's data sheet)" type="text" className="form-control"></textarea>
                                                    </div>

                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="inverterRating" value={data.inverterRating} placeholder="AC rating of the inverter in kVA (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="inverterEfficiency" value={data.inverterEfficiency} placeholder="Inverter efficiency in % (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                                    </div>

                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="V_dc_Max" value={data.V_dc_Max} placeholder="Maximum dc input voltage value of the inverter in volts (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="V_mpp_Max" value={data.V_mpp_Max} placeholder="Maximum MPP voltage value of the inverter in volts (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                                    </div>

                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="V_mpp_Min" value={data.V_mpp_Min} placeholder="Minimum MPP voltage value of the inverter in volts (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="I_Max" value={data.I_Max} placeholder="Maximum dc input current of the inverter in amps (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                                    </div>

                                                    {/* <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="I_dc_Max" value = {data.I_dc_Max} placeholder="Maximum dc short circuit current of the inverter in Amps" type="number" className="form-control"></textarea>
                                    </div>   */}
                                                </div>
                                                <p className="main-hero-para text-dark text-center fw-bold"> <h6><u>Please fill the following
                                                    Input Data for the <b>Losses </b>based on experience and local environmental data </u></h6></p>
                                                <div className="row">


                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="L2_shading" value={data.L2_shading} placeholder="Shading loss in %, typical value is 0.5% (e.g. enter 0.3 for 0.3 %)" type="number" className="form-control"></textarea>
                                                    </div>
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="L4_soiling" value={data.L4_soiling} placeholder="Soiling loss in % (e.g. enter 1 for 1%), usually this loss is less than 4 %" type="number" className="form-control"></textarea>
                                                    </div>
                                                </div>
                                                <div className="row">

                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="L7_mq" value={data.L7_mq} placeholder="Module quality loss in % (e.g. enter 1 for 1 %), usually specified by manufacturer, use negative value for gain" type="number" className="form-control"></textarea>
                                                    </div>

                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="L8_lid" value={data.L8_lid} placeholder="LID (Light induced degradation) loss in % (e.g. enter 1 for 1 %), value is usually less than 1.5 %, check manufacturer's data sheet" type="number" className="form-control"></textarea>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="L9_mam" value={data.L9_mam} placeholder="Module array mismatch loss in % (e.g. enter 0.5 for 0.5 %), typical value is 0.5 %" type="number" className="form-control"></textarea>
                                                    </div>

                                                    <div className="col-12 col-lg-6 contact-input-field">
                                                        <textarea onChange={(e) => handle(e)} id="L10_dc" value={data.L10_dc} placeholder="DC cabling loss in % (e.g. enter 1 for 1 %), Should be less than 2 %" type="number" className="form-control"></textarea>
                                                    </div>
                                                </div>


                                                <div class="text-center">
                                                    <button className="btn btn-style location-middle fw-bold w-50">Submit</button>
                                                </div>
                                                <div class="text-center">
                                                    <br />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </section>

                        </form>

                    </div>

                </>
            );
        }

    }

else 
    {        
    return (
        <>
        <div>
            
            <form onSubmit={(e) =>submit(e)}>
                                 
            <section className="contactus-section">
              <div className="loginregister container">
                  <div className="row">
                      <div className="col-12 col-lg-12 ">
                        <div className="row">
                            <div>
                                <h2 className="main-heading fw-bold text-center">
                                    INPUT DATA TO BE FILLED BY THE USER FOR PREDICTIVE ENERGY MODELLING 
                                </h2>
                                <p className="main-hero-para text-dark text-center"> <h6><u><strong>Plant General Information to be filled by the User </strong></u></h6></p>
                            </div>
                            
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="plantName" value = {data.plantName} placeholder="Name of the Plant" type="text" className="form-control"></textarea>  
                                </div>                                    
                              
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="kWp_dc" value = {data.kWp_dc} placeholder="DC rating of the plant kWp (in kW dc)" type="number" className="form-control"></textarea> 
                                </div>
                            </div>                                    
                                                        
                            <div className="row">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3722357.427729623!2d51.71102028379017!3d24.34249772838431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1666239444569!5m2!1sen!2sin" 
                                    width="100%" 
                                    height="250" 
                                    style={{border:0}} 
                                    allowFullScreen="" 
                                    loading="lazy" 
                                    referrerpolicy="no-referrer-when-downgrade">
                                </iframe>                           
                            </div>
                            <div className="row">
                                <br/>
                            </div>

                            <div className="row">
                                <p style={{color: "red"}}> <strong>Go to the location and Right Click to see the Latitudes and Longitudes of that place. Otherwise insert Latitude and Longitude of the place.</strong></p> 
                            </div>
                            
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="latitude" value = {data.latitude} placeholder= "Latitude of the site in degrees N or S (+90 to -90, '+' for northern hemisphere), e.g. 30 for 30 deg. N & -30 for 30 deg. S, use maps to get the co-ordinate of the site" type="number" className="form-control"></textarea>
                                </div>
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="longitude" value = {data.longitude} placeholder="Longitude of the site in degrees E or W (+180 to -180, '+' for east of meridian), e.g. 30 for 30 deg. E & -30 for 30 deg. W, use maps to get the co-ordinate of the site" type="number" className="form-control"></textarea>
                                </div>
                            </div>
                            
                            
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="tiltAngle" value = {data.tiltAngle} placeholder="Tilt angle of the modules in degrees (Suggestion : Use same value as that of latitude up to 30 N or S & use 30 degrees for latitude greater than 30 N or S" type="number" className="form-control"></textarea>
                                </div>        
                                <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="y" value = {data.y} placeholder="Useful life of the plant in terms of years (Recommended value is 25 years), enter 25 for 25 years life of the plant" type="number" className="form-control"></textarea>
                                </div>    
                            </div>
                                
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="dcacRatioMin" value = {data.dcacRatioMin} placeholder="Minimum DC:AC ratio of the plant (Recommended value is between 1.01 to 1.5)" type="number" className="form-control"></textarea>
                                </div>        
                            
                                <div className="col-12 col-lg-6 contact-input-field flex-nowrap">
                                    <textarea onChange={(e) =>handle(e)} id="dcacRatioMax" value = {data.dcacRatioMax} placeholder="Maximum DC:AC ratio of the plant (Recommended value is between 1.01 to 1.5)" type="number" className="form-control"></textarea>
                                </div>       
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-12 contact-input-field flex-nowrap">
                                    <p style={{color: "red"}}> Notes:(1) If DC capacity of the plant (kWp) is not known, the AC capacity in kVA based on requirement or area availability can be multiplied by 1.2 - 1.3 to get approximate DC capacity of the plant (2) Approximate area requirement will be 10 - 12 sq.m. per kWp.
                                    </p>
                                </div>
                            </div>           
                            <p className="main-hero-para fw-bold text-dark text-center"> <h6><u>Please fill the following Input Data for the <b>Modules </b>from the Module manufacturer's datasheet </u></h6></p>
                                
                            <div className="row">      
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="model_Module" value = {data.model_Module} placeholder="Model name of the module (use manufacturer's data sheet)" type="text" className="form-control"></textarea>
                                </div>     
                            
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="Pmpp" value = {data.Pmpp} placeholder="Maximum power rating of the module at STC in watts (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                </div>
                            </div> 
                                    
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="V_oc" value = {data.V_oc} placeholder="Open circuit voltage of the module value in volts (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                </div>        
                            
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="I_sc" value = {data.I_sc} placeholder="Short circuit current of the module in amps (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                </div>        
                            </div> 
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="V_mpp" value = {data.V_mpp} placeholder="MPP voltage value of the module in volts (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                </div>         
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="I_mpp" value = {data.I_mpp} placeholder="MPP current value of the module in amps (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                    </div>    
                                </div> 
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="tempCoefficient" value = {data.tempCoefficient} placeholder="Temperature coefficient of the module for power output in % per degree centigrade (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                    </div>    
                               
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="dimension_Module_l" value = {data.dimension_Module_l} placeholder="Length of the Module in mm (e.g. 1100), use manufacturer's data sheet" type="number" className="form-control"></textarea>
                                    </div>      
                                </div> 
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="dimension_Module_w" value = {data.dimension_Module_w} placeholder="Width of the Module in mm (e.g. 995), use manufacturer's data sheet" type="number" className="form-control"></textarea>
                                    </div>    
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="no_row" value = {data.no_row} placeholder="Please enter the no. of rows of modules to be stacked vertically lengthwise on one column or table of the structure (MMS)" type="number" className="form-control"></textarea>
                                    </div>                                        
                                </div> 

                                <div className="row">
                                    <p style={{color: "red"}}> <strong>Please enter larger dimension of module as length if the modules are stacked (vertically) larger dimensionwise. Otherwise, enter 
                                        smaller dimension as length if the modules are stacked with smaller dimension.</strong></p> 
                                </div> 

                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="derate_1" value = {data.derate_1 } placeholder="1st year degradation of module in % (e.g. enter 3 for 3 % ), use manufacturer's data sheet" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="derate_2" value = {data.derate_2 } placeholder="Yearly degradation of module, 2nd year onwards in % (e.g. enter 0.5 for 0.5 % ), use manufacturer's data sheet" type="number" className="form-control"></textarea>
                                    </div>    
                                </div> 
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="noct" value = {data.noct} placeholder="NOCT temperature of the PV module in degree centigrade (e.g. enter 45 for 45 degree centigrade), use manufacturer's data sheet" type="number" className="form-control"></textarea>
                                    </div>  
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="moduleEfficiency" value = {data.moduleEfficiency} placeholder="Module efficiency (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                    </div>   
                                                                    
                                </div>   
                                   
                                <p className="main-hero-para text-dark text-center fw-bold"> <h6><u>Please fill the following
                                            Input Data for the <b>Inverters </b>from the Inverter manufacturer's datasheet </u></h6></p>
                                   
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="model_Inverter" value = {data.model_Inverter} placeholder="Inverter model (use manufacturer's data sheet)" type="text" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="inverterRating" value = {data.inverterRating} placeholder="AC rating of the inverter in kVA (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>    
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="inverterEfficiency" value = {data.inverterEfficiency} placeholder="Inverter efficiency in % (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                    </div>    
                                                       
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="V_dc_Max" value = {data.V_dc_Max} placeholder="Maximum dc input voltage value of the inverter in volts (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                    </div>    
                                </div> 
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="V_mpp_Max" value = {data.V_mpp_Max} placeholder="Maximum MPP voltage value of the inverter in volts (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="V_mpp_Min" value = {data.V_mpp_Min} placeholder="Minimum MPP voltage value of the inverter in volts (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>     
                                
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="I_Max" value = {data.I_Max} placeholder="Maximum dc input current of the inverter in amps (use manufacturer's data sheet)" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                {/* <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="I_dc_Max" value = {data.I_dc_Max} placeholder="Maximum dc short circuit current of the inverter in Amps" type="number" className="form-control"></textarea>
                                    </div>   */}                       
                                    </div>      
                                <p className="main-hero-para text-dark text-center fw-bold"> <h6><u>Please fill the following
                                            Input Data for the <b>Losses </b>based on experience and local environmental data </u></h6></p>
                                <div className="row">
                                    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="L2_shading" value = {data.L2_shading} placeholder="Shading loss in %, typical value is 0.5% (e.g. enter 0.3 for 0.3 %)" type="number" className="form-control"></textarea>
                                    </div>    
                                     <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="L4_soiling" value = {data.L4_soiling} placeholder="Soiling loss in % (e.g. enter 1 for 1%), usually this loss is less than 4 %" type="number" className="form-control"></textarea>
                                    </div> 
                                </div>  
                                <div className="row">
                                   
                                </div>  
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="L7_mq" value = {data.L7_mq} placeholder="Module quality loss in % (e.g. enter 1 for 1 %), usually specified by manufacturer, use negative value for gain" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="L8_lid" value = {data.L8_lid} placeholder="LID (Light induced degradation) loss in % (e.g. enter 1 for 1 %), value is usually less than 1.5 %, check manufacturer's data sheet" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>  
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="L9_mam" value = {data.L9_mam} placeholder="Module array mismatch loss in % (e.g. enter 0.5 for 0.5 %), typical value is 0.5 %" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="L10_dc" value = {data.L10_dc} placeholder="DC cabling loss in % (e.g. enter 1 for 1 %), Should be less than 2 %" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>       
                                
                                
                                <div class="text-center">
                                    <button className="btn btn-style location-middle fw-bold w-50">Submit</button>
                                </div>
                                <div class="text-center">
                                    <br />
                                </div>
                                
                            </div>
                        </div>
                      </div>
                    </div>
                
            </section>
              
            </form>
                     
        </div>
        
        </>
    );
} 
} 
                                
export default PostForm;






