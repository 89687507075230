import React from 'react';
import Navbarmodule from "../navbarmodule";
import Footerlogin from "../Footerlogin";
import Leadership from "../Leadership";

const Founderslogin = () => {
    return (
        <>
        <Navbarmodule />     
        <Leadership />
        <Footerlogin /> 
        </>
    );
};

export default Founderslogin;
