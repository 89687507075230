import React from 'react';
import Navbar from "../navbar";
import Footer from "../Footer";
import TandC from "../TandC";

const TandCpage = () => {
    return (
        <>
        <Navbar />      
        <TandC />
        <Footer /> 
        </>
    );
};

export default TandCpage;
