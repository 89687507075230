import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

import {Bar} from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function Finoutput(outputData) {
    
    const currDate = new Date().toLocaleDateString()
    const currTime = new Date().toLocaleTimeString()

    
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content:() =>componentRef.current,
    });

    const history = useHistory()

    
    
if (outputData) 
    {
        const chartData = {
            labels: ['Jan', 'Feb','Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [
                {
                    label: "Monthwise Power Generation Data in MWh",
                    data: [outputData.Output[133].toFixed( 2), outputData.Output[134].toFixed( 2), 
                    outputData.Output[135].toFixed( 2),outputData.Output[136].toFixed( 2),
                    outputData.Output[137].toFixed( 2),outputData.Output[138].toFixed( 2),
                    outputData.Output[139].toFixed( 2),outputData.Output[140].toFixed( 2),
                    outputData.Output[141].toFixed( 2),outputData.Output[142].toFixed( 2),
                    outputData.Output[143].toFixed( 2), outputData.Output[144].toFixed( 2)],
                    borderColor: "rgb(53, 162, 235)",
                    backgroundColor: "rgba(53, 162, 235, 0.4)",
                },
            ],
        };
        
        const chartOptions = {
            responsive: true,
            plugins: {
                legend: {
                    position: "top"
                },
                title: {
                    display: true,
                    text: "Monthwise Power Generation Data",
                },
            },
        };
    
        const prData = {
            labels: ['Jan', 'Feb','Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [
                {
                    label: "Monthwise Performance Ratio of the PV Plant in %",
                    data: [outputData.Output[146].toFixed( 2), outputData.Output[147].toFixed( 2), 
                    outputData.Output[148].toFixed( 2),outputData.Output[149].toFixed( 2),
                    outputData.Output[150].toFixed( 2),outputData.Output[151].toFixed( 2),
                    outputData.Output[152].toFixed( 2),outputData.Output[153].toFixed( 2),
                    outputData.Output[154].toFixed( 2),outputData.Output[155].toFixed( 2),
                    outputData.Output[156].toFixed( 2), outputData.Output[157].toFixed( 2)],
                    borderColor: "rgb(53, 162, 235)",
                    backgroundColor: "rgba(53, 162, 235, 0.4)",
                },
            ],
        };
        
        const prOptions = {
            responsive: true,
            plugins: {
                legend: {
                    position: "top"
                },
                title: {
                    display: true,
                    text: "Monthwise PR data in %",
                },
            },
        };
            
        console.log(outputData.Output[0])
        return ( <>
                <div className="print__section">
                <div ref={ componentRef } className="card">

                <section className="container main-hero-container">
                    <div className="row">
                        <div classname="col-12 col-lg-6 header-left-side d-flex justify-content 
                            align-items-start">
                           
                            <p className="main-hero-para"> 
                                <span className="span-tag"> Welcome to Sueng-Tech for modelling of a Solar PV based power project</span> <br/> 
                                Model running Date : { currDate } & Time : { currTime } <br/> <br/>
                                
                                    <div className="text-center span-tag"><u>Technical Input data entered by customer</u></div>
                                
                                <table width = "100%">
                                    
                                        <th style={{color: "blueviolet"}}>General Plant Information</th>     
                                    
                                    <tr>
                                        <td>Name of the Plant or Project Name : { outputData.Output[0] } </td>
                                    </tr>
                                    <tr>
                                        <td>Desired DC rating of the Plant in kWp (kW DC): { outputData.Output[1] } </td>
                                    </tr>
                                    <tr >
                                        <td width = "50%">Latitude in degrees N or S, negative value (-) is for S : { outputData.Output[2].toFixed( 2) }</td>
                                        <td width = "50%">Longitude in degrees E or W, negative value (-) is for W : { outputData.Output[3].toFixed( 2) } </td>
                                    </tr>
                                    <tr>
                                        <td width = "50%">Angle of Tilt in degrees : { outputData.Output[4].toFixed( 2) } </td>
                                        <td width = "50%">Useful life of the plant in years: { outputData.Output[161] } </td>
                                    </tr>
                                    <tr>
                                        <td width = "50%">Maximum DC:AC ratio selected : { outputData.Output[6].toFixed( 2) } </td>
                                        <td width = "50%">Minimum DC:AC ratio selected : { outputData.Output[5].toFixed( 2) } </td>
                                    </tr>
                                </table> <br/>
                                <table width = "100%">
                                    <tr>
                                        <th style={{color: "blueviolet"}}> Solar PV Module Input Information </th>
                                    </tr>
                                </table>
                                <table width = "100%">                                
                                    <tr>
                                        <td width = "50%">Model name of the module : { outputData.Output[7] } </td>
                                        <td width = "50%">Maximum power rating at STC in watts : { outputData.Output[8] }</td>
                                        
                                    </tr>
                                    <tr>
                                        <td width = "50%">Open circuit voltage value in volts : { outputData.Output[9] } </td>
                                        <td width = "50%">Short circuit current in amps : { outputData.Output[10] }</td>
                                    </tr>
                                    <tr>
                                        <td width = "50%">MPP voltage value in volts : { outputData.Output[11] }</td>
                                        <td width = "50%">MPP current value in amps : { outputData.Output[12] } </td>
                                    </tr>
                                    <tr>
                                        <td>Temperature coefficient for power output in % per degree centigrade : { outputData.Output[13] } </td>
                                        
                                    </tr>
                                    <tr>
                                        <td width = "50%">Module length in mm : { outputData.Output[14] } </td>
                                        <td width = "50%">Module width in mm : { outputData.Output[15] } </td>
                                        
                                    </tr>
                                    <tr>
                                        <td width = "50%">1st year degradation of power output of module in % : { outputData.Output[162] }</td>
                                        <td width = "50%">Yearly degradation of power output of module, 2nd year onwards in % : { outputData.Output[163] }</td>
                                    </tr>
                                    <tr>
                                        <td width = "50%">Module efficiency in % : { outputData.Output[16] } </td>
                                        <td width = "50%">NOCT temperature of the PV module in degree centigrade : { outputData.Output[17] } </td>
                                    </tr>
                                </table><br/>
                                <table>
                                    <tr>
                                        <th style={{color: "blueviolet"}}> Solar Inverter Input Information </th>
                                    </tr>
                                </table>
                                <table width = "100%">                                
                                     
                                    <tr>
                                        <td width = "50%">Inverter model name : { outputData.Output[18] } </td>
                                        <td width = "50%">AC rating in kVA : { outputData.Output[19] }</td>
                                        
                                    </tr>
                                    <tr>
                                        <td width = "50%">Inverter efficiency in % : { outputData.Output[20] } </td>
                                        <td width = "50%">Maximum dc input voltage value of the inverter in volts : { outputData.Output[21] }</td>
                                    </tr>
                                    <tr>
                                        <td width = "50%">Maximum MPP voltage in volts : { outputData.Output[22] }</td>
                                        <td width = "50%">Minimum MPP voltage in volts : { outputData.Output[23] } </td>
                                    </tr>
                                    <tr>
                                        <td width = "50%">Maximum DC input current in amps : { outputData.Output[24] } </td>
                                       {/* <td width = "50%">Maximum DC short circuit current in amps : { outputData.Output[25] } </td> */}
                                    </tr><br/>
                                    
                                </table>
                                <table>
                                    <tr>
                                        <th style={{color: "blueviolet"}}> Solar PV Plant Loss Input Information </th>
                                    </tr>
                                </table>
                                <table width = "100%">                                
                                     
                                    <tr>
                                        <td width = "50%">Shading loss in % : { outputData.Output[26] } </td>
                                        <td width = "50%">Soiling loss in % : { outputData.Output[27] }</td>
                                        
                                    </tr>
                                    <tr>
                                        <td width = "50%">Module quality loss in % : { outputData.Output[28] } </td>
                                        <td width = "50%">LID (Light induced degradation) loss in % : { outputData.Output[29] }</td>
                                    </tr>
                                    <tr>
                                        <td width = "50%">Module array mismatch loss in % : { outputData.Output[30] }</td>
                                        <td width = "50%">DC cabling loss in % : { outputData.Output[31] } </td>
                                    </tr>
                                                            
                                </table><br/>
                                <div className="span-tag text-center"><u>Technical System design output data calculated & predicted by the Modelling Platform</u></div>
                                <table width="100%">
                                    <tr>
                                        <th style={{color: "blueviolet"}}> System Design Results </th>
                                    </tr>
                                </table>
                                <table width = "100%">                                                 
                                    <tr>
                                        <td width = "100%">Total no. of modules in the PV plant are : { outputData.Output[32] } </td>
                                    </tr>
                                    <tr>
                                        <td width = "100%">Total no. of inverters in the PV plant are : { outputData.Output[33] } </td>
                                    </tr>
                                    <tr>                                        
                                        <td width = "100%">{ outputData.Output[40] } </td>
                                    </tr>
                                    <tr>                                        
                                        <td width = "100%">{ outputData.Output[41] } </td>
                                    </tr>
                                    <tr>                                        
                                        <td width = "100%">{ outputData.Output[42] } </td>
                                    </tr>
                                    <tr>
                                        <td width = "100%">Calculated DC : AC ratio will be : { outputData.Output[43].toFixed( 2) } </td>
                                    </tr>                               
                                </table> 
                                <table width="100%">
                                    <tr>                                        
                                        <td width = "" style={{color: "red"}}>{ outputData.Output[25] } </td> 
                                    </tr><br/>

                                </table>
                                <br/>
                                <table width="100%">
                                    <tr>
                                        <th className="span-tag text-center fw-bold"> Yield Calculation Results & Performance Ratio Calculation </th>
                                    </tr> 
                                </table>
                                <table width = "100%"> 
                                    <tr style={{color: "navy"}}> 
                                        <th>Month</th>
                                        <th>GHI</th>
                                        <th>GInc</th>
                                        <th>DNI</th>
                                        <th>DHI</th>
                                        <th>G</th>
                                        <th>T Amb</th>
                                    {/* <th>AOI</th> */}
                                        <th>E_arr_dc</th>
                                        <th>PR</th>

                                    </tr>                                             
                                    <tr> 
                                        <td> Jan </td>
                                        <td> { outputData.Output[44].toFixed( 2) } </td>
                                        <td> { outputData.Output[57].toFixed( 2) } </td>
                                        <td> { outputData.Output[70].toFixed( 2) } </td>
                                        <td> { outputData.Output[83].toFixed( 2) } </td>
                                        <td> { outputData.Output[96].toFixed( 2) } </td>
                                        <td> { outputData.Output[108].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[120].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[133].toFixed( 2) } </td>
                                        <td> { outputData.Output[146].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> Feb </td>
                                        <td> { outputData.Output[45].toFixed( 2) } </td>
                                        <td> { outputData.Output[58].toFixed( 2) } </td>
                                        <td> { outputData.Output[71].toFixed( 2) } </td>
                                        <td> { outputData.Output[84].toFixed( 2) } </td>
                                        <td> { outputData.Output[97].toFixed( 2) } </td>
                                        <td> { outputData.Output[109].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[121].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[134].toFixed( 2) } </td>
                                        <td> { outputData.Output[147].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> Mar </td>
                                        <td> { outputData.Output[46].toFixed( 2) } </td>
                                        <td> { outputData.Output[59].toFixed( 2) } </td>
                                        <td> { outputData.Output[72].toFixed( 2) } </td>
                                        <td> { outputData.Output[85].toFixed( 2) } </td>
                                        <td> { outputData.Output[98].toFixed( 2) } </td>
                                        <td> { outputData.Output[110].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[122].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[135].toFixed( 2) } </td>
                                        <td> { outputData.Output[148].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> Apr </td>
                                        <td> { outputData.Output[47].toFixed( 2) } </td>
                                        <td> { outputData.Output[60].toFixed( 2) } </td>
                                        <td> { outputData.Output[73].toFixed( 2) } </td>
                                        <td> { outputData.Output[86].toFixed( 2) } </td>
                                        <td> { outputData.Output[99].toFixed( 2) } </td>
                                        <td> { outputData.Output[111].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[123].toFixed( 2) } </td>  */}
                                        <td> { outputData.Output[136].toFixed( 2) } </td>
                                        <td> { outputData.Output[149].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> May </td>
                                        <td> { outputData.Output[48].toFixed( 2) } </td>
                                        <td> { outputData.Output[61].toFixed( 2) } </td>
                                        <td> { outputData.Output[74].toFixed( 2) } </td>
                                        <td> { outputData.Output[87].toFixed( 2) } </td>
                                        <td> { outputData.Output[100].toFixed( 2) } </td>
                                        <td> { outputData.Output[112].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[124].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[137].toFixed( 2) } </td>
                                        <td> { outputData.Output[150].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> June </td>
                                        <td> { outputData.Output[49].toFixed( 2) } </td>
                                        <td> { outputData.Output[62].toFixed( 2) } </td>
                                        <td> { outputData.Output[75].toFixed( 2) } </td>
                                        <td> { outputData.Output[88].toFixed( 2) } </td>
                                        <td> { outputData.Output[101].toFixed( 2) } </td>
                                        <td> { outputData.Output[113].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[125].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[138].toFixed( 2) } </td>
                                        <td> { outputData.Output[151].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> July </td>
                                        <td> { outputData.Output[50].toFixed( 2) } </td>
                                        <td> { outputData.Output[63].toFixed( 2) } </td>
                                        <td> { outputData.Output[76].toFixed( 2) } </td>
                                        <td> { outputData.Output[89].toFixed( 2) } </td>
                                        <td> { outputData.Output[102].toFixed( 2) } </td>
                                        <td> { outputData.Output[114].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[126].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[139].toFixed( 2) } </td>
                                        <td> { outputData.Output[152].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> Aug </td>
                                        <td> { outputData.Output[51].toFixed( 2) } </td>
                                        <td> { outputData.Output[64].toFixed( 2) } </td>
                                        <td> { outputData.Output[77].toFixed( 2) } </td>
                                        <td> { outputData.Output[90].toFixed( 2) } </td>
                                        <td> { outputData.Output[103].toFixed( 2) } </td>
                                        <td> { outputData.Output[115].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[127].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[140].toFixed( 2) } </td>
                                        <td> { outputData.Output[153].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> Sept </td>
                                        <td> { outputData.Output[52].toFixed( 2) } </td>
                                        <td> { outputData.Output[65].toFixed( 2) } </td>
                                        <td> { outputData.Output[78].toFixed( 2) } </td>
                                        <td> { outputData.Output[91].toFixed( 2) } </td>
                                        <td> { outputData.Output[104].toFixed( 2) } </td>
                                        <td> { outputData.Output[116].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[128].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[141].toFixed( 2) } </td>
                                        <td> { outputData.Output[154].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> Oct </td>
                                        <td> { outputData.Output[53].toFixed( 2) } </td>
                                        <td> { outputData.Output[66].toFixed( 2) } </td>
                                        <td> { outputData.Output[79].toFixed( 2) } </td>
                                        <td> { outputData.Output[92].toFixed( 2) } </td>
                                        <td> { outputData.Output[105].toFixed( 2) } </td>
                                        <td> { outputData.Output[117].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[129].toFixed( 2) } </td>  */}
                                        <td> { outputData.Output[142].toFixed( 2) } </td>
                                        <td> { outputData.Output[155].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> Nov </td>
                                        <td> { outputData.Output[54].toFixed( 2) } </td>
                                        <td> { outputData.Output[67].toFixed( 2) } </td>
                                        <td> { outputData.Output[80].toFixed( 2) } </td>
                                        <td> { outputData.Output[93].toFixed( 2) } </td>
                                        <td> { outputData.Output[106].toFixed( 2) } </td>
                                        <td> { outputData.Output[118].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[130].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[143].toFixed( 2) } </td>
                                        <td> { outputData.Output[156].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td> Dec </td>
                                        <td> { outputData.Output[55].toFixed( 2) } </td>
                                        <td> { outputData.Output[68].toFixed( 2) } </td>
                                        <td> { outputData.Output[81].toFixed( 2) } </td>
                                        <td> { outputData.Output[94].toFixed( 2) } </td>
                                        <td> { outputData.Output[107].toFixed( 2) } </td>
                                        <td> { outputData.Output[119].toFixed( 2) } </td>
                                    {/* <td> { outputData.Output[131].toFixed( 2) } </td> */}
                                        <td> { outputData.Output[144].toFixed( 2) } </td>
                                        <td> { outputData.Output[157].toFixed( 2) } </td>
                                        </tr>
                                    <tr> 
                                        <td className="span-tag"> Annual </td>
                                        <td className="span-tag"> { outputData.Output[56].toFixed( 2) } </td>
                                        <td className="span-tag"> { outputData.Output[69].toFixed( 2) } </td>
                                        <td className="span-tag"> { outputData.Output[82].toFixed( 2) } </td>
                                        <td className="span-tag"> { outputData.Output[95].toFixed( 2) } </td>
                                        <td>  ------ </td>
                                        <td>  ------ </td>
                                    {/* <td className="span-tag"> { outputData.Output[132].toFixed( 2) } </td> */}
                                        <td className="span-tag"> { outputData.Output[145].toFixed( 2) } </td>
                                        <td className="span-tag"> { outputData.Output[159].toFixed( 2) } </td>
                                    </tr> 
                                                                  
                                </table> <br/>
                                <table width = "100%"> 
                                <tr style={{color: "maroon"}}>
                                    <th> Abbreviations / Legends </th>   
                                </tr>                                          
                                    <tr>GHI : Horizontal Global Irradiation in kWh / Sq.m </tr>
                                    <tr> GNI : Global Normal Irradiation in kWh / Sq.m </tr>
                                    <tr> DNI : Direct Normal Irradiation in kWh / Sq.m </tr>
                                    <tr> DHI : Diffuse Horizontal Irradiation in kWh / Sq.m </tr>
                                    <tr> T Amb : Ambient Temperature Monthwise in degree centigrade </tr>
                                {/* <tr> AOI : Monthly Angle of Incidence of Sun rays in Degrees </tr>  */}
                                    <tr> G : Insolation or Irradiation in W / Sq.m </tr>
                                    <tr> E_Arr_DC : Monthly DC Energy Produced by the Solar Module Arrays in MWh </tr>
                                    <tr> PR : Monthly Performance Ratio in % </tr>
                                    
                                </table> <br/>    
                                <section className="span-tag"> <u>Predicted Energy Output & Performance Ratio </u>
                                </section>
                                                                
                                <table> 
                                    <tr className="span-tag" style={{color: "blue"}}> Yearly energy produced (DC energy) by Array of PV modules in MWh : { outputData.Output[145].toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> Yearly energy produced by the Inverters (AC energy) in MWh : { outputData.Output[158].toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> Performance Ratio of the PV Plant in % : { outputData.Output[159].toFixed( 2) }</tr>    
                                    <tr className="span-tag" style={{color: "blue"}}> The minimum distance between two (2) consecutive rows of fixed tilt modules to avoid shadow overlapping or Pitch in mm : { outputData.Output[36].toFixed( 2) }</tr>                                
                                </table><br/>   
                                <div>
                                    <span>
                                        <Bar options= {chartOptions}  data= { chartData} />
                                        <Bar options= {prOptions}  data= { prData} /> 
                                    </span>
                                    <br/>
                                <section className="span-tag text-center"><u>Financial Modelling of the Project</u></section>
                                                                  
                                <table width="100%">
                                     <tr>
                                        <th style={{color: "blueviolet"}} className="span-tag"> Financial Input Data entered by the customer </th>
                                    </tr>
                                    <tr>
                                        <td width = "50%">Capex of the project in {outputData.Output[359]} : { outputData.Output[160] } </td>
                                        
                                    </tr>
                                    
                                    <tr>
                                        <td width = "50%">Salvage value of the plant (in %) : { outputData.Output[164] } </td>
                                        <td width = "50%">Selling price of electricity in {outputData.Output[359]}/kWh : { outputData.Output[165] } </td>
                                    </tr>
                                                   
                                    <tr>
                                        <td width = "50%">Rate of Depreciation (in %) : { outputData.Output[166] } </td>
                                        <td width = "50%">No. of years for application of WDV method of depreciation : { outputData.Output[167] }</td>
                                        
                                    </tr>
                                    <tr>
                                        <td width = "50%">Labour cost for the 1st year of operation in {outputData.Output[359]} : { outputData.Output[168] } </td>
                                        <td width = "50%">Escalation of Labour Cost per Year (in %) : { outputData.Output[169] }</td>
                                    </tr>
                                    <tr>
                                        <td width = "50%">Cost of Maintenance Spares as % of labour cost (in %) : { outputData.Output[170] }</td>
                                        <td width = "50%">Debt to Equity ratio (in decimal) : { outputData.Output[171] } </td>
                                    </tr>
                                    <tr>
                                        <td width = "50%">Rate of interest for Debt (in %) : { outputData.Output[172] } </td>
                                        <td width = "50%">No. of years for repayment of Debt (in years) : { outputData.Output[173] } </td>
                                        
                                    </tr>
                                    <tr>
                                        <td width = "50%">Tax Rate (in %) : { outputData.Output[174] } </td>
                                        <td width = "50%">Risk free rate of interest (in %) : { outputData.Output[175] } </td>
                                        
                                    </tr>
                                   <tr>
                                        <td width = "50%">Enter company sensitivity value Beta (default is 1) : { outputData.Output[176] } </td>
                                        <td width = "50%">Equity risk premium (in %) : { outputData.Output[177] } </td>
                                    </tr>
                                </table><br/>
                                
                                <table width="100%">
                                    <tr>
                                        <th className="span-tag text-center"> Yearwise Energy Generation along with Financial Data </th>
                                    </tr>
                                </table>
                                <table width="100%">                      
                                    <tr style={{color: "green"}}> 
                                        <th>Year</th>
                                        <th>AC Energy</th>
                                        <th>O&M Cost</th>
                                        <th>Depreciation</th>
                                        <th>Interest Cost</th>
                                        <th>Revenue</th>
                                        <th>PAT</th>
                                        <th>FCFF</th>

                                    </tr>                                             
                                    <tr > 
                                        <td> Yr 1 </td>
                                        <td> { outputData.Output[178].toFixed( 2) } </td>
                                        <td> { outputData.Output[203].toFixed( 2) } </td>
                                        <td> { outputData.Output[228].toFixed( 2) } </td>
                                        <td> { outputData.Output[253].toFixed( 2) } </td>
                                        <td> { outputData.Output[278].toFixed( 2) } </td>
                                        <td> { outputData.Output[303].toFixed( 2) } </td>
                                        <td> { outputData.Output[328].toFixed( 2) } </td>

                                    </tr>
                                    <tr > 
                                        <td> Yr 2 </td>
                                        <td> { outputData.Output[179].toFixed( 2) } </td>
                                        <td> { outputData.Output[204].toFixed( 2) } </td>
                                        <td> { outputData.Output[229].toFixed( 2) } </td>
                                        <td> { outputData.Output[254].toFixed( 2) } </td>
                                        <td> { outputData.Output[279].toFixed( 2) } </td>
                                        <td> { outputData.Output[304].toFixed( 2) } </td>
                                        <td> { outputData.Output[329].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 3 </td>
                                        <td> { outputData.Output[180].toFixed( 2) } </td>
                                        <td> { outputData.Output[205].toFixed( 2) } </td>
                                        <td> { outputData.Output[230].toFixed( 2) } </td>
                                        <td> { outputData.Output[255].toFixed( 2) } </td>
                                        <td> { outputData.Output[280].toFixed( 2) } </td>
                                        <td> { outputData.Output[305].toFixed( 2) } </td>
                                        <td> { outputData.Output[330].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 4 </td>
                                        <td> { outputData.Output[181].toFixed( 2) } </td>
                                        <td> { outputData.Output[206].toFixed( 2) } </td>
                                        <td> { outputData.Output[231].toFixed( 2) } </td>
                                        <td> { outputData.Output[256].toFixed( 2) } </td>
                                        <td> { outputData.Output[281].toFixed( 2) } </td>
                                        <td> { outputData.Output[306].toFixed( 2) } </td>
                                        <td> { outputData.Output[331].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 5 </td>
                                        <td> { outputData.Output[182].toFixed( 2) } </td>
                                        <td> { outputData.Output[207].toFixed( 2) } </td>
                                        <td> { outputData.Output[232].toFixed( 2) } </td>
                                        <td> { outputData.Output[257].toFixed( 2) } </td>
                                        <td> { outputData.Output[282].toFixed( 2) } </td>
                                        <td> { outputData.Output[307].toFixed( 2) } </td>
                                        <td> { outputData.Output[332].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 6 </td>
                                        <td> { outputData.Output[183].toFixed( 2) } </td>
                                        <td> { outputData.Output[208].toFixed( 2) } </td>
                                        <td> { outputData.Output[233].toFixed( 2) } </td>
                                        <td> { outputData.Output[258].toFixed( 2) } </td>
                                        <td> { outputData.Output[283].toFixed( 2) } </td>
                                        <td> { outputData.Output[308].toFixed( 2) } </td>
                                        <td> { outputData.Output[333].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 7 </td>
                                        <td> { outputData.Output[184].toFixed( 2) } </td>
                                        <td> { outputData.Output[209].toFixed( 2) } </td>
                                        <td> { outputData.Output[234].toFixed( 2) } </td>
                                        <td> { outputData.Output[259].toFixed( 2) } </td>
                                        <td> { outputData.Output[284].toFixed( 2) } </td>
                                        <td> { outputData.Output[309].toFixed( 2) } </td>
                                        <td> { outputData.Output[334].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 8 </td>
                                        <td> { outputData.Output[185].toFixed( 2) } </td>
                                        <td> { outputData.Output[210].toFixed( 2) } </td>
                                        <td> { outputData.Output[235].toFixed( 2) } </td>
                                        <td> { outputData.Output[260].toFixed( 2) } </td>
                                        <td> { outputData.Output[285].toFixed( 2) } </td>
                                        <td> { outputData.Output[310].toFixed( 2) } </td>
                                        <td> { outputData.Output[335].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 9 </td>
                                        <td> { outputData.Output[186].toFixed( 2) } </td>
                                        <td> { outputData.Output[211].toFixed( 2) } </td>
                                        <td> { outputData.Output[236].toFixed( 2) } </td>
                                        <td> { outputData.Output[261].toFixed( 2) } </td>
                                        <td> { outputData.Output[286].toFixed( 2) } </td>
                                        <td> { outputData.Output[311].toFixed( 2) } </td>
                                        <td> { outputData.Output[336].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 10 </td>
                                        <td> { outputData.Output[187].toFixed( 2) } </td>
                                        <td> { outputData.Output[212].toFixed( 2) } </td>
                                        <td> { outputData.Output[237].toFixed( 2) } </td>
                                        <td> { outputData.Output[262].toFixed( 2) } </td>
                                        <td> { outputData.Output[287].toFixed( 2) } </td>
                                        <td> { outputData.Output[312].toFixed( 2) } </td>
                                        <td> { outputData.Output[337].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 11 </td>
                                        <td> { outputData.Output[188].toFixed( 2) } </td>
                                        <td> { outputData.Output[213].toFixed( 2) } </td>
                                        <td> { outputData.Output[238].toFixed( 2) } </td>
                                        <td> { outputData.Output[263].toFixed( 2) } </td>
                                        <td> { outputData.Output[288].toFixed( 2) } </td>
                                        <td> { outputData.Output[313].toFixed( 2) } </td>
                                        <td> { outputData.Output[338].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 12 </td>
                                        <td> { outputData.Output[189].toFixed( 2) } </td>
                                        <td> { outputData.Output[214].toFixed( 2) } </td>
                                        <td> { outputData.Output[239].toFixed( 2) } </td>
                                        <td> { outputData.Output[264].toFixed( 2) } </td>
                                        <td> { outputData.Output[289].toFixed( 2) } </td>
                                        <td> { outputData.Output[314].toFixed( 2) } </td>
                                        <td> { outputData.Output[339].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 13 </td>
                                        <td> { outputData.Output[190].toFixed( 2) } </td>
                                        <td> { outputData.Output[215].toFixed( 2) } </td>
                                        <td> { outputData.Output[240].toFixed( 2) } </td>
                                        <td> { outputData.Output[265].toFixed( 2) } </td>
                                        <td> { outputData.Output[290].toFixed( 2) } </td>
                                        <td> { outputData.Output[315].toFixed( 2) } </td>
                                        <td> { outputData.Output[340].toFixed( 2) } </td>
                                    </tr> 
                                    <tr > 
                                        <td> Yr 14 </td>
                                        <td> { outputData.Output[191].toFixed( 2) } </td>
                                        <td> { outputData.Output[216].toFixed( 2) } </td>
                                        <td> { outputData.Output[241].toFixed( 2) } </td>
                                        <td> { outputData.Output[266].toFixed( 2) } </td>
                                        <td> { outputData.Output[291].toFixed( 2) } </td>
                                        <td> { outputData.Output[316].toFixed( 2) } </td>
                                        <td> { outputData.Output[341].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 15 </td>
                                        <td> { outputData.Output[192].toFixed( 2) } </td>
                                        <td> { outputData.Output[217].toFixed( 2) } </td>
                                        <td> { outputData.Output[242].toFixed( 2) } </td>
                                        <td> { outputData.Output[267].toFixed( 2) } </td>
                                        <td> { outputData.Output[292].toFixed( 2) } </td>
                                        <td> { outputData.Output[317].toFixed( 2) } </td>
                                        <td> { outputData.Output[342].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 16 </td>
                                        <td> { outputData.Output[193].toFixed( 2) } </td>
                                        <td> { outputData.Output[218].toFixed( 2) } </td>
                                        <td> { outputData.Output[243].toFixed( 2) } </td>
                                        <td> { outputData.Output[268].toFixed( 2) } </td>
                                        <td> { outputData.Output[293].toFixed( 2) } </td>
                                        <td> { outputData.Output[318].toFixed( 2) } </td>
                                        <td> { outputData.Output[343].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 17 </td>
                                        <td> { outputData.Output[194].toFixed( 2) } </td>
                                        <td> { outputData.Output[219].toFixed( 2) } </td>
                                        <td> { outputData.Output[244].toFixed( 2) } </td>
                                        <td> { outputData.Output[269].toFixed( 2) } </td>
                                        <td> { outputData.Output[294].toFixed( 2) } </td>
                                        <td> { outputData.Output[319].toFixed( 2) } </td>
                                        <td> { outputData.Output[344].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 18 </td>
                                        <td> { outputData.Output[195].toFixed( 2) } </td>
                                        <td> { outputData.Output[220].toFixed( 2) } </td>
                                        <td> { outputData.Output[245].toFixed( 2) } </td>
                                        <td> { outputData.Output[270].toFixed( 2) } </td>
                                        <td> { outputData.Output[295].toFixed( 2) } </td>
                                        <td> { outputData.Output[320].toFixed( 2) } </td>
                                        <td> { outputData.Output[345].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 19 </td>
                                        <td> { outputData.Output[196].toFixed( 2) } </td>
                                        <td> { outputData.Output[221].toFixed( 2) } </td>
                                        <td> { outputData.Output[246].toFixed( 2) } </td>
                                        <td> { outputData.Output[271].toFixed( 2) } </td>
                                        <td> { outputData.Output[296].toFixed( 2) } </td>
                                        <td> { outputData.Output[321].toFixed( 2) } </td>
                                        <td> { outputData.Output[346].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 20 </td>
                                        <td> { outputData.Output[197].toFixed( 2) } </td>
                                        <td> { outputData.Output[222].toFixed( 2) } </td>
                                        <td> { outputData.Output[247].toFixed( 2) } </td>
                                        <td> { outputData.Output[272].toFixed( 2) } </td>
                                        <td> { outputData.Output[297].toFixed( 2) } </td>
                                        <td> { outputData.Output[322].toFixed( 2) } </td>
                                        <td> { outputData.Output[347].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 21 </td>
                                        <td> { outputData.Output[198].toFixed( 2) } </td>
                                        <td> { outputData.Output[223].toFixed( 2) } </td>
                                        <td> { outputData.Output[248].toFixed( 2) } </td>
                                        <td> { outputData.Output[273].toFixed( 2) } </td>
                                        <td> { outputData.Output[298].toFixed( 2) } </td>
                                        <td> { outputData.Output[323].toFixed( 2) } </td>
                                        <td> { outputData.Output[348].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 22 </td>
                                        <td> { outputData.Output[199].toFixed( 2) } </td>
                                        <td> { outputData.Output[224].toFixed( 2) } </td>
                                        <td> { outputData.Output[249].toFixed( 2) } </td>
                                        <td> { outputData.Output[274].toFixed( 2) } </td>
                                        <td> { outputData.Output[299].toFixed( 2) } </td>
                                        <td> { outputData.Output[324].toFixed( 2) } </td>
                                        <td> { outputData.Output[349].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 23 </td>
                                        <td> { outputData.Output[200].toFixed( 2) } </td>
                                        <td> { outputData.Output[225].toFixed( 2) } </td>
                                        <td> { outputData.Output[250].toFixed( 2) } </td>
                                        <td> { outputData.Output[275].toFixed( 2) } </td>
                                        <td> { outputData.Output[300].toFixed( 2) } </td>
                                        <td> { outputData.Output[325].toFixed( 2) } </td>
                                        <td> { outputData.Output[350].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 24 </td>
                                        <td> { outputData.Output[201].toFixed( 2) } </td>
                                        <td> { outputData.Output[226].toFixed( 2) } </td>
                                        <td> { outputData.Output[251].toFixed( 2) } </td>
                                        <td> { outputData.Output[276].toFixed( 2) } </td>
                                        <td> { outputData.Output[301].toFixed( 2) } </td>
                                        <td> { outputData.Output[326].toFixed( 2) } </td>
                                        <td> { outputData.Output[351].toFixed( 2) } </td>
                                    </tr>
                                    <tr > 
                                        <td> Yr 25 </td>
                                        <td> { outputData.Output[202].toFixed( 2) } </td>
                                        <td> { outputData.Output[227].toFixed( 2) } </td>
                                        <td> { outputData.Output[252].toFixed( 2) } </td>
                                        <td> { outputData.Output[277].toFixed( 2) } </td>
                                        <td> { outputData.Output[302].toFixed( 2) } </td>
                                        <td> { outputData.Output[327].toFixed( 2) } </td>
                                        <td> { outputData.Output[352].toFixed( 2) } </td>
                                    </tr>
                                                                  
                                </table> <br/>
                                <table width="100%"> 
                                     <tr style={{color: "maroon"}} className="span-tag"> Abbreviations / Legends </tr>                                             
                                    <tr> AC Energy : Annual AC Energy output from the Inverter kWh </tr>
                                    <tr> O&M Cost : Annual Operation & Maintenance cost in {outputData.Output[359]} </tr>
                                    <tr> Depreciation : Annual depreciation value of the plant in {outputData.Output[359]}</tr>
                                    <tr> Interest Cost : Annual interest cost for the plant in {outputData.Output[359]} </tr>
                                    <tr> Revenue : Annual Revenue generation for the plant in {outputData.Output[359]} </tr>
                                    <tr> PAT : Profit after tax for the plant in {outputData.Output[359]}</tr>
                                    <tr> FCFF : Free Cash Flow to the Firm / Company for the plant in {outputData.Output[359]}</tr>
                                    
                                </table> <br/>    
                                <table width="100%">
                                    <tr className="span-tag">
                                        <th> <u>Predicted Financial Parameters of the Project </u></th>
                                    </tr>
                                </table>
                                <table width="100%"> 
                                    <tr className="span-tag" style={{color: "blue"}}> Cost of Equity in % : { outputData.Output[353].toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> Cost of Capital in % : { outputData.Output[354].toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> Levelized Cost of Electricity, LCoE in {outputData.Output[359]}/kWh : { outputData.Output[355].toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> Net Present Value of the Project, NPV in {outputData.Output[359]} : { outputData.Output[356].NPV.toFixed( 2) } </tr>
                                    <tr className="span-tag" style={{color: "blue"}}> Payback Period for the Project in years : { outputData.Output[356].Payback.toFixed( 2) }</tr>
                                {/* <tr className="span-tag" style={{color: "blue"}}> Return on Asset (ROA) for the Project in % : { outputData.Output[356].ROA.toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> Return on Equity (ROE) for the Project in % : { outputData.Output[356].ROE.toFixed( 2) }</tr> */}
                                    <tr className="span-tag" style={{color: "blue"}}> Project IRR (Internal Rate of Return) in % : { outputData.Output[357].toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> Equity IRR (Internal Rate of Return) in %  : { outputData.Output[358].toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> PLCR(Project Life Coverage Ratio) : { outputData.Output[360].PLCR.toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> LLCR(Loan Life Coverage Ratio) : { outputData.Output[360].LLCR.toFixed( 2) }</tr>
                                    <tr className="span-tag" style={{color: "blue"}}> Periodic Coverage Ratios - DSCR(Debt Service Coverage Ratio) : { outputData.Output[361].DSCR[0].toFixed( 2) }, 
                                     { outputData.Output[361].DSCR[1].toFixed( 2) }, { outputData.Output[361].DSCR[2].toFixed( 2) }, { outputData.Output[361].DSCR[3].toFixed( 2) }, 
                                     { outputData.Output[361].DSCR[4].toFixed( 2) }, { outputData.Output[361].DSCR[5].toFixed( 2) }, { outputData.Output[361].DSCR[6].toFixed( 2) }, 
                                     { outputData.Output[361].DSCR[7].toFixed( 2) }, { outputData.Output[361].DSCR[8].toFixed( 2) }, { outputData.Output[361].DSCR[9].toFixed( 2) }, 
                                     { outputData.Output[361].DSCR[10].toFixed( 2) }, { outputData.Output[361].DSCR[11].toFixed( 2) }, { outputData.Output[361].DSCR[12].toFixed( 2) }, 
                                     { outputData.Output[361].DSCR[13].toFixed( 2) }, { outputData.Output[361].DSCR[14].toFixed( 2) }, { outputData.Output[361].DSCR[15].toFixed( 2) }, 
                                     { outputData.Output[361].DSCR[16].toFixed( 2) }, { outputData.Output[361].DSCR[17].toFixed( 2) }, { outputData.Output[361].DSCR[18].toFixed( 2) }, 
                                     { outputData.Output[361].DSCR[19].toFixed( 2) }, { outputData.Output[361].DSCR[20].toFixed( 2) }, { outputData.Output[361].DSCR[21].toFixed( 2) }, 
                                     { outputData.Output[361].DSCR[22].toFixed( 2) }, { outputData.Output[361].DSCR[23].toFixed( 2) }, { outputData.Output[361].DSCR[24].toFixed( 2) } 
                                     & ICR(Interest Coverage Ratio) are : { outputData.Output[361].ICR[0].toFixed( 2) }, { outputData.Output[361].ICR[1].toFixed( 2) }, 
                                     { outputData.Output[361].ICR[2].toFixed( 2) }, { outputData.Output[361].ICR[3].toFixed( 2) }, { outputData.Output[361].ICR[4].toFixed( 2) }, 
                                     { outputData.Output[361].ICR[5].toFixed( 2) }, { outputData.Output[361].ICR[6].toFixed( 2) }, { outputData.Output[361].ICR[7].toFixed( 2) }, 
                                     { outputData.Output[361].ICR[8].toFixed( 2) }, { outputData.Output[361].ICR[9].toFixed( 2) }, { outputData.Output[361].ICR[10].toFixed( 2) }, 
                                     { outputData.Output[361].ICR[11].toFixed( 2) }, { outputData.Output[361].ICR[12].toFixed( 2) }, { outputData.Output[361].ICR[13].toFixed( 2) }, 
                                     { outputData.Output[361].ICR[14].toFixed( 2) }, { outputData.Output[361].ICR[15].toFixed( 2) }, { outputData.Output[361].ICR[16].toFixed( 2) }, 
                                     { outputData.Output[361].ICR[17].toFixed( 2) }, { outputData.Output[361].ICR[18].toFixed( 2) }, { outputData.Output[361].ICR[19].toFixed( 2) }, 
                                     { outputData.Output[361].ICR[20].toFixed( 2) }, { outputData.Output[361].ICR[21].toFixed( 2) }, { outputData.Output[361].ICR[22].toFixed( 2) }, 
                                     { outputData.Output[361].ICR[23].toFixed( 2) }, { outputData.Output[361].ICR[24].toFixed( 2) }, </tr>
                                
                                </table>   
                                <div>
                                  <br/>                      
                                    <div className="fw-bold text-black">
                                        Thank you very much for using our Solar PV SaaS program. Hope it helped you. Please provide your feedback in the comment section.
                                    </div>
                                  <br/>  
                                  <div className="row">
                                    <div class="text-center">
                                        <button className="btn btn-style location-middle w-40 fw-bold" onClick={()=> history.push("/homelogin")}>Home</button>
                                   
                                       <button onClick={ handlePrint } className="print__button btn btn-style location-middle fw-bold w-40"> Print</button>
                                    </div>
                                    <div class="text-center">
                                    <br />
                                </div>
                                </div>
                                </div> 
                                </div> 
                            </p>
                          
                        </div>
                    </div>   
                          
                </section>   
                </div>
                </div>

                </>     
            )
    }

    
} 
                                
export default Finoutput;


