import React from 'react';
import Navbar from "../navbar";
import Leadership from "../Leadership";
import Footer from "../Footer";

const Founders = () => {
    return (
        <>
            <Navbar />      
            <Leadership />      
            <Footer />
        </>
    );
};

export default Founders;
