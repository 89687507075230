import React from 'react';
import Navbarmodule from "../navbarmodule";
import Careers from "../Careers";
import Footerlogin from "../Footerlogin";


const Careerslogin = () => {
    return (
        <>
            <Navbarmodule />
            <Careers />   
            <Footerlogin />         
        </>
    );
};

export default Careerslogin;