import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
import Axios from 'axios';
import HydrogenOutput from './HydrogenOutput.js';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import {Bar} from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function PostForm() {
    const url ="/api//HydrogenModel"
    const currDate = new Date().toLocaleDateString()
    const currTime = new Date().toLocaleTimeString()

    const [outputData, setoutputData] = useState(null)    

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content:() =>componentRef.current,
    });

    const history = useHistory()

    const [data, setData] = useState({
        plantName:"",
        rating: "",
        life: "",
        type_electrolyzer: "",
        degrade:"",
        cc: "",
        r_sv: "",
        p: "",
        r_dpr:"",
        y_wdv: "",
        dc_electrolyzer: "",
        elect_cost: "",
        elect_share:"",
        running: "",
        op_esc: "",
        d_e: "",
        rd:"",
        y_rpmnt: "",
        insr:"",
        t: "",
        y_cpx: "",
        yr_replace: "",
        replace_cc: "",
        cap_subsidy: "",
        cost_equity_input: "",
        r_input: "",        
        b_input: "",        
        erp_input: "",
        
        currency:"",
                
    })

    async function submit(e) {
        e.preventDefault();
        Axios.post(url, {
            
            plantName: data.plantName,
            rating: parseFloat(data.rating),
            life: parseFloat(data.life),
            type_electrolyzer: data.type_electrolyzer,
            degrade:parseFloat(data.degrade),
            cc: parseFloat(data.cc),
            r_sv: parseFloat(data.r_sv),
            p: parseFloat(data.p),
            r_dpr:parseFloat(data.r_dpr),
            y_wdv: parseFloat(data.y_wdv),
            dc_electrolyzer: parseFloat(data.dc_electrolyzer),
            elect_cost: parseFloat(data.elect_cost),
            elect_share:parseFloat(data.elect_share),
            running: parseFloat(data.running),
            op_esc: parseFloat(data.op_esc),
            d_e: parseFloat(data.d_e),
            rd: parseFloat(data.rd),
            y_rpmnt: parseFloat(data.y_rpmnt),
            insr:parseFloat(data.insr),
            t: parseFloat(data.t),
            y_cpx: parseFloat(data.y_cpx),
            yr_replace: parseFloat(data.yr_replace),
            replace_cc: parseFloat(data.replace_cc),
            cap_subsidy: parseFloat(data.cap_subsidy),
            cost_equity_input: parseFloat(data.cost_equity_input),
            r_input: parseFloat(data.r_input),
            b_input:parseFloat(data.b_input),
            erp_input:parseFloat(data.erp_input),
            
            currency:data.currency,
                        
           // G:parseFloat(data.G)
        }).then (res => {setoutputData(res.data);
            console.log(outputData)
                       
        })
      
    }

    if (outputData) { 
        console.log(outputData);
        console.log(outputData.Output[0])
        console.log(outputData.Output[1])
        console.log(outputData.Output[2])
       
    }
    
    function handle(e) {
        const newdata = {...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
        console.log(newdata)
    }
    if (outputData) 
    {
        if (outputData.Output[1]) {  
                    
            console.log(outputData.Output[0])
            return (
            <>
            <div>
            
            <form onSubmit={(e) =>submit(e)}>
                                 
            <section className="contactus-section">
              <div className="loginregister container">
                  <div className="row">
                      <div className="col-12 col-lg-12 ">
                        <div className="row">
                            <div>
                                <h2 className="main-heading fw-bold text-center">
                                    INPUT DATA TO BE FILLED BY THE USER FOR FINANCIAL MODELLING OF HYDROGEN GENERATION PLANT
                                </h2><br/>

                                <div className="col-12 col-lg-12 contact-input-field flex-nowrap">
                                    <p style={{color: "blue"}}> <span><b><u>INSTRUCTIONS :</u></b></span> (a) Conversion factors shall be considered as 1 Nm3/h = 0.09 kg/h & 1kg/hr of H2 ~ 53 - 56 kW of AC Electricity 
                                    consumption. (b) Max. plant life shall be considered as 20 years. (c) If Alkaline electrolyzer is selected, the membrane replacement is usually after 10th 
                                    year (80,000 - 90,000 running hours, check with manufacturers). If PEM electrolyzer is selected, then the life will be approximately 40,000 - 50,000 hours 
                                    (Please check with manufacturers) and whole electrolyzers are required to be replaced. Refer datasheet of the manufacturers for life of electrolyzers in terms 
                                    of years or running hours. (d) The degradation factor shall be considered from the data sheet of the manufacturers. Consider 1% as degradation value in case data 
                                    sheet is not available or not specified by manufacturers.                                 </p>
                                </div>
                                <p className="main-hero-para text-dark text-center"> <h6><u>Plant General Information to be filled by the User </u></h6></p>
                            </div>
                            
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="plantName" value = {data.plantName} placeholder="Name of the plant" type="text" className="form-control"></textarea>  
                                </div>                                    
                              
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="rating" value = {data.rating} placeholder="Please indicate the rating of the plant in Nm3/h " type="number" className="form-control"></textarea> 
                                </div>
                               
                            </div>         

                        
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="life" value = {data.life} placeholder="Enter Useful Life of the plant (in Years)" type="number" className="form-control"></textarea>
                                </div>

                                
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="type_electrolyzer" value = {data.type_electrolyzer} placeholder="Please indicate the type of the electrolyzer - Alkaline / PEM / SOEC / AEM" type="text" className="form-control"></textarea>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="degrade" value = {data.degrade} placeholder="Enter the yearly degradation factor of the electrolyzer in % (e.g. Check with data sheet of the manufacturer, enter 1 for 1%)" type="number" className="form-control"></textarea>
                                </div>                                 
                                                               
                            </div> 

                                <p className="main-hero-para text-dark text-center fw-bold"> <h6><u>Please fill the following
                                            Input Data for the <b>Financial Modelling </b>of the proposed Green Hydrogen Generation Plant </u></h6></p>
                                <div className="row">

                                     <div className="col-12 col-lg-12 contact-input-field flex-nowrap">
                                    <p style={{color: "blue"}}> <span><b><u>INSTRUCTIONS :</u></b></span> (a) Capex depends on the system requirement (b) <span style={{color: "red"}}> <b>Consult Sueng-Tech for 
                                        optimized design solution of the system </b></span>
                                    (c) Rough cost of 200 Nm3/hr Alkaline Electrolyzer system (Electrolyzer & its electrical system like rectifier, transformer, gas-lye separation, 
                                    drying system, lye preparation and feeding system and DM water supply system) is approximately 5 - 5.5 Crores INR (installed). The installed cost 
                                    of PEM Electrolyzer is approximately 2.8 - 3.25 times the cost of Alkaline electrolyzer system. For higher size electrolyzers, suitable factor shall be 
                                    considered for costing. 
                                    (d) Capex for the Hydrogen Generation project without hydrogen compressors may be considered as 1.75 - 2.5  times the installed cost of Alkaline electrolyzer 
                                    system and 1.4 - 1.75 times the installed cost of PEM Electrolyzer. (e) The cost of membrane replacement of Alkaline electrolyzer is 
                                    approximately 25 - 35% of the installed cost of the electrolyzer. (f) Salvage value of plant may be considered as 5% of installed value as per 
                                    Companies Act (Indian) (g) Sell price of Hydrogen may be considered from market intelligence. 
                                    (h) Rate of Depreciation may be connsidered as 5% and years of depreciation for WDV method may be considered as 10 years (i) For AC power 
                                    consumption of electrolyzer plant, the data sheet of electrolyzer may be referred for electrolyzer specific energy consumption (DC power in 
                                    kWh / Nm3) and add suitable margin of 0.2 - 0.6 kWh / Nm3 for BOP portion over & above that of electrolyzer consumption data to 
                                    arrive at the AC power requirement for the plant. Please remember to use average specific energy consumption data due to wide variation in 
                                    consumption data for BOL (beginning of life) and EOL (end of life) of electrolyzer (j) Cost of green power / power shall be considered based on 
                                    the condition of power supply agreement of the project. (k) Share of green power cost as % to that of overall O&M cost is approximately 
                                    65% to 75%. (l) Electrolyzer running hours may be considered as 8000 hours per annum if continuous requirement is there, else need to estimate 
                                    based on project condition.
                                     (m) The yearly value of capital investment (reinvestment) as % of Revenue may be considered as 0.5. </p>
                                </div> 

                                    <div className="col-12 col-lg-12 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="currency" value = {data.currency } placeholder="Enter the currency of choice, e.g USD for US Dollars, EURO for Euro, AED for UAE Dirhams, SAR for Saudi Riyals, INR for Indian Rupees etc." type="text" className="form-control"></textarea>
                                    </div>                                    
                                </div>
                                <div className="row">                                        
                                
                                </div>
                                
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="cc" value = {data.cc } placeholder="Capex of the Project in terms of value (Enter absolute value of the project) e.g. enter 1000000 for 1000000 INR project" type="number" className="form-control"></textarea>
                                    </div>   
                                                                 
                                        
                                </div>  
                                 
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="r_sv" value = {data.r_sv } placeholder="Salvage value of the plant in %, e.g. enter 5 salvage value as 5 %" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="p" value = {data.p } placeholder="Enter Sale Price of Green H2 (in Currency of Choice/kg) e.g. enter 330 for 330 INR/kg of hydrogen" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="r_dpr" value = {data.r_dpr  } placeholder="Rate of Depreciation in %, e.g. enter 5 for depreciation value of 5 % " type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="y_wdv" value = {data.y_wdv  } placeholder="No. of years for application of WDV method of depreciation in years, e.g. enter 10 for 10 years" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="dc_electrolyzer" value = {data.dc_electrolyzer  } placeholder="Enter the AC power consumption of the complete Electrolyzer Plant (in kWh/(Nm3/h)) e.g. enter 5.2 for 5.2kWh/(Nm3/h)" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="elect_cost" value = {data.elect_cost } placeholder="Enter the cost of green power (in Currency / kWh) e.g. enter 2.6 for 2.6 INR/kWh" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="elect_share" value = {data.elect_share  } placeholder="Specify the share of green power cost as % of overall O&M Cost (in %), e.g. enter 60 for 60%" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="running" value = {data.running } placeholder="Enter the no. of running hours of eletrolyzer per year e.g. enter 7200 for 7200 hours per year" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="op_esc" value = {data.op_esc } placeholder="Enter Escalation of O&M Cost per Year (in %) e.g. enter 5 for 5%" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="d_e" value = {data.d_e } placeholder="Debt to Equity ratio in decimal, e.g. enter 3 if debt - equity ratio is 3:1" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="rd" value = {data.rd } placeholder="Rate of Interest for Debt in %, e.g. enter 10.5 for interest rate as 10.5 %" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="y_rpmnt" value = {data.y_rpmnt } placeholder="No. of years for repayment of Debt in Years, e.g. enter 20 for 20 years loan repayment period" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="insr" value = {data.insr } placeholder="Insurance Cost as % of Depreciated Project Cost for the Year (in %), e.g. enter 0.25 for 0.25% of insurance premium" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="t" value = {data.t } placeholder="Enter Tax Rate (in %), enter 25 for 25% tax rate" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="y_cpx" value = {data.y_cpx} placeholder="Enter the yearly value of capex as % of Revenue (in %), e.g.enter 0.5 for 0.5% " type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="yr_replace" value = {data.yr_replace} placeholder="Enter the year of replacement (e.g. 11 for 11th year) of electrolyzer membranes or complete Electrolyzers as the case may be" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="replace_cc" value = {data.replace_cc} placeholder="Enter the capex for replacement of electrolyzer membranes or complete Electrolyzers as the case may be" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="cap_subsidy" value = {data.cap_subsidy} placeholder="Enter the value of capital subsidy from the Government" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="col-12 col-lg-12 contact-input-field flex-nowrap">
                                    <p style={{color: "blue"}}> <span><b><u>INSTRUCTIONS :</u></b></span>  Please note for the following 4 input data : if you enter Cost of Equity e.g. 14%, then the value of next 3 fields namely, Risk free Interest, Beta & Equity risk premium
                                     may be inserted as 0. If all the 4 values are inserted, then our model will calculate the maximum value and result will be published accordingly. </p>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="cost_equity_input" value = {data.cost_equity_input} placeholder="Enter the Cost of Equity i.e. desired rate of return for equity investors (in %), e.g. enter 14 for 14%" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="r_input" value = {data.r_input} placeholder="Enter Risk Free Rate of Interest (in %), e.g. enter 6.5 for 6.5%" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="b_input" value = {data.b_input} placeholder="Enter Beta (in decimal), e.g. default value is 1" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="erp_input" value = {data.erp_input} placeholder="Enter Equity Risk Premium (in %), e.g. 7.5 for 7.5%" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>



                                
                                <div className="row">
                                    <div class="text-center">
                                        <button className="btn btn-style location-middle fw-bold w-50">See Results below / Re-Model</button>
                                    
                                        <button className="btn btn-style location-middle w-40 fw-bold" onClick={()=> history.push("/homelogin")}>Home</button>
                                    </div>
                                
                                    <div class="text-center">
                                        <br />
                                    </div>
                                    <div class="text-center">
                                        <h6 style={{color: "orange"}}><strong><u>RESULTS FROM SUENG-TECH MODELLING PLATFORM</u></strong></h6>
                                    </div>
                                    <div class="text-center">
                                        <br />
                                    </div>
                                </div>
                                

                                <div>
                                    <HydrogenOutput {...outputData} />
                                </div>   
                                                    
                               
                            
                            </div>
                        </div>
                      </div>
                    </div>
                
            </section>
        
            </form>
                     
        </div>
        
        </>
    );
    }


else if (!outputData.Output[1]) {
    alert(outputData.Output[0]) 

    return (
        <>
        <div>
            
            <form onSubmit={(e) =>submit(e)}>
                                 
            <section className="contactus-section">
              <div className="loginregister container">
                  <div className="row">
                      <div className="col-12 col-lg-12 ">
                        <div className="row">
                            <div>
                                <h2 className="main-heading fw-bold text-center">
                                    INPUT DATA TO BE FILLED BY THE USER FOR FINANCIAL MODELLING OF HYDROGEN GENERATION PLANT
                                </h2><br/>

                                <div className="col-12 col-lg-12 contact-input-field flex-nowrap">
                                    <p style={{color: "blue"}}> <span><b><u>INSTRUCTIONS :</u></b></span> (a) Conversion factors shall be considered as 1 Nm3/h = 0.09 kg/h & 1kg/hr of H2 ~ 53 - 56 kW of AC Electricity 
                                    consumption. (b) Max. plant life shall be considered as 20 years. (c) If Alkaline electrolyzer is selected, the membrane replacement is usually after 10th 
                                    year (80,000 - 90,000 running hours, check with manufacturers). If PEM electrolyzer is selected, then the life will be approximately 40,000 - 50,000 hours 
                                    (Please check with manufacturers) and whole electrolyzers are required to be replaced. Refer datasheet of the manufacturers for life of electrolyzers in terms 
                                    of years or running hours. (d) The degradation factor shall be considered from the data sheet of the manufacturers. Consider 1% as degradation value in case data 
                                    sheet is not available or not specified by manufacturers.                                 </p>
                                </div>
                                <p className="main-hero-para text-dark text-center"> <h6><u>Plant General Information to be filled by the User </u></h6></p>
                            </div>
                            
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="plantName" value = {data.plantName} placeholder="Name of the plant" type="text" className="form-control"></textarea>  
                                </div>                                    
                              
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="rating" value = {data.rating} placeholder="Please indicate the rating of the plant in Nm3/h " type="number" className="form-control"></textarea> 
                                </div>
                               
                            </div>         

                        
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="life" value = {data.life} placeholder="Enter Useful Life of the plant (in Years)" type="number" className="form-control"></textarea>
                                </div>

                                
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="type_electrolyzer" value = {data.type_electrolyzer} placeholder="Please indicate the type of the electrolyzer - Alkaline / PEM / SOEC / AEM" type="text" className="form-control"></textarea>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="degrade" value = {data.degrade} placeholder="Enter the yearly degradation factor of the electrolyzer in % (e.g. Check with data sheet of the manufacturer, enter 1 for 1%)" type="number" className="form-control"></textarea>
                                </div>                                 
                                                               
                            </div> 

                                <p className="main-hero-para text-dark text-center fw-bold"> <h6><u>Please fill the following
                                            Input Data for the <b>Financial Modelling </b>of the proposed Green Hydrogen Generation Plant </u></h6></p>
                                <div className="row">

                                     <div className="col-12 col-lg-12 contact-input-field flex-nowrap">
                                    <p style={{color: "blue"}}> <span><b><u>INSTRUCTIONS :</u></b></span> (a) Capex depends on the system requirement (b) <span style={{color: "red"}}> <b>Consult Sueng-Tech for 
                                        optimized design solution of the system </b></span>
                                    (c) Rough cost of 200 Nm3/hr Alkaline Electrolyzer system (Electrolyzer & its electrical system like rectifier, transformer, gas-lye separation, 
                                    drying system, lye preparation and feeding system and DM water supply system) is approximately 5 - 5.5 Crores INR (installed). The installed cost 
                                    of PEM Electrolyzer is approximately 2.8 - 3.25 times the cost of Alkaline electrolyzer system. For higher size electrolyzers, suitable factor shall be 
                                    considered for costing. 
                                    (d) Capex for the Hydrogen Generation project without hydrogen compressors may be considered as 1.75 - 2.5  times the installed cost of Alkaline electrolyzer 
                                    system and 1.4 - 1.75 times the installed cost of PEM Electrolyzer. (e) The cost of membrane replacement of Alkaline electrolyzer is 
                                    approximately 25 - 35% of the installed cost of the electrolyzer. (f) Salvage value of plant may be considered as 5% of installed value as per 
                                    Companies Act (Indian) (g) Sell price of Hydrogen may be considered from market intelligence. 
                                    (h) Rate of Depreciation may be connsidered as 5% and years of depreciation for WDV method may be considered as 10 years (i) For AC power 
                                    consumption of electrolyzer plant, the data sheet of electrolyzer may be referred for electrolyzer specific energy consumption (DC power in 
                                    kWh / Nm3) and add suitable margin of 0.2 - 0.6 kWh / Nm3 for BOP portion over & above that of electrolyzer consumption data to 
                                    arrive at the AC power requirement for the plant. Please remember to use average specific energy consumption data due to wide variation in 
                                    consumption data for BOL (beginning of life) and EOL (end of life) of electrolyzer (j) Cost of green power / power shall be considered based on 
                                    the condition of power supply agreement of the project. (k) Share of green power cost as % to that of overall O&M cost is approximately 
                                    65% to 75%. (l) Electrolyzer running hours may be considered as 8000 hours per annum if continuous requirement is there, else need to estimate 
                                    based on project condition.
                                     (m) The yearly value of capital investment (reinvestment) as % of Revenue may be considered as 0.5. </p>
                                </div> 

                                    <div className="col-12 col-lg-12 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="currency" value = {data.currency } placeholder="Enter the currency of choice, e.g USD for US Dollars, EURO for Euro, AED for UAE Dirhams, SAR for Saudi Riyals, INR for Indian Rupees etc." type="text" className="form-control"></textarea>
                                    </div>                                    
                                </div>
                                <div className="row">                                        
                                
                                </div>
                                
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="cc" value = {data.cc } placeholder="Capex of the Project in terms of value (Enter absolute value of the project) e.g. enter 1000000 for 1000000 INR project" type="number" className="form-control"></textarea>
                                    </div>   
                                                                 
                                        
                                </div>  
                                 
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="r_sv" value = {data.r_sv } placeholder="Salvage value of the plant in %, e.g. enter 5 salvage value as 5 %" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="p" value = {data.p } placeholder="Enter Sale Price of Green H2 (in Currency of Choice/kg) e.g. enter 330 for 330 INR/kg of hydrogen" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="r_dpr" value = {data.r_dpr  } placeholder="Rate of Depreciation in %, e.g. enter 5 for depreciation value of 5 % " type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="y_wdv" value = {data.y_wdv  } placeholder="No. of years for application of WDV method of depreciation in years, e.g. enter 10 for 10 years" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="dc_electrolyzer" value = {data.dc_electrolyzer  } placeholder="Enter the AC power consumption of the complete Electrolyzer Plant (in kWh/(Nm3/h)) e.g. enter 5.2 for 5.2kWh/(Nm3/h)" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="elect_cost" value = {data.elect_cost } placeholder="Enter the cost of green power (in Currency / kWh) e.g. enter 2.6 for 2.6 INR/kWh" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="elect_share" value = {data.elect_share  } placeholder="Specify the share of green power cost as % of overall O&M Cost (in %), e.g. enter 60 for 60%" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="running" value = {data.running } placeholder="Enter the no. of running hours of eletrolyzer per year e.g. enter 7200 for 7200 hours per year" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="op_esc" value = {data.op_esc } placeholder="Enter Escalation of O&M Cost per Year (in %) e.g. enter 5 for 5%" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="d_e" value = {data.d_e } placeholder="Debt to Equity ratio in decimal, e.g. enter 3 if debt - equity ratio is 3:1" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="rd" value = {data.rd } placeholder="Rate of Interest for Debt in %, e.g. enter 10.5 for interest rate as 10.5 %" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="y_rpmnt" value = {data.y_rpmnt } placeholder="No. of years for repayment of Debt in Years, e.g. enter 20 for 20 years loan repayment period" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="insr" value = {data.insr } placeholder="Insurance Cost as % of Depreciated Project Cost for the Year (in %), e.g. enter 0.25 for 0.25% of insurance premium" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="t" value = {data.t } placeholder="Enter Tax Rate (in %), enter 25 for 25% tax rate" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="y_cpx" value = {data.y_cpx} placeholder="Enter the yearly value of capex as % of Revenue (in %), e.g.enter 0.5 for 0.5% " type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="yr_replace" value = {data.yr_replace} placeholder="Enter the year of replacement (e.g. 11 for 11th year) of electrolyzer membranes or complete Electrolyzers as the case may be" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="replace_cc" value = {data.replace_cc} placeholder="Enter the capex for replacement of electrolyzer membranes or complete Electrolyzers as the case may be" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="cap_subsidy" value = {data.cap_subsidy} placeholder="Enter the value of capital subsidy from the Government" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="col-12 col-lg-12 contact-input-field flex-nowrap">
                                    <p style={{color: "blue"}}> <span><b><u>INSTRUCTIONS :</u></b></span>  Please note for the following 4 input data : if you enter Cost of Equity e.g. 14%, then the value of next 3 fields namely, Risk free Interest, Beta & Equity risk premium
                                     may be inserted as 0. If all the 4 values are inserted, then our model will calculate the maximum value and result will be published accordingly. </p>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="cost_equity_input" value = {data.cost_equity_input} placeholder="Enter the Cost of Equity i.e. desired rate of return for equity investors (in %), e.g. enter 14 for 14%" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="r_input" value = {data.r_input} placeholder="Enter Risk Free Rate of Interest (in %), e.g. enter 6.5 for 6.5%" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="b_input" value = {data.b_input} placeholder="Enter Beta (in decimal), e.g. default value is 1" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="erp_input" value = {data.erp_input} placeholder="Enter Equity Risk Premium (in %), e.g. 7.5 for 7.5%" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                
                                
                                
                                <div class="text-center">
                                    <button className="btn btn-style location-middle fw-bold w-50">Submit</button>
                                </div>
                                <div class="text-center">
                                    <br />
                                </div>                      
                               
                            
                            </div>
                        </div>
                      </div>
                    </div>
                
            </section>
        
            </form>
                     
        </div>
        
        </>
    );
} 
}  

    else 
    {        
    return (
        <>
        <div>
            
            <form onSubmit={(e) =>submit(e)}>
                                 
            <section className="contactus-section">
              <div className="loginregister container">
                  <div className="row">
                      <div className="col-12 col-lg-12 ">
                        <div className="row">
                            <div>
                                <h2 className="main-heading fw-bold text-center">
                                    INPUT DATA TO BE FILLED BY THE USER FOR TECHNICAL & FINANCIAL MODELLING OF HYDROGEN GENERATION PLANT
                                </h2><br/>

                                <div className="col-12 col-lg-12 contact-input-field flex-nowrap">
                                    <p style={{color: "blue"}}> <span><b><u>INSTRUCTIONS :</u></b></span> (a) Conversion factors shall be considered as 1 Nm3/h = 0.09 kg/h & 1kg/hr of H2 ~ 53 - 56 kW of AC Electricity 
                                    consumption. (b) Max. plant life shall be considered as 20 years. (c) If Alkaline electrolyzer is selected, the membrane replacement is usually after 10th 
                                    year (80,000 - 90,000 running hours, check with manufacturers). If PEM electrolyzer is selected, then the life will be approximately 40,000 - 50,000 hours 
                                    (Please check with manufacturers) and whole electrolyzers are required to be replaced. Refer datasheet of the manufacturers for life of electrolyzers in terms 
                                    of years or running hours. (d) The degradation factor shall be considered from the data sheet of the manufacturers. Consider 1% as degradation value in case data 
                                    sheet is not available or not specified by manufacturers.                                 </p>
                                </div>
                                <p className="main-hero-para text-dark text-center"> <h6><u>Plant General Information to be filled by the User </u></h6></p>
                            </div>
                            
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="plantName" value = {data.plantName} placeholder="Name of the plant" type="text" className="form-control"></textarea>  
                                </div>                                    
                              
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="rating" value = {data.rating} placeholder="Please indicate the rating of the plant in Nm3/h " type="number" className="form-control"></textarea> 
                                </div>
                               
                            </div>         

                        
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="life" value = {data.life} placeholder="Enter Useful Life of the plant (in Years)" type="number" className="form-control"></textarea>
                                </div>

                                
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="type_electrolyzer" value = {data.type_electrolyzer} placeholder="Please indicate the type of the electrolyzer - Alkaline / PEM / SOEC / AEM" type="text" className="form-control"></textarea>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6 contact-input-field">
                                    <textarea onChange={(e) =>handle(e)} id="degrade" value = {data.degrade} placeholder="Enter the yearly degradation factor of the electrolyzer in % (e.g. Check with data sheet of the manufacturer, enter 1 for 1%)" type="number" className="form-control"></textarea>
                                </div>                                 
                                                               
                            </div> 

                                <p className="main-hero-para text-dark text-center fw-bold"> <h6><u>Please fill the following
                                            Input Data for the <b>Financial Modelling </b>of the proposed Green Hydrogen Generation Plant </u></h6></p>
                                <div className="row">

                                     <div className="col-12 col-lg-12 contact-input-field flex-nowrap">
                                    <p style={{color: "blue"}}> <span><b><u>INSTRUCTIONS :</u></b></span> (a) Capex depends on the system requirement (b) <span style={{color: "red"}}> <b>Consult Sueng-Tech for 
                                        optimized design solution of the system </b></span>
                                    (c) Rough cost of 200 Nm3/hr Alkaline Electrolyzer system (Electrolyzer & its electrical system like rectifier, transformer, gas-lye separation, 
                                    drying system, lye preparation and feeding system and DM water supply system) is approximately 5 - 5.5 Crores INR (installed). The installed cost 
                                    of PEM Electrolyzer is approximately 2.8 - 3.25 times the cost of Alkaline electrolyzer system. For higher size electrolyzers, suitable factor shall be 
                                    considered for costing. 
                                    (d) Capex for the Hydrogen Generation project without hydrogen compressors may be considered as 1.75 - 2.5  times the installed cost of Alkaline electrolyzer 
                                    system and 1.4 - 1.75 times the installed cost of PEM Electrolyzer. (e) The cost of membrane replacement of Alkaline electrolyzer is 
                                    approximately 25 - 35% of the installed cost of the electrolyzer. (f) Salvage value of plant may be considered as 5% of installed value as per 
                                    Companies Act (Indian) (g) Sell price of Hydrogen may be considered from market intelligence. 
                                    (h) Rate of Depreciation may be connsidered as 5% and years of depreciation for WDV method may be considered as 10 years (i) For AC power 
                                    consumption of electrolyzer plant, the data sheet of electrolyzer may be referred for electrolyzer specific energy consumption (DC power in 
                                    kWh / Nm3) and add suitable margin of 0.2 - 0.6 kWh / Nm3 for BOP portion over & above that of electrolyzer consumption data to 
                                    arrive at the AC power requirement for the plant. Please remember to use average specific energy consumption data due to wide variation in 
                                    consumption data for BOL (beginning of life) and EOL (end of life) of electrolyzer (j) Cost of green power / power shall be considered based on 
                                    the condition of power supply agreement of the project. (k) Share of green power cost as % to that of overall O&M cost is approximately 
                                    65% to 75%. (l) Electrolyzer running hours may be considered as 8000 hours per annum if continuous requirement is there, else need to estimate 
                                    based on project condition.
                                     (m) The yearly value of capital investment (reinvestment) as % of Revenue may be considered as 0.5. </p>
                                </div> 

                                    <div className="col-12 col-lg-12 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="currency" value = {data.currency } placeholder="Enter the currency of choice, e.g USD for US Dollars, EURO for Euro, AED for UAE Dirhams, SAR for Saudi Riyals, INR for Indian Rupees etc." type="text" className="form-control"></textarea>
                                    </div>                                    
                                </div>
                                <div className="row">                                        
                                
                                </div>
                                
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="cc" value = {data.cc } placeholder="Capex of the Project in terms of value (Enter absolute value of the project) e.g. enter 1000000 for 1000000 INR project" type="number" className="form-control"></textarea>
                                    </div>   
                                                                 
                                        
                                </div>  
                                 
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="r_sv" value = {data.r_sv } placeholder="Salvage value of the plant in %, e.g. enter 5 salvage value as 5 %" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="p" value = {data.p } placeholder="Enter Sale Price of Green H2 (in Currency of Choice/kg) e.g. enter 330 for 330 INR/kg of hydrogen" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="r_dpr" value = {data.r_dpr  } placeholder="Rate of Depreciation in %, e.g. enter 5 for depreciation value of 5 % " type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="y_wdv" value = {data.y_wdv  } placeholder="No. of years for application of WDV method of depreciation in years, e.g. enter 10 for 10 years" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="dc_electrolyzer" value = {data.dc_electrolyzer  } placeholder="Enter the AC power consumption of the complete Electrolyzer Plant (in kWh/(Nm3/h)) e.g. enter 5.2 for 5.2kWh/(Nm3/h)" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="elect_cost" value = {data.elect_cost } placeholder="Enter the cost of green power (in Currency / kWh) e.g. enter 2.6 for 2.6 INR/kWh" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="elect_share" value = {data.elect_share  } placeholder="Specify the share of green power cost as % of overall O&M Cost (in %), e.g. enter 60 for 60%" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="running" value = {data.running } placeholder="Enter the no. of running hours of eletrolyzer per year e.g. enter 7200 for 7200 hours per year" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="op_esc" value = {data.op_esc } placeholder="Enter Escalation of O&M Cost per Year (in %) e.g. enter 5 for 5%" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="d_e" value = {data.d_e } placeholder="Debt to Equity ratio in decimal, e.g. enter 3 if debt - equity ratio is 3:1" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="rd" value = {data.rd } placeholder="Rate of Interest for Debt in %, e.g. enter 10.5 for interest rate as 10.5 %" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="y_rpmnt" value = {data.y_rpmnt } placeholder="No. of years for repayment of Debt in Years, e.g. enter 20 for 20 years loan repayment period" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="insr" value = {data.insr } placeholder="Insurance Cost as % of Depreciated Project Cost for the Year (in %), e.g. enter 0.25 for 0.25% of insurance premium" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="t" value = {data.t } placeholder="Enter Tax Rate (in %), enter 25 for 25% tax rate" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="y_cpx" value = {data.y_cpx} placeholder="Enter the yearly value of capex as % of Revenue (in %), e.g.enter 0.5 for 0.5% " type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="yr_replace" value = {data.yr_replace} placeholder="Enter the year of replacement (e.g. 11 for 11th year) of electrolyzer membranes or complete Electrolyzers as the case may be" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="replace_cc" value = {data.replace_cc} placeholder="Enter the capex for replacement of electrolyzer membranes or complete Electrolyzers as the case may be" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="cap_subsidy" value = {data.cap_subsidy} placeholder="Enter the value of capital subsidy from the Government" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="col-12 col-lg-12 contact-input-field flex-nowrap">
                                    <p style={{color: "blue"}}> <span><b><u>INSTRUCTIONS :</u></b></span>  Please note for the following 4 input data : if you enter Cost of Equity e.g. 14%, then the value of next 3 fields namely, Risk free Interest, Beta & Equity risk premium
                                     may be inserted as 0. If all the 4 values are inserted, then our model will calculate the maximum value and result will be published accordingly. </p>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="cost_equity_input" value = {data.cost_equity_input} placeholder="Enter the Cost of Equity i.e. desired rate of return for equity investors (in %), e.g. enter 14 for 14%" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="r_input" value = {data.r_input} placeholder="Enter Risk Free Rate of Interest (in %), e.g. enter 6.5 for 6.5%" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="b_input" value = {data.b_input} placeholder="Enter Beta (in decimal), e.g. default value is 1" type="number" className="form-control"></textarea>
                                    </div>    
                                
                                    <div className="col-12 col-lg-6 contact-input-field">
                                        <textarea onChange={(e) =>handle(e)} id="erp_input" value = {data.erp_input} placeholder="Enter Equity Risk Premium (in %), e.g. 7.5 for 7.5%" type="number" className="form-control"></textarea>
                                    </div>    
                                </div>

                               
                                
                                <div class="text-center">
                                    <button className="btn btn-style location-middle fw-bold w-50">Submit</button>
                                </div>
                                <div class="text-center">
                                    <br />
                                </div>                      
                               
                            
                            </div>
                        </div>
                      </div>
                    </div>
                
            </section>
        
            </form>
                     
        </div>
        
        </>
    );
} 
} 
                                
export default PostForm;


