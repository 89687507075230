import React from 'react';
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-10 mx-auto">
                            <div className="row">
                                <div className="col-6 col-lg-3">
                                    
                                    <ul>
                                        
                                        <li>
                                            <Link to="/scheduleademopage">Schedule A Demo</Link>
                                        </li>
                                        <li>
                                            <Link to="/faqpage">FAQ</Link>
                                        </li>
                                       
                                        <li>
                                            <Link to="/contact">Contact us</Link>
                                        </li>
                                    </ul>  
                                </div>
                                <div className="col-6 col-lg-3">
                                    
                                    <ul>
                                        <li>
                                            <Link to="/privacypolicypage">Privacy Policy</Link>
                                        </li>
                                        <li>
                                            <Link to="/tandcpage">Terms & Conditions</Link>
                                        </li>
                                        <li>
                                            <Link to="/disclaimerpage">Disclaimer</Link>
                                        </li>
                                        
                                    </ul>  
                                </div>
                                <div className="col-6 col-lg-3">
                                    
                                    <ul>
                                        <li>
                                            <Link to="/careerspage">Careers</Link>
                                        </li>
                                       
                                        <li>
                                            <Link to="/trainingpage">Training</Link>
                                        </li>
                                        <li>
                                            <Link to="/investorpage">Investor Corner</Link>
                                        </li>
                                    </ul>  
                                </div>
                                <div className="col-6 col-lg-3">
                                
                                    <ul>
                                        <li>
                                            <a href="https://www.linkedin.com/in/suengtech-corporation-b5a627239/" target="_blank" rel="noreferrer">
                                                <i class="fab fa-linkedin fontawesome-style"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
                                                <i class="fab fa-youtube fontawesome-style"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/suengtech" target="_blank" rel="noreferrer">
                                                <i class="fab fa-twitter fontawesome-style"></i>
                                            </a>
                                        </li>
                                       
                                    </ul>  
                                </div>
                                <hr />
                                    <div className="mt-1">
                                        <p className="main-hero-para text-center w-100">Copyright SuEng Tech &copy; 2022; All Rights Reserved</p>
                                    </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </footer>
            
        </>
    );
};

export default Footer;
