import React from 'react';
import Navbarmodule from "../navbarmodule";
import Footerlogin from "../Footerlogin";
import Modulemainhydrogen from "../Modulemainhydrogen";

const Modulesmainpagehydrogen = (setLoginUser) => {
    return (
        <>
        <Navbarmodule />      
        <Modulemainhydrogen />
        <Footerlogin /> 
        </>
    );
};

export default Modulesmainpagehydrogen;
