import React from 'react';
import Navbar from "../navbar";
import Investor from "../Investor";
import Footer from "../Footer";

const Investorpage = () => {
    return (
        <>
            <Navbar />      
            <Investor />      
            <Footer />
        </>
    );
};

export default Investorpage
;
