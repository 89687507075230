import React from 'react';
import Navbarmodule from "../navbarmodule";
import Privacypolicy from "../Privacypolicy";
import Footerlogin from "../Footerlogin";


const Privacypolicylogin = () => {
    return (
        <>
            <Navbarmodule />
            <Privacypolicy />   
            <Footerlogin />         
        </>
    );
};

export default Privacypolicylogin;