import React from 'react';
import Navbar from "../navbar";
import Footer from "../Footer";
import Training from "../Training";

const Trainingpage = () => {
    return (
        <>
        <Navbar />      
        <Training />
        <Footer /> 
        </>
    );
};

export default Trainingpage;
