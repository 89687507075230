

const hydrogenapi = [
    {
        id: 1,
        logo: "fas fa-dumpster-fire",
        title: "Project Technical & Financial Module",
        info: "This Module provides User the Technical & Financial modelling of the Project with respect to the Suggestive Design Guidelines, Process Flow Diagram, Piping & Instrumentation Diagram, Hydrogen Generation, Capital cost, Annual O&M cost, Depreciation, Interest, Return on Investment (ROI), Internal rate of return (IRR) and LCOE over the PPA / Contracted period.",
        },
    {
        id: 2,
        logo: "fas fa-solar-panel",
        title: "Suggestive Supplier, Vendor & Sub-Vendor's list",
        info: "This module will provide the list of Suppliers, Vendor's and Sub-Vendor's for each and every item which are required in a standard Green Hydrogen Generation Plant.",
            
         },

    {
        id: 3,
        logo: "fas fa-database",
        title: "Bill of Quantity (BOQ) & Cost Module",
        info: "Provides users a suggestive BOQ for the main items of Green Hydrogen Plant. The Module will also be provided with a cost estimation sheet which can be used by the proposal team & management team and decides the final cost of the project",
    },

    {
        id: 4,
        logo: "fas fa-hand-holding-water",
        title: "Schedule Management",
        info: "Schedule Management Module provides User a handy tool to monitor day to day planning & scheduling in the project w.r.t Engineering, Procurement, Construction, Commissioning, Operation & Maintenance activities before handover etc. with an alarm to the management of any potential delays.",
    },

    {
        id: 5,
        logo: "fas fa-gas-pump",
        title: "Quality Management",
        info: "Quality Management Module provides options to the User for various quality checks required during the procurement, construction & commissioning phases of the project.",
  },

    {
        id: 6,
        logo: "fas fa-industry",
        title: "Dash Board",
        info: "This Module provides the management team to Monitor and Track the project progress with respect to Project Management, Cost Management, Estimated Project Completion based on progress achieved, Alarms and Flags and Contractual Issues ",
   },
];

export default hydrogenapi;