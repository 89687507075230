import React from 'react';
import Navbarmodule from "../navbarmodule";
import Investor from "../Investor";
import Footerlogin from "../Footerlogin";

const Investorlogin = () => {
    return (
        <>
            <Navbarmodule />      
            <Investor />      
            <Footerlogin />
        </>
    );
};

export default Investorlogin
;
